import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { setLoaderAction } from '../actions/siteActions';
import { routeConstants } from '../common/routeConstants';

import PageLoader from '../components/PageLoader';

const Public = (ChildComponent) => {
  const PublicHoc = (props) => {
    const history = useHistory();
    const [shouldRender, setShouldRender] = useState(false);

    useEffect(() => {
      if (props?.auth?.accessToken) {
        return history.push(routeConstants.DASHBOARD);
      } else {
        setShouldRender(true);
      }
      setShouldRender(true);
    }, [props.auth.accessToken]); // eslint-disable-line

    return (
      <>
        {props.site.loader && <PageLoader />}
        {shouldRender && <ChildComponent {...props} />};
      </>
    );
  };

  const mapStateToProps = (state) => ({
    site: state.site,
    auth: state.auth.userDetails,
  });

  const mapDispatchToProps = (dispatch) => ({
    setLoader: (dataPayload) => setLoaderAction(dispatch, dataPayload),
  });

  return connect(mapStateToProps, mapDispatchToProps)(PublicHoc);
};

export default Public;
