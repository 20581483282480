import { useState, useEffect } from 'react';

import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import QuestionHeader from '../QuestionHeader';
import InputTextArea from '../../InputTextArea';
import PrimaryButton from '../../PrimaryButton';
import WrongAnswerToast from '../../WrongAnswerToast';
import QuestionSuggestions from '../QuestionSuggestions';

import styles from './styles.scss';

const LAQWLQuestion = (props) => {
  const [wordCount, setWordCount] = useState(0);
  const [correctAnswer, setCorrectAnswer] = useState('');
  const [answerResponse, setAnswerResponse] = useState(null);

  useEffect(() => {
    if (!isEqual(answerResponse, props.answerResponse)) {
      setAnswerResponse(props.answerResponse);
    }
  }, [props.answerResponse]);

  useEffect(() => {
    setWordCount(0);
    setCorrectAnswer('');
  }, [props.activeQuestion]);

  const handleAnswerSubmit = () => {
    props.answerSubmitHandler(correctAnswer);
  };

  const handleAnswerUpdate = (answer = '') => {
    setCorrectAnswer(answer);
    setWordCount(
      answer
        .trim()
        .replace(/\n/g, ' ')
        .split(' ')
        .filter((ca) => ca && ca.trim()).length
    );
  };

  const checkSubmitEnabled = () => {
    let isSubmitDisabled = true;
    if (correctAnswer) {
      if (
        props.activeQuestion.answer?.wordCount &&
        wordCount >= props.activeQuestion.answer.wordCount
      ) {
        isSubmitDisabled = false;
      }

      if (
        !isSubmitDisabled &&
        props.activeQuestion.answer?.keywords &&
        props.activeQuestion.answer?.keywords.length
      ) {
        const answerWords = correctAnswer
          .trim()
          .toLowerCase()
          .replace(/\n/g, ' ')
          .split(' ')
          .filter((ca) => ca && ca.trim());

        if (answerWords.length) {
          for (let keyword of props.activeQuestion.answer.keywords) {
            if (!answerWords.includes(keyword.toLowerCase())) {
              isSubmitDisabled = true;
              break;
            }
          }
        }
      }
    }

    return isSubmitDisabled;
  };

  return (
    <div style={styles} className="laqwl-container">
      <div className="content-scroller">
        {props.activeQuestion?.question?.length !== 0 && (
          <div className="laqwl-container-content">
            <QuestionHeader question={props.activeQuestion.question} />

            <div className="laqwl-container-item">
              <InputTextArea
                rows={4}
                wordCount={wordCount}
                value={correctAnswer}
                placeholder="Type answer"
                onChange={(event) =>
                  handleAnswerUpdate(event.currentTarget.value)
                }
                minWords={get(props.activeQuestion, 'answer.wordCount', 0) || 0}
              />

              {props.activeQuestion.answer?.keywords &&
                props.activeQuestion.answer.keywords.length !== 0 && (
                  <div className="keywords-container">
                    <div className="keywords-container-header">
                      <strong>Keywords: </strong>
                      <p className="keywords-container-header-subtext">
                        (Please make use of these keywords)
                      </p>
                    </div>
                    <div className="leywords-container-content">
                      {props.activeQuestion.answer.keywords.join(', ')}
                    </div>
                  </div>
                )}
            </div>

            {props.activeQuestion?.questionSuggestions?.length !== 0 && (
              <QuestionSuggestions activeQuestion={props.activeQuestion} />
            )}
          </div>
        )}
      </div>

      <div className="laqwl-container-footer">
        {answerResponse && !answerResponse.correct && <WrongAnswerToast />}

        <PrimaryButton
          name="Submit"
          showIcon={true}
          onClick={handleAnswerSubmit}
          disabled={checkSubmitEnabled()}
        />
      </div>
    </div>
  );
};

export default LAQWLQuestion;
