export const routeConstants = {
  HOME: '/',
  ONBOARDINGOTP: '/auth/verify-mobile',
  ONBOARDINGPROFILE: '/onboarding/complete-profile',
  PACKAGES: '/packages/choose',
  PACKAGESCHECKOUT: '/packages/checkout',
  PACKAGESPAYMENT: '/packages/payment',
  PACKAGESCHECKOUTSUCCESS: '/packages/checkout/success',
  PACKAGESCHECKOUTFAILURE: '/packages/checkout/failure',
  SUBJECTDETAILS: '/subjects/:subjectID',
  LESSONDETAILS: '/lessons/:lessonID',
  DASHBOARD: '/dashboard',
  RESOURCEDETAILS: '/resource/:resourceType/:resourceID',
  TRANSACTIONS: '/transactions',
  MEMBERSHIP: '/membership',
  SESSIONDETAILS: '/sessions/:sessionID',
  ALLPRODUCTS: '/products',
  MYPRODUCTS: '/products/allocated',
  CART: '/cart',
  CARTCHECKOUT: '/cart/checkout',
  PRODUCTPAYMENT: '/product/payment',
  PRODUCTCHECKOUTSUCCESS: '/product/checkout/success',
  PRODUCTCHECKOUTFAILURE: '/product/checkout/failure',
  SUPPORT: '/support',
  ALLSESSIONS: '/sessions',
  SEARCH: '/search',
  SEARCHPARAMS: '/search/:search',
  WALKTHROUGH: '/walkthrough',
  WORKSHEET: '/worksheet',
  VIDEODETAILS: '/videos/:videoID',
  DOWNLOADAPP: '/download/app',

  // PARENT

  PARENT_ONBOARDING_MOBILE: '/parent/auth',
  PARENT_ONBOARDING_OTP: '/parent/auth/verify-mobile',
  PARENT_SUBJECTDETAILS: '/parent/subjects/:subjectID',
  PARENT_DASHBOARD: '/parent/dashboard',
  PARENT_MEMBERSHIP: '/parent/membership',
  PARENT_TRANSACTIONS: '/parent/transactions',
  PARENT_MYPRODUCTS: '/parent/products/allocated',
  PARENT_MODELPROJECTS: '/parent/projects/',
  PARENT_ALLPRODUCTS: '/parent/products',
  PARENT_ALLUPLOADS: '/parent/uploads',
  PARENT_ALLSESSIONS: '/parent/sessions',
  PARENT_SESSIONDETAILS: '/parent/sessions/:sessionID',
  PARENT_NEWSLETTERS: '/parent/newsletters',

  // FREEBIE

  FREEBIE_WORKSHEET: '/freebie/:worksheetID'
};
