import { connect } from 'react-redux';
import { routeConstants } from '../common/routeConstants';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import OTPEntry from '../containers/Onboarding/OTPEntry';
import MobileEntry from '../containers/Onboarding/MobileEntry';
import CompleteProfile from '../containers/Onboarding/CompleteProfile';
import SubjectChoice from '../containers/Onboarding/SubjectChoice';
import LessonDetails from '../containers/Subjects/LessonDetails';
import PageLoader from '../components/PageLoader';
import Checkout from '../containers/Orders/Checkout';
import Payment from '../containers/Orders/Payment';
import PaymentSuccess from '../containers/Orders/PaymentSuccess';
import PaymentFailure from '../containers/Orders/PaymentFailure';
import SubjectDetails from '../containers/Subjects/SubjectDetails';
import Questionnaire from '../containers/Subjects/Questionnaire';
import ResourceDetails from '../containers/Resources/ResourceDetails';
import Transactions from '../containers/Transactions';
import Membership from '../containers/Membership';
import Dashboard from '../containers/Dashboard';
import SessionDetails from '../containers/Sessions/SessionDetails';
import AllProducts from '../containers/AllProducts';
import MyProducts from '../containers/MyProducts';
import Cart from '../containers/Orders/Cart';
import CartCheckout from '../containers/Orders/CartCheckout';
import Support from '../containers/Support';
import AllSessions from '../containers/Sessions/AllSessions';
import Search from '../containers/Search';
// Parent
import ParentMobileEntry from '../containers/Parent/Onboarding/ParentMobileEntry';
import ParentOtpEntry from '../containers/Parent/Onboarding/ParentOtp';
import LessonProgress from '../containers/Parent/LessonProgress';
import AllUploads from '../containers/Parent/AllUploads';
import ParentDashboard from '../containers/Parent/Dashboard';
import Newsletters from '../containers/Parent/Newsletters';
import ModelProjects from '../containers/ModelProjects';
import VideoDetails from '../containers/Resources/VideoDetails';
// Freebie
import FreebieQuestionnaire from '../containers/Resources/FreebieQuestionnaire';
import DownloadApp from '../containers/DownloadApp';

const AppRouter = (props) => {
	return (
		<>
			<Router>
				<Switch>
					<Route exact path={routeConstants.HOME} component={MobileEntry} />
					<Route exact path={routeConstants.ONBOARDINGOTP} component={OTPEntry} />
					{/* <Route exact path={routeConstants.ONBOARDINGPROFILE} component={CompleteProfile} />
					<Route exact path={routeConstants.PACKAGES} component={SubjectChoice} />
					<Route exact path={routeConstants.PACKAGESCHECKOUT} component={Checkout} />
					<Route exact path={routeConstants.PACKAGESPAYMENT} component={Payment} />
					<Route exact path={routeConstants.PACKAGESCHECKOUTSUCCESS} component={PaymentSuccess} />
					<Route exact path={routeConstants.PACKAGESCHECKOUTFAILURE} component={PaymentFailure} /> */}
					<Route exact path={routeConstants.SUBJECTDETAILS} component={SubjectDetails} />
					<Route exact path={routeConstants.LESSONDETAILS} component={LessonDetails} />
					<Route exact path={routeConstants.WALKTHROUGH} component={Questionnaire} />
					<Route exact path={routeConstants.WORKSHEET} component={Questionnaire} />
					<Route exact path={routeConstants.RESOURCEDETAILS} component={ResourceDetails} />
					<Route exact path={routeConstants.TRANSACTIONS} component={Transactions} />
					<Route exact path={routeConstants.MEMBERSHIP} component={Membership} />
					<Route exact path={routeConstants.DASHBOARD} component={Dashboard} />
					<Route exact path={routeConstants.SESSIONDETAILS} component={SessionDetails} />
					<Route exact path={routeConstants.ALLPRODUCTS} component={AllProducts} />
					<Route exact path={routeConstants.MYPRODUCTS} component={MyProducts} />
					<Route exact path={routeConstants.CART} component={Cart} />
					<Route exact path={routeConstants.CARTCHECKOUT} component={CartCheckout} />
					<Route exact path={routeConstants.PRODUCTPAYMENT} component={Payment} />
					<Route exact path={routeConstants.PRODUCTCHECKOUTSUCCESS} component={PaymentSuccess} />
					<Route exact path={routeConstants.PRODUCTCHECKOUTFAILURE} component={PaymentFailure} />
					<Route exact path={routeConstants.SUPPORT} component={Support} />
					<Route exact path={routeConstants.ALLSESSIONS} component={AllSessions} />
					<Route exact path={routeConstants.SEARCH} component={Search} />
					<Route exact path={routeConstants.SEARCHPARAMS} component={Search} />
					<Route exact path={routeConstants.VIDEODETAILS} component={VideoDetails} />
					<Route exact path={routeConstants.DOWNLOADAPP} component={DownloadApp} />
					{/* PARENT */}
					<Route exact path={routeConstants.PARENT_ONBOARDING_MOBILE} component={ParentMobileEntry} />
					<Route exact path={routeConstants.PARENT_ONBOARDING_OTP} component={ParentOtpEntry} />
					<Route exact path={routeConstants.PARENT_SUBJECTDETAILS} component={LessonProgress} />
					<Route exact path={routeConstants.PARENT_ALLUPLOADS} component={AllUploads} />
					<Route exact path={routeConstants.PARENT_DASHBOARD} component={ParentDashboard} />
					<Route exact path={routeConstants.PARENT_TRANSACTIONS} component={Transactions} />
					<Route exact path={routeConstants.PARENT_MEMBERSHIP} component={Membership} />
					<Route exact path={routeConstants.PARENT_MYPRODUCTS} component={MyProducts} />
					<Route exact path={routeConstants.PARENT_ALLPRODUCTS} component={AllProducts} />
					<Route exact path={routeConstants.PARENT_SESSIONDETAILS} component={SessionDetails} />
					<Route exact path={routeConstants.PARENT_ALLSESSIONS} component={AllSessions} />
					<Route exact path={routeConstants.PARENT_NEWSLETTERS} component={Newsletters} />
					<Route exact path={routeConstants.PARENT_MODELPROJECTS} component={ModelProjects} />
					{/* FREEBIE */}
					<Route exact path={routeConstants.FREEBIE_WORKSHEET} component={FreebieQuestionnaire} />
				</Switch>
			</Router>

			{props.loader && <PageLoader />}
		</>
	);
};

const mapStateToProps = (state) => ({
	loader: state.site.loader,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AppRouter);
