import React from 'react'
import Logo from '../../assets/images/dashboard-logo.svg';
import GoogleStore from '../../assets/images/googleStore.png';
import AppStore from '../../assets/images/appStore.png';
import './styles.scss'

const MobileOverlay = () => {
  return (  
    <div className='mobile-overlay'>
      <div className='content'>
        <div className='logo-container'>
          <img src={Logo}  alt='Skipin LMS' className='logo' />
        </div>

        <p className='message'>
          Looks Like you want to use Skipin LMS in a mobile. You can download our app from the store. 
        </p>

        <div className='app-links'>
          <a href="https://play.google.com/store/apps/details?id=io.wiseowllearning.skipin&hl=en&gl=US">
            <img src={GoogleStore} alt='Get it on Google Store' />
          </a>
          <a href="https://apps.apple.com/in/app/skipin/id1537130813">
            <img src={AppStore}  alt='Get it on App Store' />
          </a>
        </div>
      </div>
    </div>
  )
}

export default MobileOverlay
