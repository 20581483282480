import { connect } from 'react-redux';
import { Table } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { showToast } from '../../common/helpers';
import { setLoaderAction } from '../../actions/siteActions';
import { routeConstants } from '../../common/routeConstants';

import moment from 'moment';
import Private from '../../hocs/Private';
import capitalize from 'lodash/capitalize';
import packageService from '../../services/packageService';
import NavigationStatic from '../../components/NavigationStatic';
import BreadcrumbStatic from '../../components/BreadcrumbStatic';

import './styles.scss';

const Transactions = (props) => {
  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    getTransactions();
  }, []); // eslint-disable-line

  const getTransactions = () => {
    props.setLoader(true);
    packageService
      .getTransactions()
      .then((responseData) => {
        if (responseData) {
          props.setLoader(false);
          if (responseData.code === 200 && responseData.data) {
            setTransactions(responseData.data);
          }
        }
      })
      .catch((errorData) => {
        props.setLoader(false);
        showToast(errorData?.message);
      });
  };

  return (
    <div className="global-container content-container">
      <NavigationStatic />

      <section className="content-container-dynamic">
        <BreadcrumbStatic
          crumbs={[
            {
              name: 'Dashboard',
              route: window.location.pathname.includes('parent')
                ? routeConstants.PARENT_DASHBOARD
                : routeConstants.DASHBOARD,
            },
            {
              name: 'My Transactions',
              route: routeConstants.TRANSACTIONS,
            },
          ]}
        />

        <div className="transactions">
          <div className="transactions-header">
            <h5 className="transactions-header-title">My Transactions</h5>
          </div>

          <div className="transactions-content">
            <Table responsive>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Description</th>
                  <th>Amount</th>
                  <th>Type</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {transactions &&
                  transactions.length !== 0 &&
                  transactions.map((txn, index) => {
                    return (
                      <tr key={index}>
                        <td>{moment(txn.createdAt).format('DD/MM/YYYY, hh:mm a')}</td>
                        <td>{txn.title}</td>
                        <td>Rs. {txn.order?.orderAmount || '0'}</td>
                        <td>{capitalize(txn.type)}</td>
                        <td>{txn.status}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </div>
        </div>
      </section>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  setLoader: (dataPayload) => setLoaderAction(dispatch, dataPayload),
});

export default Private(
  connect(mapStateToProps, mapDispatchToProps)(Transactions)
);
