import React from 'react';
import './styles.scss';

const SectionTitle = (props) => {
	return (
		<div className='section-title'>
			<div>{props.icon && <img src={props.icon} alt='' className='icon' />}</div>
			<h3 className='title'>{props.title}</h3>
		</div>
	);
};

export default SectionTitle;
