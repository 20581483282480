import { baseAxiosInstance } from './';
import { apiConstants } from '../common/apiConstants';

const packageService = {
  getPackages: () => baseAxiosInstance.get(apiConstants.GET_PACKAGES),
  verifyCoupon: (dataPayload) =>
    baseAxiosInstance.post(apiConstants.VERIFY_COUPON, dataPayload),
  verifyGiftCard: (dataPayload) =>
    baseAxiosInstance.post(apiConstants.VERIFY_GIFTCARD, dataPayload),
  addUserPackage: (dataPayload) =>
    baseAxiosInstance.post(apiConstants.ADD_USER_PACKAGE, dataPayload),
  redeemUserPackage: (dataPayload) =>
    baseAxiosInstance.post(apiConstants.REDEEM_USER_PACKAGE, dataPayload),
  verifyPayment: (dataPayload) =>
    baseAxiosInstance.post(apiConstants.VERIFY_PAYMENT, dataPayload),
  failPayment: (dataPayload) =>
    baseAxiosInstance.post(apiConstants.FAIL_PAYMENT, dataPayload),
  getTransactions: () => baseAxiosInstance.get(apiConstants.TRANSACTIONS),
  getMembership: () => baseAxiosInstance.get(apiConstants.MEMBERSHIP),
};

export default packageService;
