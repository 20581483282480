import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import { showToast } from '../../../common/helpers';
import { setLoaderAction } from '../../../actions/siteActions';
import { routeConstants } from '../../../common/routeConstants';

import moment from 'moment';
import Private from '../../../hocs/Private';
import userService from '../../../services/userService';
import NavigationStatic from '../../../components/NavigationStatic';
import BreadcrumbStatic from '../../../components/BreadcrumbStatic';
import PlayIconWhite from '../../../assets/images/play-white-icon.svg';

import './styles.scss';

const SessionDetails = (props) => {
  const [startSession, setStartSession] = useState(false);
  const [sessionDetails, setSessionDetails] = useState(null);

  useEffect(() => {
    getSessionDetails();
  }, []); // eslint-disable-line

  const getSessionDetails = () => {
    props.setLoader(true);
    userService
      .getSessionDetails({ sessionID: props.match.params.sessionID })
      .then((responseData) => {
        if (responseData) {
          props.setLoader(false);
          if (responseData.code === 200 && responseData.data) {
            setSessionDetails(responseData.data);
          }
        }
      })
      .catch((errorData) => {
        props.setLoader(false);
        showToast(errorData?.message);
      });
  };

  const getBreadcrumbs = () => {
    const breadcrumbs = [
      {
        name: 'Dashboard',
        route: window.location.pathname.includes('parent')
          ? routeConstants.PARENT_DASHBOARD
          : routeConstants.DASHBOARD,
      },
      {
        name: 'All Sessions',
        route: window.location.pathname.includes('parent')
          ? routeConstants.PARENT_ALLSESSIONS
          : routeConstants.ALLSESSIONS,
      },
    ];

    if (sessionDetails) {
      breadcrumbs.push({
        name: sessionDetails.title,
        route: routeConstants.SESSIONDETAILS.replace(
          ':sessionID',
          sessionDetails._id
        ),
      });
    }

    return breadcrumbs;
  };

  return (
    <div className="global-container content-container">
      <NavigationStatic />

      <section className="content-container-dynamic">
        <BreadcrumbStatic crumbs={getBreadcrumbs()} />

        {sessionDetails && (
          <div className="sessiondetails">
            <div className="sessiondetails-header">
              <h5 className="sessiondetails-header-title">
                {sessionDetails.title}
              </h5>
            </div>

            <div className="sessiondetails-content">
              {!startSession ? (
                <div className="sessiondetails-content-thumbnail">
                  <img src={sessionDetails.thumbnail} alt="Session" />
                  <div className="thumbnail-overlay">
                    {sessionDetails.upcomingSession ? (
                      <div className="thumbnail-overlay-content">
                        <h5 className="thumbnail-overlay-title">HOLD TIGHT</h5>
                        <h5 className="thumbnail-overlay-subtitle">
                          This session will be available on
                        </h5>
                        <h5 className="thumbnail-overlay-date">
                          {moment(sessionDetails.startDate).format(
                            'Do MMMM, YYYY - hh:mm A'
                          )}
                        </h5>
                      </div>
                    ) : (
                      <>
                        <img
                          src={PlayIconWhite}
                          onClick={() => setStartSession(true)}
                          className="play-icon"
                        />
                      </>
                    )}
                  </div>
                </div>
              ) : (
                <div className="sessiondetails-content-player">
                  {sessionDetails.source === 'Youtube' ? (
                    <iframe
                      allowfullscreen
                      frameBorder="0"
                      className="session-video"
                      allow="autoplay; fullscreen; picture-in-picture"
                      src={`https://www.youtube.com/embed/${sessionDetails.url}?autoplay=1`}
                    />
                  ) : sessionDetails.source === 'Vimeo' ? (
                    <iframe
                      allowfullscreen
                      frameBorder="0"
                      className="session-video"
                      allow="autoplay; fullscreen; picture-in-picture"
                      src={`https://player.vimeo.com/video/${sessionDetails.url}?autoplay=1&badge=0`}
                    />
                  ) : (
                    <video className="session-video" autoPlay controls>
                      <source src={sessionDetails.url} />
                    </video>
                  )}
                </div>
              )}
            </div>
          </div>
        )}
      </section>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  setLoader: (dataPayload) => setLoaderAction(dispatch, dataPayload),
});

export default Private(
  connect(mapStateToProps, mapDispatchToProps)(SessionDetails)
);
