import { useState, useEffect } from 'react';

import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import QuestionHeader from '../QuestionHeader';
import InputTextArea from '../../InputTextArea';
import PrimaryButton from '../../PrimaryButton';
import WrongAnswerToast from '../../WrongAnswerToast';
import QuestionSuggestions from '../QuestionSuggestions';

import styles from './styles.scss';

const LAQEQuestion = (props) => {
  const [correctAnswer, setCorrectAnswer] = useState(null);
  const [answerResponse, setAnswerResponse] = useState(null);
  const [showTextArea, setShowTextArea] = useState(true);

  useEffect(() => {
    if (!isEqual(answerResponse, props.answerResponse)) {
      setAnswerResponse(props.answerResponse);
    }
  }, [props.answerResponse]);

  useEffect(() => {
    setCorrectAnswer(null);
    setShowTextArea(false);
  }, [props.activeQuestion]);

  useEffect(() => {
    if (!showTextArea) {
      setShowTextArea(true);
    }
  }, [showTextArea]);

  const handleAnswerSubmit = () => {
    props.answerSubmitHandler(correctAnswer);
  };

  return (
    <div style={styles} className="laqe-container">
      <div className="content-scroller">
        {props.activeQuestion?.question?.length !== 0 && (
          <div className="laqe-container-content">
            <QuestionHeader question={props.activeQuestion.question} />

            <div className="laqe-container-item">
              {showTextArea && (
                <InputTextArea
                  rows={4}
                  placeholder="Type answer"
                  onChange={(event) =>
                    setCorrectAnswer(event.currentTarget.value)
                  }
                  defaultValue={get(props.activeQuestion, 'answer.ques') || ''}
                />
              )}
            </div>
            {props.activeQuestion?.questionSuggestions?.length !== 0 && (
              <QuestionSuggestions activeQuestion={props.activeQuestion} />
            )}
          </div>
        )}
      </div>

      <div className="laqe-container-footer">
        {answerResponse && !answerResponse.correct && <WrongAnswerToast />}

        <PrimaryButton
          name="Submit"
          showIcon={true}
          disabled={!correctAnswer}
          onClick={handleAnswerSubmit}
        />
      </div>
    </div>
  );
};

export default LAQEQuestion;
