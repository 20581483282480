import { connect } from 'react-redux';
import { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { showToast } from '../../../../common/helpers';
import { setLoaderAction } from '../../../../actions/siteActions';
import { routeConstants } from '../../../../common/routeConstants';

import Logo from '../../../../assets/images/logo.svg';
import authService from '../../../../services/authService';
import PrimaryButton from '../../../../components/PrimaryButton';

import './styles.scss';

const ParentMobileEntry = (props) => {
  const history = useHistory();
  const [checked, setChecked] = useState(false);
  const [mobileNumber, setMobileNumber] = useState('');
  const [isInputDisabled, setIsInputDisabled] = useState(false);

  useEffect(() => {
    if (!props?.auth?.accessToken || !props?.profile?.mobileNumber) {
      return history.replace(routeConstants.DASHBOARD);
    }

    if (props?.profile?.parentMobileNumber) {
      return history.replace(routeConstants.PARENT_ONBOARDING_OTP);
    }
  }, []); // eslint-disable-line

  const handleFieldUpdate = (fieldValue) => {
    if (fieldValue && !/^\d+$/.test(fieldValue)) {
      return
    }

    setMobileNumber(fieldValue);
  };

  const submitHandler = () => {
    props.setLoader(true);
    authService
      .parentLogin({ mobileNumber, countryCode: '91' })
      .then((responseData) => {
        props.setLoader(false);
        if (responseData) {
          if (responseData.code === 200) {
            history.push(routeConstants.PARENT_ONBOARDING_OTP, {
              mobileNumber,
            });
          }
        }
      })
      .catch((errorData) => {
        props.setLoader(false);
        showToast(errorData?.message);
      });
  };

  const checkboxHandler = () => {
    if (!checked) {
      setMobileNumber(props.profile.mobileNumber);
      setIsInputDisabled(true);
    } else if (checked && mobileNumber === props.profile.mobileNumber) {
      setMobileNumber('');
      setIsInputDisabled(false);
    }

    setChecked(!checked);
  };

  return (
    <div className='parent-onboarding-mobile'>
      <div className='global-container onboarding-container mobileentry-container'>
        <header className='global-header'>
          <div className='global-header-left'>
            <Link to='#' onClick={() => window.open(process.env.REACT_APP_STATIC_APP_URL)}>
              <img src={Logo} className='global-header-logo' alt='SKIPIN' />
            </Link>
          </div>

          <div className='global-header-right'></div>
        </header>

        <div className='mobileentry-container-content'>
          <h3 className='mobileentry-container-title'>
            This is a parent's only section
          </h3>

          <div className='mobileentry-container-input'>
            <label className='mobileentry-container-label'>
              Please enter your parent's mobile number
            </label>
            <div className='flex-wrapper d-flex align-items-center justify-content-start'>
              <input
                type='text'
                className='mobileentry-container-field'
                value='+91'
                readOnly
              />
              <input
                type='text'
                maxLength={10}
                value={mobileNumber}
                placeholder='98XXXXXXXX'
                disabled={isInputDisabled}
                className='mobileentry-container-field'
                onChange={(event) =>
                  handleFieldUpdate(event.currentTarget.value)
                }
              />
            </div>
          </div>
          <div className='use-same-num-container'>
            <input
              type='checkbox'
              id='useSameNum'
              checked={checked}
              onChange={checkboxHandler}
            />
            <label for='useSameNum'>Use same number as mine</label>
          </div>

          <PrimaryButton
            name='Send OTP'
            showIcon={true}
            onClick={submitHandler}
            disabled={mobileNumber.length !== 10}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  site: state.site,
  profile: state.user.profile,
  auth: state.auth.userDetails,
});

const mapDispatchToProps = (dispatch) => ({
  setLoader: (dataPayload) => setLoaderAction(dispatch, dataPayload),
});

export default connect(mapStateToProps, mapDispatchToProps)(ParentMobileEntry);
