import { reduxConstants } from '../common/reduxConstants';

export const setActiveQuestionAction = (dispatch, dataPayload) => {
  dispatch({
    payload: dataPayload,
    type: reduxConstants.SETACTIVEQUESTION,
  });
};

export const setQuestionnaireBreadcrumbsAction = (dispatch, dataPayload) => {
  dispatch({
    payload: dataPayload,
    type: reduxConstants.SETQUESTIONNAIREBREADCRUMB,
  });
};
