import { connect } from 'react-redux';
import { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { showToast } from '../../../common/helpers';
import { setLoaderAction } from '../../../actions/siteActions';
import { routeConstants } from '../../../common/routeConstants';
import {
  CashfreeWalletCodes,
  CASHFREE_ENVIRONMENT,
  CASHFREE_SDK_URL,
} from '../../../common/constants';

import get from 'lodash/get';
import Private from '../../../hocs/Private';
import Logo from '../../../assets/images/logo.svg';
import UpiIcon from '../../../assets/images/upi-icon.svg';
import WalletIcon from '../../../assets/images/wallet.svg';
import packageService from '../../../services/packageService';
import PrimaryButton from '../../../components/PrimaryButton';
import resourceService from '../../../services/resourceService';
import CreditCardIcon from '../../../assets/images/credit-card.svg';
import RightChevron from '../../../assets/images/right-chevron-icon.svg'

import './styles.scss';
import Header from '../../../components/Header';

const Payment = (props) => {
  const history = useHistory();
  const [formData, setFormData] = useState({});
  const [enablePayment, setEnablePayment] = useState(false);
  const [selectedMethod, setSelectedMethod] = useState(null);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = CASHFREE_SDK_URL;
    script.async = true;
    script.onload = cashfreeScriptLoaded;
    document.head.appendChild(script);
  }, []); // eslint-disable-line

  const cashfreeScriptLoaded = () => {
    setEnablePayment(true);
    window.CashFree.init({
      layout: {},
      checkout: 'transparent',
      mode: CASHFREE_ENVIRONMENT,
    });
  };

  const postPaymentCallback = (event) => {
    if (event.name === 'PAYMENT_RESPONSE' && event.response) {
      if (event.response.txStatus === 'SUCCESS') {
        verifyOrder();
      } else if (
        event.response.txStatus === 'CANCELLED' ||
        event.response.txStatus === 'FAILED'
      ) {
        failOrder();
      }
    } else {
      failOrder();
    }
  };

  const handleSelectedPaymentMethod = (fieldValue) => {
    let formData = {};
    if (selectedMethod === fieldValue) {
      return;
    }

    switch (fieldValue) {
      case 'CARD':
        formData = {
          cvv: '',
          number: '',
          holder: '',
          expiryYear: '',
          expiryMonth: '',
        };
        break;

      case 'WALLET':
        formData = {
          code: '',
        };
        break;

      case 'UPI':
        formData = {
          vpa: '',
        };
        break;

      default:
        return;
    }

    setFormData(formData);
    setSelectedMethod(fieldValue);
  };

  const formValidation = () => {
    const numberRegex = /^[0-9]*$/;

    let isFormValid = true;

    switch (selectedMethod) {
      case 'CARD':
        if (
          !formData.cvv ||
          !formData.holder ||
          !formData.number ||
          !formData.expiryMonth ||
          !formData.expiryYear
        ) {
          isFormValid = false;
          showToast('Card details are required.');
          return isFormValid;
        }

        if (
          !numberRegex.test(formData.cvv) ||
          !numberRegex.test(formData.expiryMonth) ||
          !numberRegex.test(formData.expiryYear) ||
          !numberRegex.test(formData.number)
        ) {
          isFormValid = false;
          showToast('Invalid Card details entered.');
          return isFormValid;
        }

        break;

      case 'WALLET':
        if (!formData.code) {
          isFormValid = false;
          showToast('Please select from the available options.');
          return isFormValid;
        }

        break;

      case 'UPI':
        if (!formData.vpa) {
          isFormValid = false;
          showToast('UPI Handle is required.');
          return isFormValid;
        }

        break;

      default:
        return;
    }

    return isFormValid;
  };

  const handlePayment = () => {
    const isFormValid = formValidation();
    if (!isFormValid) {
      return;
    }

    let orderDetails = {
      appId: get(props.currentOrder, 'order.appId', ''),
      orderId: get(props.currentOrder, 'order.orderID', ''),
      orderAmount: get(props.currentOrder, 'order.orderAmount', ''),
      orderCurrency: get(props.currentOrder, 'order.orderCurrency', ''),
      orderNote: get(props.currentOrder, 'order.orderNote', ''),
      customerName: get(props.currentOrder, 'order.userName', ''),
      customerEmail: get(props.currentOrder, 'order.userEmail', ''),
      customerPhone: get(props.currentOrder, 'order.userNumber', ''),
      paymentToken: get(props.currentOrder, 'order.paymentToken', ''),
    };

    switch (selectedMethod) {
      case 'CARD':
        orderDetails.paymentOption = 'card';
        orderDetails.card = Object.assign({}, formData);
        break;

      case 'WALLET':
        orderDetails.paymentOption = 'wallet';
        orderDetails.wallet = Object.assign({}, formData);
        break;

      case 'UPI':
        orderDetails.paymentOption = 'upi';
        orderDetails.upi = Object.assign({}, formData);
        break;

      default:
        return;
    }

    window.CashFree.initPopup();
    window.CashFree.paySeamless(orderDetails, postPaymentCallback);
  };

  const verifyOrder = () => {
    if (props.currentOrderType === 'PACKAGE') {
      verifyPackageOrder();
    } else if (props.currentOrderType === 'PRODUCT') {
      verifyProductOrder();
    }
  };

  const verifyPackageOrder = () => {
    props.setLoader(true);
    packageService
      .verifyPayment({ userPackageID: props.currentOrder._id })
      .then((responseData) => {
        props.setLoader(false);
        if (responseData) {
          if (responseData.code === 200) {
            history.push(routeConstants.PACKAGESCHECKOUTSUCCESS);
          }
        }
      })
      .catch((errorData) => {
        props.setLoader(false);
        showToast(errorData?.message);
      });
  };

  const verifyProductOrder = () => {
    props.setLoader(true);
    resourceService
      .verifyProductPayment({ userProductID: props.currentOrder._id })
      .then((responseData) => {
        props.setLoader(false);
        if (responseData) {
          if (responseData.code === 200) {
            history.push(routeConstants.PRODUCTCHECKOUTSUCCESS);
          }
        }
      })
      .catch((errorData) => {
        props.setLoader(false);
        showToast(errorData?.message);
      });
  };

  const failOrder = () => {
    if (props.currentOrderType === 'PACKAGE') {
      failPackageOrder();
    } else if (props.currentOrderType === 'PRODUCT') {
      failProductOrder();
    }
  };

  const failPackageOrder = () => {
    props.setLoader(true);
    packageService
      .failPayment({ userPackageID: props.currentOrder._id })
      .then((responseData) => {
        props.setLoader(false);
        if (responseData) {
          if (responseData.code === 200) {
            history.push(routeConstants.PACKAGESCHECKOUTFAILURE);
          }
        }
      })
      .catch((errorData) => {
        props.setLoader(false);
        showToast(errorData?.message);
      });
  };

  const failProductOrder = () => {
    props.setLoader(true);
    resourceService
      .failProductPayment({ userProductID: props.currentOrder._id })
      .then((responseData) => {
        props.setLoader(false);
        if (responseData) {
          if (responseData.code === 200) {
            history.push(routeConstants.PRODUCTCHECKOUTFAILURE);
          }
        }
      })
      .catch((errorData) => {
        props.setLoader(false);
        showToast(errorData?.message);
      });
  };

  const handleFieldUpdate = (fieldName, fieldValue) => {
    if (fieldName === 'number' && fieldValue.toString().length > 16) {
      return;
    }
    if (fieldName === 'expiryMonth' && fieldValue.toString().length > 2) {
      return;
    }
    if (fieldName === 'expiryYear' && fieldValue.toString().length > 4) {
      return;
    }
    if (fieldName === 'cvv' && fieldValue.toString().length > 3) {
      return;
    }

    setFormData({
      ...formData,
      [fieldName]: fieldValue,
    });
  };

  return (
    <div className="global-container onboarding-container subjectchoice-container">
      <Header />

      <section className="onboarding-container-dynamic">
        <div className="decoration-layer">
          <div className="decoration-layer-one"></div>
          <div className="decoration-layer-two"></div>
          <div className="decoration-layer-three"></div>
          <div className="decoration-layer-four"></div>
          <div className="decoration-layer-five"></div>
          <div className="decoration-layer-six"></div>
        </div>

        <div className="payment-container-content">
          <div className="payment-container-wrapper">
            <h1 className="payment-container-title">Choose Payment Method</h1>
            <div className="payment-container-selections">
              <div className="accordion-wrapper d-flex align-item-center justify-content-center">
                <div
                  className="accordion-wrapper-item"
                  onClick={() => handleSelectedPaymentMethod('CARD')}
                >
                  <div className="row-pay-option">
                    <img src={CreditCardIcon} alt="Card" />
                    <h5 className="accordion-title">
                      Debit Card / Credit Card
                    </h5>
                  </div>
                  <div className='chevron'>
                    <img src={RightChevron} alt='' className={selectedMethod==='CARD' ? 'chevron-active': null} />
                  </div>
                </div>
                {selectedMethod === 'CARD' && (
                  <div className="accordion-wrapper-content">
                    <div className='card-content'>
                      <div className="accordion-row-input">
                        <label className="accordion-row-label">
                          Card Number
                        </label>
                        <input
                          type="number"
                          value={formData.number}
                          placeholder="1234567812345678"
                          className="accordion-row-field"
                          onChange={(event) =>
                            handleFieldUpdate('number', event.target.value)
                          }
                        />
                      </div>
                      <div className="accordion-row-input">
                        <label className="accordion-row-label">
                          Name on Card
                        </label>
                        <input
                          type="text"
                          placeholder="Aayush Dutta"
                          className="accordion-row-field"
                          onChange={(event) =>
                            handleFieldUpdate('holder', event.target.value)
                          }
                        />
                      </div>
                        
                      <div className='accordion-row third-row'>
                        <div className="accordion-row-input">
                          <label className="accordion-row-label">
                            Expiry Month
                          </label>
                          <input
                            type="number"
                            placeholder="01"
                            value={formData.expiryMonth}
                            className="accordion-row-field"
                            onChange={(event) =>
                              handleFieldUpdate('expiryMonth', event.target.value)
                            }
                          />
                        </div>
                        <div className="accordion-row-input">
                          <label className="accordion-row-label">
                            Expiry Year
                          </label>
                          <input
                            type="number"
                            placeholder="2023"
                            value={formData.expiryYear}
                            className="accordion-row-field"
                            onChange={(event) =>
                              handleFieldUpdate('expiryYear', event.target.value)
                            }
                          />
                        </div>
                        <div className="accordion-row-input">
                          <label className="accordion-row-label">CVV</label>
                          <input
                            type="number"
                            placeholder="123"
                            value={formData.cvv}
                            className="accordion-row-field"
                            onChange={(event) =>
                              handleFieldUpdate('cvv', event.target.value)
                            }
                          />
                        </div>
                      </div>

                    <div className="accordion-row card-pay-btn">
                      <PrimaryButton
                        disabled={!enablePayment}
                        name="Pay"
                        onClick={handlePayment}
                      />
                    </div>
                  </div>
                </div>
                )}

                <div
                  className="accordion-wrapper-item"
                  onClick={() => handleSelectedPaymentMethod('UPI')}
                >
                  <div className="row-pay-option">
                    <img src={UpiIcon} alt="Card" />
                    <h5 className="accordion-title">UPI</h5>
                  </div>
                  <div className='chevron'>
                    <img src={RightChevron} alt='' className={selectedMethod==='UPI' ? 'chevron-active': null} />
                  </div>
                </div>
                {selectedMethod === 'UPI' && (
                  <div className="accordion-wrapper-content">
                    <div className="accordion-row">
                      <div className="accordion-row-input">
                        <label className="accordion-row-label">
                          Enter UPI ID
                        </label>
                        <input
                          type="text"
                          placeholder="example@ybl"
                          className="accordion-row-field"
                          onChange={(event) =>
                            handleFieldUpdate('vpa', event.target.value)
                          }
                        />
                      </div>
                    </div>
                    <div className="accordion-row">
                      <PrimaryButton
                        disabled={!enablePayment}
                        name="Pay"
                        onClick={handlePayment}
                      />
                    </div>
                  </div>
                )}

                <div
                  className="accordion-wrapper-item"
                  onClick={() => handleSelectedPaymentMethod('WALLET')}
                >
                  <div className="row-pay-option">
                    <img src={WalletIcon} alt="Card" />
                    <h5 className="accordion-title">Wallet</h5>
                  </div>
                  <div className='chevron'>
                    <img src={RightChevron} alt='' className={selectedMethod==='WALLET' ? 'chevron-active': null} />
                  </div>
                </div>
                {selectedMethod === 'WALLET' && (
                  <div className="accordion-wrapper-content">
                    <div className="accordion-row">
                      <div className="accordion-row-input">
                        <label htmlFor="code" className="accordion-row-label">
                          Select your Bank
                        </label>
                        <select
                          id="code"
                          type="select"
                          value={formData.code}
                          className="accordion-row-field"
                          onChange={(event) =>
                            handleFieldUpdate('code', event.target.value)
                          }
                        >
                          <option value="" disabled>
                            Please choose a Wallet
                          </option>
                          {CashfreeWalletCodes.map((walletCode, index) => {
                            return (
                              <option key={index} value={walletCode.value}>
                                {walletCode.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="accordion-row">
                      <PrimaryButton
                        disabled={!enablePayment}
                        name="Pay"
                        onClick={handlePayment}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentOrder: state.order.currentOrder,
  currentOrderType: state.order.currentOrderType,
});

const mapDispatchToProps = (dispatch) => ({
  setLoader: (dataPayload) => setLoaderAction(dispatch, dataPayload),
});

export default Private(connect(mapStateToProps, mapDispatchToProps)(Payment));
