import './styles.scss';

const ActivityDetails = (props) => {
  return (
    <div className="activity-content">
      <h5 className="activity-content-title">{props.resourceData.title}</h5>

      {props.resourceData.description?.texts?.length !== 0 &&
        props.resourceData.description?.texts.map((description, index) => {
          return (
            <h5 key={index} className="activity-content-description">
              {description}
            </h5>
          );
        })}

      <div className="activity-frame">
        {props.resourceData.source === 'Embeded' ? (
          <div
            className="activity-frame-embeded"
            dangerouslySetInnerHTML={{ __html: props.resourceData.activityURL }}
          ></div>
        ) : (
          props.resourceData.source === 'URL' && (
            <iframe
              className="activity-frame-url"
              src={props.resourceData.activityURL}
            />
          )
        )}
      </div>
    </div>
  );
};

export default ActivityDetails;
