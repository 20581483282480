import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { routeConstants } from '../common/routeConstants';

import PageLoader from '../components/PageLoader';

const Private = (ChildComponent) => {
  const PrivateHoc = (props) => {
    const history = useHistory();
    const [shouldRender, setShouldRender] = useState(false);

    useEffect(() => {
      if (!props?.auth?.accessToken) {
        return history.push(routeConstants.HOME);
      } else {
        setShouldRender(true);
      }
    }, [props.auth.accessToken]); // eslint-disable-line

    return (
      <>
        {props.site.loader && <PageLoader />}
        {shouldRender && <ChildComponent {...props} />}
      </>
    );
  };

  const mapStateToProps = (state) => ({
    site: state.site,
    profile: state.user.profile,
    auth: state.auth.userDetails,
  });

  const mapDispatchToProps = (dispatch) => ({});

  return connect(mapStateToProps, mapDispatchToProps)(PrivateHoc);
};

export default Private;
