import React, { useState } from 'react';

import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { logoutAction } from '../../actions/authActions';

import Logo from '../../assets/images/logo.svg';

import './styles.scss';

const Header = (props) => {
  const [showMenu, setShowMenu] = useState(false);

  const logoutHandler = () => {
    props.logout();
  };

  return (
    <header className='global-header'>
      <div className='global-header-left'>
        <Link to='#' onClick={() => window.open(process.env.REACT_APP_STATIC_APP_URL)}>
          <img src={Logo} className='global-header-logo' alt='Skipin' />
        </Link>
      </div>

      <div className='global-header-right'>
        {!props.hideMenu &&
        <div className='hamburger-menu'>
          <button
            type='button'
            className='global-breadcrumb-option'
            onClick={() => setShowMenu(!showMenu)}
          >
            <div className='hamburger-icon'>
              <span className='hamburger-icon-line d-block' />
              <span className='hamburger-icon-line d-block' />
              <span className='hamburger-icon-line d-block' />
            </div>
          </button>

          {showMenu && (
            <div className='hamburger-menu-items'>
              <Link to={'#'} onClick={() => logoutHandler()}>
                Logout
              </Link>
            </div>
          )}
        </div>
        }
      </div>
    </header>
  );
};

const mapDispatchToProps = (dispatch) => ({
  logout: (dataPayload) => logoutAction(dispatch, dataPayload),
});

export default connect(null, mapDispatchToProps)(Header);
