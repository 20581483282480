import React from 'react';
import store from './store/';
import AppRouter from './navigator/AppRouter';

import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { PersistGate } from 'redux-persist/integration/react';
import MobileOverlay from './components/MobileOverlay'

import './assets/styles/imports.scss';
import 'react-image-lightbox/style.css';
import 'react-toastify/dist/ReactToastify.css';

const { reduxStore, persistedStore } = store;

const App = () => {
  return (
    <>
      <MobileOverlay />
      <Provider store={reduxStore}>
        <PersistGate loading={null} persistor={persistedStore}>
          <AppRouter />
          <ToastContainer
            newestOnTop={true}
            closeOnClick={true}
            position="top-right"
            progressClassName="toast-progress"
          />
        </PersistGate>
      </Provider>
    </>
  );
};

export default App;
