import { reduxConstants } from '../common/reduxConstants';

const orderReducer = (
  state = {
    packages: [],
    products: [],
    productsIds: [],
    packagesIds: [],
    currentOrder: null,
    packageDiscount: 0,
    currentOrderType: null,
  },
  action
) => {
  switch (action.type) {
    case reduxConstants.CHOOSEPACKAGES:
      return {
        ...state,
        packages: action.payload.packages,
        packagesIds: action.payload.packagesIds,
        packageDiscount: action.payload.packageDiscount,
      };

    case reduxConstants.REMOVEPACKAGES:
      const updatedPackages = state.packages.filter(
        (packageItem) => packageItem._id !== action.payload
      );
      const updatedPackagesIds = state.packagesIds.filter(
        (packageId) => packageId !== action.payload
      );
      return {
        ...state,
        packages: updatedPackages,
        packagesIds: updatedPackagesIds,
      };

    case reduxConstants.SETCURRENTORDER:
      return {
        ...state,
        currentOrder: action.payload.currentOrder,
        currentOrderType: action.payload.currentOrderType,
      };

    case reduxConstants.ADDPRODUCTS:
      return {
        ...state,
        products: action.payload.products,
        productsIds: action.payload.productsIds,
      };

    case reduxConstants.REMOVEPRODUCTS:
      const updatedProducts = state.products.filter(
        (productItem) => productItem._id !== action.payload
      );
      const updatedProductsIds = state.productsIds.filter(
        (productId) => productId !== action.payload
      );
      return {
        ...state,
        products: updatedProducts,
        productsIds: updatedProductsIds,
      };

    case reduxConstants.RESETPACKAGES:
      return {
        ...state,
        packages: [],
        packagesIds: [],
        packageDiscount: 0,
      };

    case reduxConstants.RESETPRODUCTS:
      return {
        ...state,
        products: [],
        productsIds: [],
      };

    case reduxConstants.LOGOUT:
      return {
        packages: [],
        products: [],
        productsIds: [],
        packagesIds: [],
        currentOrder: null,
        packageDiscount: 0,
        currentOrderType: null,
      };

    default: {
      return state;
    }
  }
};

export default orderReducer;
