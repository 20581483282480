import { connect } from 'react-redux';
import { Carousel } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import React, { Fragment, useState, useEffect } from 'react';
import { AvailableSubjects } from '../../../common/constants';
import { setLoaderAction } from '../../../actions/siteActions';
import { setProfileAction } from '../../../actions/userActions';
import { routeConstants } from '../../../common/routeConstants';
import { showToast, shortenText } from '../../../common/helpers';

import ParentPrivate from '../../../hocs/ParentPrivate';
import userService from '../../../services/userService';
import UploadCard from '../../../components/UploadCard';
import ProductCard from '../../../components/ProductCard';
import TabularModal from '../../../components/TabularModal';
import SectionTitle from '../../../components/SectionTitle';
import ProductIcon from '../../../assets/images/product.svg';
import UploadIcon from '../../../assets/images/upload-icon.svg';
import BlueArrow from '../../../assets/images/blue-arrow.svg';
import MathsIcon from '../../../assets/images/maths-subject.svg';
import SubjectProgress from '../../../components/SubjectProgress';
import BreadcrumbStatic from '../../../components/BreadcrumbStatic';
import AdvertismentCard from '../../../components/AdvertismentCard';
import NavigationStatic from '../../../components/NavigationStatic';
import ScienceIcon from '../../../assets/images/science-subject.svg';
import EnglishIcon from '../../../assets/images/english-subject.svg';
import NewsletterIcon from '../../../assets/images/newsletter-icon.svg';
import MySessionsIcon from '../../../assets/images/my-sessions-icon.svg';

import './styles.scss';

const ParentDashboard = (props) => {
  const [dashboardData, setDashboardData] = useState({});
  const [uploadAttachments, setUploadAttachments] = useState([]);

  const history = useHistory();

  useEffect(() => {
    props.setLoader(true);
    userService
      .getParentDashboard()
      .then((responseData) => {
        if (responseData) {
          props.setLoader(false);
          if (responseData.code === 200 && responseData.data) {
            const data = responseData.data;
            if (data && !data.profile?.journey?.profile?.completed) {
              return history.push(routeConstants.DOWNLOADAPP);
            }
            if (data && !data.subjects.length) {
              return history.push(routeConstants.DOWNLOADAPP);
            }

            props.setProfile(data.profile);
            setDashboardData(data);
          }
        }
      })
      .catch((errorData) => {
        props.setLoader(false);
        showToast(errorData?.message);
      });
  }, []); // eslint-disable-line

  const getRenderingSubjectIcons = (subject) => {
    switch (subject) {
      case AvailableSubjects.Maths:
        return MathsIcon;

      case AvailableSubjects.Science:
        return ScienceIcon;

      case AvailableSubjects.English:
        return EnglishIcon;

      default:
        break;
    }
  };

  const handleSubjectRedirection = (subject, subjectID) => {
    history.push(
      routeConstants.PARENT_SUBJECTDETAILS.replace(':subjectID', subjectID)
    );
  };

  return (
    <div className="global-container content-container ">
      <NavigationStatic />
      <BreadcrumbStatic
        crumbs={[
          {
            name: 'Dashboard',
            route: routeConstants.PARENT_DASHBOARD,
          },
        ]}
      />

      <div className="parent-dashboard">
        <div className="subject-progress-row">
          {dashboardData?.subjects &&
            dashboardData?.subjects.length !== 0 &&
            dashboardData?.subjects.map((subject, index) => {
              return (
                <SubjectProgress
                  key={index}
                  total={subject.total}
                  name={subject.subject}
                  done={subject.completed}
                  icon={getRenderingSubjectIcons(subject.subject)}
                  onClick={() =>
                    handleSubjectRedirection(subject.subject, subject._id)
                  }
                />
              );
            })}
        </div>

        {/* Prodcuts Section */}

        {dashboardData &&
          dashboardData.products &&
          dashboardData.products.length !== 0 && (
            <section className="products section">
              <SectionTitle icon={ProductIcon} title="Products" />
              <div className="products-container">
                <div className="product-list">
                  {dashboardData.products.map((product, index) => {
                    if (index < 3) {
                      return (
                        <ProductCard
                          key={index}
                          type="products"
                          product={product}
                          title={product.title}
                          prices={product.prices}
                          thumbnail={product.thumbnail}
                        />
                      );
                    } else {
                      return <Fragment key={index}></Fragment>;
                    }
                  })}
                </div>
                {dashboardData.products.length > 3 && (
                  <Link
                    to={routeConstants.PARENT_ALLPRODUCTS}
                    className="view-all-products"
                  >
                    <div className="content">
                      <div className="all-products-arrow">
                        <img src={BlueArrow} alt="Right" className="icon" />
                      </div>
                      <p className="label">View All Products</p>
                    </div>
                  </Link>
                )}
              </div>
            </section>
          )}

        {/* Uploads Section */}

        {dashboardData &&
          dashboardData.uploads &&
          dashboardData.uploads.length !== 0 && (
            <section className="products section">
              <SectionTitle icon={UploadIcon} title="Uploads" />
              <div className="products-container">
                <div className="product-list">
                  {dashboardData.uploads.map((upload, index) => {
                    if (index < 3) {
                      return (
                        <UploadCard
                          key={index}
                          type="myProducts"
                          title={upload.project?.title}
                          thumbnail={upload.project?.thumbnail}
                          description={shortenText(
                            upload.project?.description?.texts &&
                              upload.project?.description.texts[0],
                            60
                          )}
                          onClick={() =>
                            setUploadAttachments(upload.attachments || [])
                          }
                        />
                      );
                    } else {
                      return <Fragment key={index}></Fragment>;
                    }
                  })}
                </div>
                {dashboardData.uploads.length > 3 && (
                  <Link
                    to={routeConstants.PARENT_ALLUPLOADS}
                    className="view-all-products"
                  >
                    <div className="content">
                      <div className="all-products-arrow">
                        <img src={BlueArrow} alt="Right" className="icon" />
                      </div>
                      <p className="label">View All Uploads</p>
                    </div>
                  </Link>
                )}
              </div>
            </section>
          )}

        {/* Sessions */}

        {dashboardData &&
          dashboardData.sessions &&
          dashboardData.sessions.length !== 0 && (
            <section className="sessions section">
              <SectionTitle icon={MySessionsIcon} title="Sessions" />

              <div className="products-container">
                <div className="product-list">
                  {dashboardData.sessions.map((mySession, index) => {
                    if (index < 3) {
                      return (
                        <ProductCard
                          key={index}
                          title={mySession.title}
                          thumbnail={mySession.thumbnail}
                          startDate={mySession.startDate}
                          type={
                            mySession.upcomingSession
                              ? 'upcomingSessions'
                              : 'mySessions'
                          }
                          onClick={() =>
                            history.push(
                              routeConstants.PARENT_SESSIONDETAILS.replace(
                                ':sessionID',
                                mySession._id
                              )
                            )
                          }
                        />
                      );
                    } else {
                      return <Fragment key={index}></Fragment>;
                    }
                  })}
                </div>
                {dashboardData.sessions.length > 3 && (
                  <Link
                    to={routeConstants.PARENT_ALLSESSIONS}
                    className="view-all-products"
                  >
                    <div className="content">
                      <div className="all-products-arrow">
                        <img src={BlueArrow} alt="Right" className="icon" />
                      </div>
                      <p className="label">View All Sessions</p>
                    </div>
                  </Link>
                )}
              </div>
            </section>
          )}

        {/* Banner Section */}

        {dashboardData &&
          dashboardData.banners &&
          dashboardData.banners.length !== 0 && (
            <Carousel className="dashboard-image-banner">
              {dashboardData.banners.map((banner, index) => {
                return (
                  <Carousel.Item key={index}>
                    <a href={banner.actionURL} target="_blank" rel="noreferrer">
                      <img
                        alt="Banner"
                        src={banner.imageURL}
                        className="banner-image"
                      />
                    </a>
                  </Carousel.Item>
                );
              })}
            </Carousel>
          )}

        {/* Advertisments Section */}

        {dashboardData &&
          dashboardData.advertisements &&
          dashboardData.advertisements.length !== 0 && (
            <section className="advert">
              <SectionTitle title="Advertisments" />
              <div className="advert-list">
                {dashboardData.advertisements.map((advert, index) => {
                  return (
                    <AdvertismentCard
                      key={index}
                      title={advert.title}
                      imageURL={advert.imageURL}
                      actionURL={advert.actionURL}
                    />
                  );
                })}
              </div>
            </section>
          )}

        {/* Newsletters Section */}

        {dashboardData &&
          dashboardData.newsletters &&
          dashboardData.newsletters.length !== 0 && (
            <section className="sessions section">
              <SectionTitle icon={NewsletterIcon} title="Newsletter Archives" />

              <div className="products-container">
                <div className="product-list">
                  {dashboardData.newsletters.map((newsletter, index) => {
                    if (index < 3) {
                      return (
                        <ProductCard
                          key={index}
                          type="mySessions"
                          title={newsletter.title}
                          thumbnail={newsletter.thumbnail}
                          onClick={() => window.open(newsletter.url, '_blank')}
                        />
                      );
                    } else {
                      return <Fragment key={index}></Fragment>;
                    }
                  })}
                </div>
                {dashboardData.newsletters.length > 3 && (
                  <Link
                    to={routeConstants.PARENT_NEWSLETTERS}
                    className="view-all-products"
                  >
                    <div className="content">
                      <div className="all-products-arrow">
                        <img src={BlueArrow} alt="Right" className="icon" />
                      </div>
                      <p className="label">View All Newsletters</p>
                    </div>
                  </Link>
                )}
              </div>
            </section>
          )}
      </div>

      {uploadAttachments.length !== 0 && (
        <TabularModal
          rows={uploadAttachments}
          title="View Uploaded Files"
          hideModal={() => setUploadAttachments([])}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  setLoader: (dataPayload) => setLoaderAction(dispatch, dataPayload),
  setProfile: (dataPayload) => setProfileAction(dispatch, dataPayload),
});

export default ParentPrivate(
  connect(mapStateToProps, mapDispatchToProps)(ParentDashboard)
);
