import axios from 'axios';
import get from 'lodash/get';
import store from '../store';
import { reduxConstants } from '../common/reduxConstants';

const baseAxiosInstance = axios.create({
  timeout: 60000,
  withCredentials: false,
  baseURL: process.env.REACT_APP_BASE_API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Headers':
      'Access-Control-Allow-Headers, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers',
  },
});

const cdnAxiosInstance = axios.create({
  timeout: 60000,
  withCredentials: false,
  baseURL: process.env.REACT_APP_CDN_API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Headers':
      'Access-Control-Allow-Headers, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers',
  },
});

const parentAxiosInstance = axios.create({
  timeout: 60000,
  withCredentials: false,
  baseURL: process.env.REACT_APP_BASE_API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Headers':
      'Access-Control-Allow-Headers, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers',
  },
});

const successResponseInterceptor = (responseData) => {
  console.log('+++++++++', 'Interceptor Output Starts', '+++++++++');
  console.log(responseData.data);
  console.log('+++++++++', 'Interceptor Output Ends', '+++++++++');

  return responseData.data;
};

const errorResponseInterceptor = (errorData) => {
  const errorResponse = get(errorData, 'response.data');

  if (!errorResponse) {
    return Promise.reject({
      message:
        'Network Connection Failure. Please Check Your Internet Access and Try Again.',
    });
  } else if (errorResponse.code === 401 || errorResponse.code === 403) {
    if (window.location.pathname.includes('parent')) {
      store.reduxStore.dispatch({
        payload: null,
        type: reduxConstants.PARENTLOGIN,
      });
    } else {
      store.reduxStore.dispatch({
        payload: {},
        type: reduxConstants.LOGOUT,
      });
    }
  }

  console.log('+++++++++', 'Interceptor Output Starts', '+++++++++');
  console.log(errorResponse);
  console.log('+++++++++', 'Interceptor Output Ends', '+++++++++');

  return Promise.reject(errorResponse);
};

baseAxiosInstance.interceptors.response.use(
  successResponseInterceptor,
  errorResponseInterceptor
);

cdnAxiosInstance.interceptors.response.use(
  successResponseInterceptor,
  errorResponseInterceptor
);

parentAxiosInstance.interceptors.response.use(
  successResponseInterceptor,
  errorResponseInterceptor
);

export { baseAxiosInstance, cdnAxiosInstance, parentAxiosInstance };
