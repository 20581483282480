import { connect } from 'react-redux';
import { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { showToast } from '../../../common/helpers';
import { setLoaderAction } from '../../../actions/siteActions';
import { setProfileAction } from '../../../actions/userActions';
import { routeConstants } from '../../../common/routeConstants';
import { AvailableSubjects, ResourceTypes } from '../../../common/constants';

import userService from '../../../services/userService';
import resourceService from '../../../services/resourceService';
import BookIcon from '../../../assets/images/book-icon.svg';
import WorksheetIcon from '../../../assets/images/worksheets.svg';
import YellowWorksheetIcon from '../../../assets/images/yellow-worksheet.svg';
import RedWorksheetIcon from '../../../assets/images/red-worksheet.svg';
import ProjectIcon from '../../../assets/images/projects.svg';
import YellowProjectIcon from '../../../assets/images/yellow-project.svg';
import RedProjectIcon from '../../../assets/images/red-project.svg';
import ActivityIcon from '../../../assets/images/activities.svg';
import YellowActivityIcon from '../../../assets/images/yellow-activity.svg';
import RedActivityIcon from '../../../assets/images/red-activity.svg';
import RightChevron from '../../../assets/images/right-chevron-icon.svg';
import RightChevronWhite from '../../../assets/images/right-chevron-white.png';
import WhiteActivityIcon from '../../../assets/images/white-activity.svg';
import WhiteProjectIcon from '../../../assets/images/white-project.svg';
import WhiteWorksheetIcon from '../../../assets/images/white-worksheet.svg';
import VideoPlaceholder from '../../../assets/images/video-thumbnail-placeholder.png';
import RightBlueArrow from '../../../assets/images/blue-right-arrow.png';

import './styles.scss';

const StudyKit = (props) => {
  const history = useHistory();
  const [videos, setVideos] = useState(null);
  const [allocatedResources, setAllocatedResources] = useState({});
  const [selectedResourceType, setSelectedResourceType] = useState('');

  useEffect(() => {
    setAllocatedResources(props.resources);
    setSelectedResourceType(ResourceTypes.WORKSHEET);
  }, [props.resources]); // eslint-disable-line

  useEffect(() => {
    userService.getProfile().then((responseData) => {
      if (responseData) {
        if (responseData.code === 200) {
          props.setProfile(responseData.data);
        }
      }
    });
  }, []); // eslint-disable-line

  const getSelectedIcon = (resourceType) => {
    switch (resourceType.toLowerCase()) {
      case ResourceTypes.PROJECT.toLowerCase():
        return WhiteProjectIcon;
      case ResourceTypes.WORKSHEET.toLowerCase():
        return WhiteWorksheetIcon;
      case ResourceTypes.ACTIVITY.toLowerCase():
        return WhiteActivityIcon;
      default:
        return '';
    }
  };

  const getRenderingIcon = (resourceType, subject) => {
    switch (resourceType.toLowerCase()) {
      case ResourceTypes.PROJECT.toLowerCase():
        if (subject?.toLowerCase() === AvailableSubjects.Maths.toLowerCase()) {
          return RedProjectIcon;
        } else if (
          subject?.toLowerCase() === AvailableSubjects.Science.toLowerCase()
        ) {
          return YellowProjectIcon;
        } else if (
          subject?.toLowerCase() === AvailableSubjects.English.toLowerCase()
        ) {
          return ProjectIcon;
        }
        break;

      case ResourceTypes.WORKSHEET.toLowerCase():
        if (subject?.toLowerCase() === AvailableSubjects.Maths.toLowerCase()) {
          return RedWorksheetIcon;
        } else if (
          subject?.toLowerCase() === AvailableSubjects.Science.toLowerCase()
        ) {
          return YellowWorksheetIcon;
        } else if (
          subject?.toLowerCase() === AvailableSubjects.English.toLowerCase()
        ) {
          return WorksheetIcon;
        }
        break;

      case ResourceTypes.ACTIVITY.toLowerCase():
        if (subject?.toLowerCase() === AvailableSubjects.Maths.toLowerCase()) {
          return RedActivityIcon;
        } else if (
          subject?.toLowerCase() === AvailableSubjects.Science.toLowerCase()
        ) {
          return YellowActivityIcon;
        } else if (
          subject?.toLowerCase() === AvailableSubjects.English.toLowerCase()
        ) {
          return ActivityIcon;
        }
        break;

      default:
        return ProjectIcon;
    }
  };

  const getAllocatedResourcesByType = (resourceType) => {
    setSelectedResourceType(resourceType);
    if (
      !allocatedResources[resourceType] ||
      !allocatedResources[resourceType].length
    ) {
      props.setLoader(true);
      resourceService
        .getAllocatedResourcesByType({
          lessonID: props.match.params.lessonID,
          resourceType,
        })
        .then((responseData) => {
          if (responseData) {
            if (responseData.code === 200 && responseData.data) {
              props.setLoader(false);
              setAllocatedResources({
                ...allocatedResources,
                [resourceType]: responseData.data || [],
              });
            }
          }
        })
        .catch((errorData) => {
          props.setLoader(false);
          showToast(errorData?.message);
        });
    }
  };

  const retryWalkthrough = () => {
    props.setLoader(true);
    resourceService
      .getAllocatedResourcesByType({
        lessonID: props.match.params.lessonID,
        resourceType: ResourceTypes.WALKTHROUGH,
      })
      .then((responseData) => {
        if (responseData) {
          if (responseData.code === 200 && responseData.data) {
            if (responseData.data.walkthrough === true) {
              props.setLoader(false);
              props.retryWalkthrough(responseData.data.question);
            }
          }
        }
      })
      .catch((errorData) => {
        props.setLoader(false);
        showToast(errorData?.message);
      });
  };

  const getAllocatedResource = (resourceID) => {
    history.push(
      routeConstants.RESOURCEDETAILS.replace(
        ':resourceType',
        selectedResourceType.toLowerCase()
      ).replace(':resourceID', resourceID)
    );
  };

  const getVideosHandler = () => {
    setSelectedResourceType('Video');
    if (Array.isArray(videos)) {
      return;
    }
    props.setLoader(true);
    userService
      .getVideos({
        lessonID: props.match.params.lessonID,
      })
      .then((responseData) => {
        if (responseData) {
          if (responseData.code === 200 && responseData.data) {
            props.setLoader(false);
            setVideos(responseData.data);
          }
        }
      })
      .catch((errorData) => {
        props.setLoader(false);
        showToast(errorData?.message);
      });
  };

  const getVideoDetails = (videoID) => {
    history.push(routeConstants.VIDEODETAILS.replace(':videoID', videoID));
  };

  return (
    <div className="global-container studykit-container">
      <section className="onboarding-container-dynamic">
        <div className="studykit-container-content">
          <div className="studykit-header">
            <h1 className="studykit-header-title">
              {props.lessonDetails?.chapter}
            </h1>
            <h2 className="studykit-header-subtitle">
              {props.lessonDetails?.subjectID?.subject}
            </h2>
          </div>
          <div className="studykit-content">
            <div className="row d-flex align-items-center justify-content-center study-kit-title-container">
              <img src={BookIcon} className="book-icon" alt="Book" />
              <h5>Study Kit</h5>
            </div>
            <p className="subtitle">
              Walkthrough of the lesson has unlocked this study kit. This study
              kit is made just for you by our AI based adaptive modelling
              programme. Enjoy all the resources as many times as you want to.
            </p>
            <div className="studykit-content studykit-content-tabs">
              <div className="studykit-content-video">
                <h5
                  className={`studykit-content-tab-label ${
                    selectedResourceType === 'Video' ? 'active-label' : ''
                  }`}
                  onClick={getVideosHandler}
                >
                  Videos
                </h5>
                <div className="studykit-content-walkthrough">
                  <h5
                    className="studykit-content-tab-label"
                    onClick={retryWalkthrough}
                  >
                    Walkthrough
                  </h5>
                </div>
              </div>
            </div>
            <div className="row d-flex align-item-center studykit-body">
              <div className="col-4 studykit-body-left">
                {props.resourceTypes &&
                  props.resourceTypes.length !== 0 &&
                  props.resourceTypes.map((resourceObj, index) => {
                    return (
                      <div
                        key={index}
                        className={
                          selectedResourceType === resourceObj.resourceType
                            ? 'studykit-resource studykit-resource-selected'
                            : 'studykit-resource'
                        }
                        onClick={() =>
                          getAllocatedResourcesByType(resourceObj.resourceType)
                        }
                      >
                        <img
                          alt="Resource Type"
                          src={
                            selectedResourceType === resourceObj.resourceType
                              ? getSelectedIcon(resourceObj.resourceType)
                              : getRenderingIcon(
                                  resourceObj.resourceType,
                                  props.lessonDetails?.subjectID?.subject
                                )
                          }
                        />
                        <div className="resource-info">
                          <h5
                            className="resource-title"
                            style={
                              selectedResourceType === resourceObj.resourceType
                                ? { color: '#fff' }
                                : {}
                            }
                          >
                            {resourceObj.resourceType}
                          </h5>
                          <p
                            className="resource-subtitle"
                            style={
                              selectedResourceType === resourceObj.resourceType
                                ? { color: '#fff' }
                                : {}
                            }
                          >
                            {resourceObj.count} Resources
                          </p>
                        </div>
                        <div className="chevron">
                          {selectedResourceType === resourceObj.resourceType ? (
                            <img src={RightChevronWhite} alt="" />
                          ) : (
                            <img src={RightChevron} alt="" />
                          )}
                        </div>
                      </div>
                    );
                  })}
              </div>
              <div className="col-8 studykit-body-right">
                {selectedResourceType === 'Video' ? (
                  <div className="content">
                    {videos && videos.length !== 0 ? (
                      videos.map((videoItem, index) => {
                        return (
                          <div
                            key={index}
                            className="resource-item resource-item-video"
                          >
                            <img
                              alt="Resource"
                              src={VideoPlaceholder}
                              className="resource-item-icon"
                              onClick={() => getVideoDetails(videoItem._id)}
                            />
                            <div className="res-info">
                              <h5 className="resource-item-title">
                                {videoItem.title}
                              </h5>
                              <Link
                                to={'#'}
                                className="link"
                                onClick={() => getVideoDetails(videoItem._id)}
                              >
                                <span>Play Video</span>
                                <img src={RightBlueArrow} alt="" />
                              </Link>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <p className="studykit-msg">No resources found</p>
                    )}
                  </div>
                ) : (
                  <div className="content">
                    {selectedResourceType ? (
                      allocatedResources &&
                      allocatedResources[selectedResourceType] &&
                      allocatedResources[selectedResourceType].length !== 0 ? (
                        allocatedResources[selectedResourceType].map(
                          (resource, index) => {
                            return (
                              <div
                                key={index}
                                className="resource-item"
                                onClick={() =>
                                  getAllocatedResource(resource._id)
                                }
                              >
                                <img
                                  alt="Resource"
                                  src={resource.thumbnail}
                                  className="resource-item-icon"
                                />
                                <div className="res-info">
                                  <h5 className="resource-item-title">
                                    {resource.title}
                                  </h5>
                                  <p className="resource-item-description">
                                    {resource.description?.texts &&
                                      resource.description.texts[0]}
                                  </p>
                                </div>
                              </div>
                            );
                          }
                        )
                      ) : (
                        <p className="studykit-msg">No resources found</p>
                      )
                    ) : (
                      <p className="studykit-msg">Please select a type</p>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  setLoader: (dataPayload) => setLoaderAction(dispatch, dataPayload),
  setProfile: (dataPayload) => setProfileAction(dispatch, dataPayload),
});

export default connect(mapStateToProps, mapDispatchToProps)(StudyKit);
