import { apiConstants } from '../common/apiConstants';
import { baseAxiosInstance, cdnAxiosInstance, parentAxiosInstance } from '.';

const resourceService = {
  submitAnswer: (dataPayload) =>
    baseAxiosInstance.post(apiConstants.POST_SUBMIT_ANSWER, dataPayload),
  nextQuestion: (dataPayload) =>
    baseAxiosInstance.get(apiConstants.GET_QUESTION(dataPayload.code)),
  getStudyKit: (dataPayload) =>
    baseAxiosInstance.get(apiConstants.STUDY_KIT(dataPayload.lessonID)),
  getAllocatedResourcesByType: (dataPayload) =>
    baseAxiosInstance.get(
      apiConstants.GET_ALLOCATED_RESOURCE_BY_TYPE(
        dataPayload.lessonID,
        dataPayload.resourceType
      )
    ),
  getAllocatedResourcesById: (dataPayload) =>
    baseAxiosInstance.get(
      apiConstants.GET_ALLOCATED_RESOURCE_BY_ID(
        dataPayload.resourceID,
        dataPayload.resourceType
      )
    ),
  startWorksheet: (dataPayload) =>
    baseAxiosInstance.get(apiConstants.START_WORKSHEET(dataPayload.resourceID)),
  uploadProjectFile: (dataPayload) =>
    cdnAxiosInstance.post(apiConstants.UPLOAD_PROJECT_FILE, dataPayload),
  submitProject: (dataPayload) =>
    baseAxiosInstance.post(apiConstants.SUBMIT_PROJECT, dataPayload),
  getProducts: () => baseAxiosInstance.get(apiConstants.GET_PRODUCTS),
  getMyProducts: () => baseAxiosInstance.get(apiConstants.GET_MY_PRODUCTS),
  addUserProduct: (dataPayload) =>
    baseAxiosInstance.post(apiConstants.ADD_USER_PRODUCT, dataPayload),
  verifyProductPayment: (dataPayload) =>
    baseAxiosInstance.post(apiConstants.VERIFY_PRODUCT_PAYMENT, dataPayload),
  failProductPayment: (dataPayload) =>
    baseAxiosInstance.post(apiConstants.FAIL_PRODUCT_PAYMENT, dataPayload),
  getModelProjects: () =>
    parentAxiosInstance.get(apiConstants.PARENT_MODEL_PROJECTS),
  startFreebieWorksheet: (dataPayload) =>
    baseAxiosInstance.post(apiConstants.STATIC_WORKHEET_START, dataPayload),
  getFreebieWorksheetQuestion: (dataPayload) =>
    baseAxiosInstance.post(apiConstants.STATIC_WORKHEET_QUESTION, dataPayload),
  answerFreebieWorksheetQuestion: (dataPayload) =>
    baseAxiosInstance.post(apiConstants.STATIC_WORKHEET_ANSWER, dataPayload),
};

export default resourceService;
