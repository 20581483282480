import { useState, useEffect } from 'react';

import isEqual from 'lodash/isEqual';
import InputText from '../../InputText';
import QuestionHeader from '../QuestionHeader';
import PrimaryButton from '../../PrimaryButton';
import WrongAnswerToast from '../../WrongAnswerToast';

import styles from './styles.scss';
import QuestionSuggestions from '../QuestionSuggestions';

const SAQQuestion = (props) => {
  const [correctAnswer, setCorrectAnswer] = useState('');
  const [answerResponse, setAnswerResponse] = useState(null);

  useEffect(() => {
    if (!isEqual(answerResponse, props.answerResponse)) {
      setAnswerResponse(props.answerResponse);
    }
  }, [props.answerResponse]); // eslint-disable-line

  useEffect(() => {
    setCorrectAnswer('');
  }, [props.activeQuestion]); // eslint-disable-line

  const handleAnswerSubmit = () => {
    props.answerSubmitHandler(correctAnswer);
  };

  return (
    <div style={styles} className="saq-container">
      <div className="content-scroller">
        {props.activeQuestion?.question?.length !== 0 && (
          <div className="saq-container-content">
            <QuestionHeader question={props.activeQuestion.question} />

            <div className="saq-container-item">
              <InputText
                type="text"
                value={correctAnswer}
                placeholder="Type answer"
                onChange={(event) =>
                  setCorrectAnswer(event.currentTarget.value)
                }
              />
            </div>
            {props.activeQuestion?.questionSuggestions?.length !== 0 && (
              <QuestionSuggestions activeQuestion={props.activeQuestion} />
            )}
          </div>
        )}
      </div>

      <div className="saq-container-footer">
        {answerResponse && !answerResponse.correct && <WrongAnswerToast />}

        <PrimaryButton
          name="Submit"
          showIcon={true}
          disabled={!correctAnswer}
          onClick={handleAnswerSubmit}
        />
      </div>
    </div>
  );
};

export default SAQQuestion;
