import { connect } from 'react-redux';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { showToast } from '../../../common/helpers';
import { ResourceTypes } from '../../../common/constants';
import { setLoaderAction } from '../../../actions/siteActions';
import { routeConstants } from '../../../common/routeConstants';
import {
  setActiveQuestionAction,
  setQuestionnaireBreadcrumbsAction,
} from '../../../actions/resourceActions';

import Private from '../../../hocs/Private';
import resourceService from '../../../services/resourceService';
import NavigationStatic from '../../../components/NavigationStatic';
import BreadcrumbStatic from '../../../components/BreadcrumbStatic';
import ProjectDetails from '../../../components/Resources/ProjectDetails';
import ActivityDetails from '../../../components/Resources/ActivityDetails';

import './styles.scss';

const ResourceDetails = (props) => {
  const history = useHistory();
  const [resourceData, setResourceData] = useState(null);

  useEffect(() => {
    props.setLoader(true);
    resourceService
      .getAllocatedResourcesById({
        resourceType: getResourceType(),
        resourceID: props.match.params.resourceID,
      })
      .then((responseData) => {
        if (responseData) {
          props.setLoader(false);
          if (responseData.code === 200 && responseData.data) {
            const resourceType = getResourceType();
            if (resourceType === ResourceTypes.WORKSHEET) {
              return startWorksheet(responseData.data);
            }
            setResourceData(responseData.data);
          }
        }
      })
      .catch((errorData) => {
        props.setLoader(false);
        showToast(errorData?.message);
      });
  }, []); // eslint-disable-line

  const getResourceType = () => {
    switch (props.match.params.resourceType) {
      case ResourceTypes.WORKSHEET.toLowerCase():
        return ResourceTypes.WORKSHEET;

      case ResourceTypes.PROJECT.toLowerCase():
        return ResourceTypes.PROJECT;

      case ResourceTypes.ACTIVITY.toLowerCase():
        return ResourceTypes.ACTIVITY;

      default:
        break;
    }
  };

  const startWorksheet = (resourceData) => {
    props.setLoader(true);
    resourceService
      .startWorksheet({
        resourceID: props.match.params.resourceID,
      })
      .then((responseData) => {
        if (responseData) {
          props.setLoader(false);
          if (responseData.code === 200 && responseData.data) {
            props.setActiveQuestion(responseData.data);
            const englishTag = resourceData.chapterID?.englishTag;
            const subjectRoute = routeConstants.SUBJECTDETAILS.replace(
              ':subjectID',
              resourceData.chapterID?.subjectID?._id
            );

            props.setQuestionnaireBreadcrumbs([
              {
                name: 'Dashboard',
                route: routeConstants.DASHBOARD,
              },
              {
                name: resourceData.chapterID?.subjectID?.subject,
                route: englishTag
                  ? `${subjectRoute}?et=${englishTag}`
                  : subjectRoute,
              },
              {
                name: resourceData.chapterID?.chapter,
                route: routeConstants.LESSONDETAILS.replace(
                  ':lessonID',
                  resourceData.chapterID?._id
                ),
              },
              {
                name: ResourceTypes.WORKSHEET ,
                route: '#',
              },
            ]);
            history.replace(routeConstants.WORKSHEET);
          }
        }
      })
      .catch((errorData) => {
        props.setLoader(false);
        showToast(errorData?.message);
      });
  };

  const getBreadcrumbs = () => {
    if (resourceData && resourceData.chapterID) {
      const englishTag = resourceData.chapterID?.englishTag;
      const subjectRoute = routeConstants.SUBJECTDETAILS.replace(
        ':subjectID',
        resourceData.chapterID?.subjectID?._id
      );

      return [
        {
          name: 'Dashboard',
          route: routeConstants.DASHBOARD,
        },
        {
          name: resourceData.chapterID?.subjectID?.subject,
          route: englishTag ? `${subjectRoute}?et=${englishTag}` : subjectRoute,
        },
        {
          name: resourceData.chapterID?.chapter,
          route: routeConstants.LESSONDETAILS.replace(
            ':lessonID',
            resourceData.chapterID?._id
          ),
        },
        {
          name: `${resourceData.resourceType}: ${resourceData.title}`,
          route: '#',
        },
      ];
    } else {
      return [
        {
          name: 'Dashboard',
          route: routeConstants.DASHBOARD,
        },
      ];
    }
  };

  return (
    <div className="global-container content-container">
      <NavigationStatic />

      <section className="content-container-dynamic">
        <BreadcrumbStatic crumbs={getBreadcrumbs()} />

        <div className="resourcedetails-container">
          {resourceData?.resourceType === ResourceTypes.PROJECT ? (
            <ProjectDetails
              {...props}
              resourceData={resourceData}
              setResourceData={setResourceData}
            />
          ) : (
            resourceData?.resourceType === ResourceTypes.ACTIVITY && (
              <ActivityDetails resourceData={resourceData} />
            )
          )}
        </div>
      </section>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  setActiveQuestion: (dataPayload) =>
    setActiveQuestionAction(dispatch, dataPayload),
  setQuestionnaireBreadcrumbs: (dataPayload) =>
    setQuestionnaireBreadcrumbsAction(dispatch, dataPayload),
  setLoader: (dataPayload) => setLoaderAction(dispatch, dataPayload),
});

export default Private(
  connect(mapStateToProps, mapDispatchToProps)(ResourceDetails)
);
