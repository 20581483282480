import { baseAxiosInstance } from './';
import { apiConstants } from '../common/apiConstants';

const authService = {
  login: (dataPayload) =>
    baseAxiosInstance.post(apiConstants.LOGIN, dataPayload),

  verify: (dataPayload) =>
    baseAxiosInstance.post(apiConstants.VERIFY, dataPayload),

  parentLogin: (dataPayload) =>
    baseAxiosInstance.post(apiConstants.PARENTLOGIN, dataPayload),

  parentLoginRequest: (dataPayload) =>
    baseAxiosInstance.post(apiConstants.PARENTLOGINREQUEST, dataPayload),

  parentVerify: (dataPayload) =>
    baseAxiosInstance.post(apiConstants.PARENTVERIFY, dataPayload),
};

export default authService;
