import { reduxConstants } from '../common/reduxConstants';

const userReducer = (
  state = {
    profile: false,
    profileTs: null,
  },
  action
) => {
  switch (action.type) {
    case reduxConstants.PROFILE:
      return {
        ...state,
        profileTs: Date.now(),
        profile: action.payload,
      };

    case reduxConstants.LOGOUT:
      return {
        profile: false,
        profileTs: null,
      };

    default: {
      return state;
    }
  }
};

export default userReducer;
