export const DialogTextsPositive = [
    {
      title: 'Great Going!',
      subTitle: 'Go ahead and try something new.',
    },
    {
      title: 'Perfect!',
      subTitle: 'Go ahead and try something new.',
    },
    {
      title: `That's Correct!`,
      subTitle: 'Go ahead and try something new.',
    },
    {
      title: 'You are Right!',
      subTitle: 'Go ahead and try something new.',
    },
  ];
  
  export const DialogTextsNegetive = [
    {
      title: `We've hit a bump!`,
      subTitle: 'Check the explanation below and move on.',
    },
    {
      title: 'This seems to be a slip up!',
      subTitle: 'Check the explanation below and move on.',
    },
    {
      title: 'Hurdle along the way?',
      subTitle: 'Check the explanation below and move on.',
    },
    {
      title: 'Having hiccups?',
      subTitle: 'Check the explanation below and move on.',
    },
  ];