import { apiConstants } from '../common/apiConstants';
import { baseAxiosInstance, cdnAxiosInstance, parentAxiosInstance } from './';

const userService = {
  getProfile: () => baseAxiosInstance.get(apiConstants.GET_PROFILE),

  updateProfile: (dataPayload) =>
    baseAxiosInstance.put(apiConstants.UPDATE_PROFILE, dataPayload),

  getDashboard: () => baseAxiosInstance.get(apiConstants.DASHBOARD),
  getSessionDetails: (dataPayload) =>
    baseAxiosInstance.get(
      apiConstants.SESSIONDETAILS.replace(':sessionID', dataPayload.sessionID)
    ),
  getSessions: (dataPayload) =>
    baseAxiosInstance.post(apiConstants.SESSIONS, dataPayload),
  support: (dataPayload) =>
    baseAxiosInstance.post(apiConstants.SUPPORT, dataPayload),
  uploadSupportFile: (dataPayload) =>
    cdnAxiosInstance.post(apiConstants.UPLOAD_SUPPORT_FILE, dataPayload),
  search: (dataPayload) =>
    baseAxiosInstance.post(apiConstants.SEARCH, dataPayload),
  getParentDashboard: () =>
    parentAxiosInstance.get(apiConstants.PARENTDASHBOARD),
  getNewsletters: () => parentAxiosInstance.get(apiConstants.PARENTNEWSLETTER),
  getUploads: () => parentAxiosInstance.get(apiConstants.PARENTUPLOADS),
  getVideoDetails: (dataPayload) =>
    baseAxiosInstance.get(
      apiConstants.VIDEO_DETAILS.replace(':videoID', dataPayload.videoID)
    ),
  getVideos: (dataPayload) =>
    baseAxiosInstance.post(apiConstants.VIDEOS, dataPayload),
  downloadApp: (dataPayload) => baseAxiosInstance.post(apiConstants.DOWNLOAD_APP, dataPayload),
};

export default userService;
