import { useState } from 'react';
import { showToast } from '../../../common/helpers';

import PrimaryButton from '../../PrimaryButton';
import PlusIcon from '../../../assets/images/plus-blue-icon.svg';
import resourceService from '../../../services/resourceService';
import TrashIcon from '../../../assets/images/trash-icon.svg';


import './styles.scss';

const ProjectDetails = (props) => {
  const [attachments, setAttachments] = useState([]);
  const [isUploaded, setIsUploaded] = useState(false);

  const uploadFileHandler = (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('file', file);
    formData.append('directoryName', props.resourceData?._id || null);

    props.setLoader(true);
    resourceService
      .uploadProjectFile(formData)
      .then((responseData) => {
        if (responseData) {
          props.setLoader(false);
          if (responseData.code === 200 && responseData.data) {
            setIsUploaded(true);
            setAttachments([
              ...attachments,
              { name: file.name, url: responseData.data?.url },
            ]);
          }
        }
      })
      .catch((errorData) => {
        props.setLoader(false);
        showToast(errorData?.message);
      });
  };

  const removeAttachment = (index) => {
    let attachmentsCopy = Object.assign([], attachments);
    attachmentsCopy = attachments.filter(
      (item, itemIndex) => itemIndex !== index
    );
    setAttachments(attachmentsCopy);
  };

  const submitProject = () => {
    props.setLoader(true);
    resourceService
      .submitProject({ projectID: props.resourceData?._id, attachments })
      .then((responseData) => {
        if (responseData) {
          props.setLoader(false);
          if (responseData.code === 200 && responseData.data) {
            props.setResourceData(responseData.data);
            showToast('Project Submitted Successfully!');
          }
        }
      })
      .catch((errorData) => {
        props.setLoader(false);
        showToast(errorData?.message);
      });
  };

  return (
    <>
      <div className="resourcedetails-container-header">
        <PrimaryButton
          name="Download"
          onClick={() => window.open(props.resourceData.projectURL)}
        />
      </div>

      <div className="row project-content">
        <div className="col-7 project-content-left">
          <h5 className="project-content-left-title">
            {props.resourceData?.title}
          </h5>

          {props.resourceData?.description?.texts?.length !== 0 &&
            props.resourceData?.description?.texts.map((description, index) => {
              return (
                <h5 key={index} className="project-content-left-description">
                  {description}
                </h5>
              );
            })}

          {!props.resourceData?.completed && (
            <div className="project-upload">
              {!isUploaded ? (
                <label className="project-upload-cta" htmlFor="uploader">
                  <input
                    type="file"
                    id="uploader"
                    name="uploader"
                    accept="application/pdf"
                    onChange={uploadFileHandler}
                    className="project-upload-input"
                  />
                  <p className='label'>Upload Files</p>
                </label>
              ) : (
                <>
                  <div className="project-upload-header">
                    <h5 className="project-upload-header-title">
                      Uploaded Files:
                    </h5>
                    <label className="project-upload-icon" htmlFor="uploader1">
                      <input
                        type="file"
                        id="uploader1"
                        name="uploader1"
                        accept="application/pdf"
                        onChange={uploadFileHandler}
                        className="project-upload-input"
                      />
                      <img src={PlusIcon} className="upload-icon" alt="Upload" />
                    </label>
                  </div>

                  <div className="row project-upload-attachments">
                    {attachments &&
                      attachments.length !== 0 &&
                      attachments.map((attachment, index) => {
                        return (
                          <div key={index} className="col-6 attachment-item">
                            <h6 className="attachment-item-number">
                              {index + 1}
                            </h6>
                            <div className="attachment-item-name">
                              {attachment.name}
                            </div>
                            <img
                              alt="Remove"
                              src={TrashIcon}
                              className="attachment-item-remove"
                              onClick={() => removeAttachment(index)}
                            />
                          </div>
                        );
                      })}
                  </div>

                  {
                    attachments.length!==0 &&
                    (<PrimaryButton
                    name="Submit"
                    showIcon={true}
                    onClick={submitProject}
                  />)
                  }
                </>
              )}
            </div>
          )}
        </div>

        <div className="col-5 project-content-right">
          <img src={props.resourceData?.thumbnail} alt="Project" />
        </div>
      </div>
    </>
  );
};

export default ProjectDetails;
