import './styles.scss';

const LinkButton = (props) => {
  return (
    <button
      type="button"
      name={props.name}
      onClick={props.onClick}
      className={
        props.className ? `link-button ${props.className}` : 'link-button'
      }
      {...props}
    >
      {props.name}
    </button>
  );
};

export default LinkButton;
