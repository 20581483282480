const userPrefix = '/user';
export const apiConstants = {
  GET_LESSON_DETAILS: (lessonID) => `${userPrefix}/lessons/${lessonID}`,
  POST_SUBMIT_ANSWER: `${userPrefix}/content/answer`,
  GET_QUESTION: (questionCode) =>
    `${userPrefix}/content/question/${questionCode}`,
  LOGIN: `${userPrefix}/login`,
  VERIFY: `${userPrefix}/login/verify`,
  GET_CLASSES: `${userPrefix}/classes`,
  GET_PROFILE: `${userPrefix}/profile`,
  UPDATE_PROFILE: `${userPrefix}/profile`,
  GET_SUBJECTS: `${userPrefix}/subjects`,
  GET_SUBJECT_DETAILS: `${userPrefix}/lessons/`,
  GET_PACKAGES: `${userPrefix}/packages`,
  VERIFY_COUPON: `${userPrefix}/packages/coupon`,
  VERIFY_GIFTCARD: `${userPrefix}/packages/giftcard`,
  ADD_USER_PACKAGE: `${userPrefix}/packages/order`,
  REDEEM_USER_PACKAGE: `${userPrefix}/packages/redeem`,
  VERIFY_PAYMENT: `${userPrefix}/packages/verify`,
  FAIL_PAYMENT: `${userPrefix}/packages/fail`,
  STUDY_KIT: (lessonID) =>
    `${userPrefix}/content/allocated/types/?lessonID=${lessonID}`,
  GET_ALLOCATED_RESOURCE_BY_TYPE: (lessonID, resourceType) =>
    `${userPrefix}/content/allocated/resources/?lessonID=${lessonID}&resourceType=${resourceType}`,
  GET_ALLOCATED_RESOURCE_BY_ID: (resourceID, resourceType) =>
    `${userPrefix}/content/allocated/resources/${resourceID}?resourceType=${resourceType}`,
  START_WORKSHEET: (resourceID) =>
    `${userPrefix}/content/allocated/worksheet/${resourceID}`,
  UPLOAD_PROJECT_FILE: '/user/media/projects',
  SUBMIT_PROJECT: `${userPrefix}/content/allocated/project/`,
  TRANSACTIONS: `${userPrefix}/orders/transactions`,
  MEMBERSHIP: `${userPrefix}/orders/membership`,
  DASHBOARD: `${userPrefix}/dashboard`,
  SESSIONDETAILS: `${userPrefix}/sessions/:sessionID`,
  SESSIONS: `${userPrefix}/sessions`,
  GET_PRODUCTS: `${userPrefix}/content/products`,
  GET_MY_PRODUCTS: `${userPrefix}/content/products/allocated`,
  ADD_USER_PRODUCT: `${userPrefix}/content/products/order`,
  VERIFY_PRODUCT_PAYMENT: `${userPrefix}/content/products/verify`,
  FAIL_PRODUCT_PAYMENT: `${userPrefix}/content/products/fail`,
  SUPPORT: `${userPrefix}/support`,
  UPLOAD_SUPPORT_FILE: '/user/media/support',
  SEARCH: `${userPrefix}/search`,
  PARENTLOGIN: `${userPrefix}/parent/login`,
  PARENTLOGINREQUEST: `${userPrefix}/parent/login/request`,
  PARENTVERIFY: `${userPrefix}/parent/login/verify`,
  PARENTDASHBOARD: `${userPrefix}/parent/dashboard`,
  PARENTNEWSLETTER: `${userPrefix}/parent/newsletters`,
  PARENTUPLOADS: `${userPrefix}/parent/uploads`,
  PARENT_MODEL_PROJECTS: `${userPrefix}/parent/projects/featured`,
  PARENT_SUBJECT_DETAILS: `${userPrefix}/parent/lessons/`,
  VIDEOS: `${userPrefix}/videos/`,
  VIDEO_DETAILS: `${userPrefix}/videos/:videoID`,
  DOWNLOAD_APP: '/download/app',

  // FREEBIE
  
  STATIC_WORKHEET_START: `/static/worksheet/start`,
  STATIC_WORKHEET_QUESTION: `/static/worksheet/question`,
  STATIC_WORKHEET_ANSWER: `/static/worksheet/answer`
};
