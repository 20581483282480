import { connect } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { parentAxiosInstance } from '../../../../services';
import { setLoaderAction } from '../../../../actions/siteActions';
import { setProfileAction } from '../../../../actions/userActions';
import { routeConstants } from '../../../../common/routeConstants';
import {
  parentLoginAction,
  setParentOtpAction,
} from '../../../../actions/authActions';
import { getDeviceIdentifier, showToast } from '../../../../common/helpers';

import moment from 'moment';
import Logo from '../../../../assets/images/logo.svg';
import authService from '../../../../services/authService';
import PrimaryButton from '../../../../components/PrimaryButton';

import './styles.scss';

const ParentOTPEntry = (props) => {
  let timerTicker = null;
  const history = useHistory();
  const ref1 = useRef();
  const ref2 = useRef();
  const ref3 = useRef();
  const ref4 = useRef();
  const [otp, setOtp] = useState({
    1: '',
    2: '',
    3: '',
    4: '',
  });
  const [timerObject, setTimerObject] = useState({
    timerLimit: 59,
    resendCount: 1,
    isTimerEnabled: true,
    isResendEnabled: false,
  });
  const [mobileNumber, setMobileNumber] = useState('');

  useEffect(() => {
    if (!props?.auth?.accessToken || !props?.profile?.mobileNumber) {
      return history.replace(routeConstants.DASHBOARD);
    } else if (
      !props?.profile?.parentMobileNumber &&
      !history?.location?.state?.mobileNumber
    ) {
      return history.replace(routeConstants.PARENT_ONBOARDING_MOBILE);
    } else if (!props.parentAuth?.accessToken) {
      setMobileNumber(
        props?.profile?.parentMobileNumber ||
          history?.location?.state?.mobileNumber
      );
      resendOtp(
        props?.profile?.parentMobileNumber ||
          history?.location?.state?.mobileNumber,
        true
      );
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    if (props.parentAuth?.accessToken) {
      return history.replace(routeConstants.PARENT_DASHBOARD);
    }
  }, [props.parentAuth?.accessToken]); // eslint-disable-line

  const handleFieldUpdate = (fieldName, fieldValue) => {
    if (fieldValue.length > 1) {
      return
    }

    let otpCopy = Object.assign({}, otp);
    otpCopy[fieldName] = fieldValue;
    setOtp(otpCopy);

    if (/^\d+$/.test(fieldValue)) {
      switch (fieldName) {
        case '1':
          ref2.current.focus();
          break;

        case '2':
          ref3.current.focus();
          break;

        case '3':
          ref4.current.focus();
          break;

        default:
          break;
      }
    }
  };

  const getOtp = () => {
    return `${otp[1]}${otp[2]}${otp[3]}${otp[4]}`;
  };

  const onKeyDown = (keyCode, fieldName) => {
    if (keyCode === 8 && !otp[fieldName]) {
      switch (fieldName) {
        case '2':
          ref1.current.focus();
          break;

        case '3':
          ref2.current.focus();
          break;

        case '4':
          ref3.current.focus();
          break;

        default:
          break;
      }
    }
  };

  const submitHandler = () => {
    props.setLoader(true);
    authService
      .parentVerify({
        mobileNumber,
        otp: getOtp(),
        countryCode: '91',
        deviceIdentifier: getDeviceIdentifier(),
      })
      .then((responseData) => {
        if (responseData) {
          if (responseData.code === 200) {
            parentAxiosInstance.defaults.headers.common[
              'Authorization'
            ] = `${responseData.data?.accessToken}`;

            props.setProfile(responseData.data.profile);
            props.login(responseData.data.accessToken);
          }
        }
      })
      .catch((errorData) => {
        props.setLoader(false);
        showToast(errorData?.message);
      });
  };

  const enableResendTimer = () => {
    let timerObj = Object.assign({}, timerObject);

    timerTicker = setInterval(() => {
      timerObj.timerLimit--;
      if (timerObj.timerLimit === 0) {
        timerObj.isTimerEnabled = false;
        timerObj.isResendEnabled = true;

        clearInterval(timerTicker);
      }

      setTimerObject({ ...timerObj });
    }, 1000);
  };

  const resendOtp = (parentMobileNumber, cache) => {
    if (
      cache &&
      props.parentOtpTs &&
      moment().diff(moment(props.parentOtpTs), 'seconds') < 50
    ) {
      return
    }
    props.setLoader(true);
    authService
      .parentLoginRequest({
        countryCode: '91',
        mobileNumber: mobileNumber || parentMobileNumber,
      })
      .then((responseData) => {
        props.setLoader(false);
        props.setParentOtp();
      })
      .catch((errorData) => {
        props.setLoader(false);
      });
  };

  const handleResendRequest = () => {
    if (timerObject.resendCount <= 5) {
      resendOtp('', false);
      setTimerObject({
        timerLimit: 59,
        isTimerEnabled: true,
        isResendEnabled: false,
        resendCount: timerObject.resendCount + 1,
      });
    }
  };

  useEffect(() => {
    enableResendTimer();
  }, [timerObject.resendCount]); // eslint-disable-line

  return (
    <div className='parent-otp-container'>
      <div className='global-container onboarding-container otpentry-container'>
        <header className='global-header'>
          <div className='global-header-left'>
            <Link to='#' onClick={() => window.open(process.env.REACT_APP_STATIC_APP_URL)}>
              <img src={Logo} className='global-header-logo' alt='SKIPIN' />
            </Link>
          </div>

          <div className='global-header-right'></div>
        </header>

        <section className='onboarding-container-dynamic'>
          <div className='otpentry-container-content'>
            <h1 className='otpentry-container-title'>
              We have sent you a 4 digit OTP on
            </h1>
            <p className='otpentry-container-mobile'>+91-{mobileNumber}</p>

            <div className='otpentry-container-input'>
              <label className='otpentry-container-label'>
                Enter the OTP received
              </label>
              <div className='otpentry-container-inputwrapper flex-wrapper d-flex align-items-center justify-content-start'>
                <input
                  ref={ref1}
                  type='number'
                  value={otp[1]}
                  className='otpentry-container-field'
                  placeholder='X'
                  maxLength={1}
                  onChange={(event) =>
                    handleFieldUpdate('1', event.currentTarget.value)
                  }
                />
                <input
                  ref={ref2}
                  type='number'
                  value={otp[2]}
                  className='otpentry-container-field'
                  placeholder='X'
                  maxLength={1}
                  onChange={(event) =>
                    handleFieldUpdate('2', event.currentTarget.value)
                  }
                  onKeyDown={(event) => onKeyDown(event.keyCode, '2')}
                />
                <input
                  ref={ref3}
                  type='number'
                  value={otp[3]}
                  className='otpentry-container-field'
                  placeholder='X'
                  maxLength={1}
                  onChange={(event) =>
                    handleFieldUpdate('3', event.currentTarget.value)
                  }
                  onKeyDown={(event) => onKeyDown(event.keyCode, '3')}
                />
                <input
                  ref={ref4}
                  type='number'
                  value={otp[4]}
                  className='otpentry-container-field'
                  placeholder='X'
                  maxLength={1}
                  onChange={(event) =>
                    handleFieldUpdate('4', event.currentTarget.value)
                  }
                  onKeyDown={(event) => onKeyDown(event.keyCode, '4')}
                />
              </div>
            </div>

            {timerObject.resendCount <= 5 && (
              <div className='otpentry-container-fallback d-flex align-items-center justify-content-center'>
                <span className='fallback-text'>Didn't receive it?</span>
                {timerObject.isTimerEnabled ? (
                  <span className='fallback-timer'>{`00:${
                    timerObject.timerLimit > 9
                      ? timerObject.timerLimit
                      : `0${timerObject.timerLimit}`
                  }`}</span>
                ) : (
                  <span
                    className='fallback-resend'
                    onClick={handleResendRequest}
                  >
                    Resend
                  </span>
                )}
              </div>
            )}

            <PrimaryButton
              name='Verify & Proceed'
              onClick={submitHandler}
              disabled={getOtp().length !== 4}
            />
          </div>
        </section>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  site: state.site,
  profile: state.user.profile,
  auth: state.auth.userDetails,
  parentOtpTs: state.auth.parentOtpTs,
  parentAuth: state.auth.parentDetails,
});

const mapDispatchToProps = (dispatch) => ({
  login: (dataPayload) => parentLoginAction(dispatch, dataPayload),
  setLoader: (dataPayload) => setLoaderAction(dispatch, dataPayload),
  setProfile: (dataPayload) => setProfileAction(dispatch, dataPayload),
  setParentOtp: (dataPayload) => setParentOtpAction(dispatch, dataPayload),
});

export default connect(mapStateToProps, mapDispatchToProps)(ParentOTPEntry);
