import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { setLoaderAction } from '../../../actions/siteActions';
import { routeConstants } from '../../../common/routeConstants';
import { setActiveQuestionAction } from '../../../actions/resourceActions';

import Private from '../../../hocs/Private';
import Lightbox from '../../../components/Lightbox';
import HintIcon from '../../../assets/images/hint-icon.svg';
import NavigationStatic from '../../../components/NavigationStatic';
import BreadcrumbStatic from '../../../components/BreadcrumbStatic';
import ActiveQuestion from '../../../components/Questions/ActiveQuestion';

import './styles.scss';

const Questionnaire = (props) => {
  const [showHint, setShowHint] = useState(false);
  const [showLightbox, setShowLightbox] = useState(null);
  const history = useHistory();

  useEffect(() => {
    if (!props.activeQuestion) {
      history.push(routeConstants.DASHBOARD);
    }
  }, []); // eslint-disable-line

  const resourceCompleted = () => {
    if (props.activeQuestion && props.activeQuestion.lessonID) {
      history.replace(
        routeConstants.LESSONDETAILS.replace(
          ':lessonID',
          props.activeQuestion.lessonID
        )
      );
    }
  };

  return (
    <div className="global-container content-container questions-container">
      <NavigationStatic />

      <section className="content-container-dynamic">
        <BreadcrumbStatic crumbs={props.questionnaireBreadcrumbs} />

        <ActiveQuestion
          setLoader={props.setLoader}
          activeQuestion={props.activeQuestion}
          resourceCompleted={resourceCompleted}
          setActiveQuestion={props.setActiveQuestion}
        />

        <div className="content-options">
          <div className="hint d-flex align-items-center justify-content-center flex-column relative">
            <span className="hint-title d-block">Hint</span>

            <img
              src={HintIcon}
              className="questionhint-icon"
              alt="Hint"
              onClick={() => setShowHint(!showHint)}
            />

            {showHint && (
              <div className="questionhint-overlay">
                <h5 className="questionhint-title">Hint</h5>
                <p className="questionhint-content">
                  {props.activeQuestion?.questionHint &&
                  props.activeQuestion?.questionHint?.length !== 0 ? (
                    props.activeQuestion?.questionHint.map((hint, index) => {
                      return typeof hint === 'string' ? (
                        <p key={index} className="questionhint-content-item">
                          {hint}
                        </p>
                      ) : hint.type === 'text' ? (
                        <p key={index} className="questionhint-content-item">
                          {hint.value}
                        </p>
                      ) : (
                        <img
                          alt="Hint"
                          key={index}
                          src={hint.value}
                          className="questionhint-content-image"
                          onClick={() => setShowLightbox(hint.value)}
                        />
                      );
                    })
                  ) : (
                    <p className="questionhint-content-item">
                      Sorry! No hint available for this question.
                    </p>
                  )}
                </p>
                <p className="questionhint-info">
                  Q. Code: {props.activeQuestion?.code}
                </p>
                <p className="questionhint-info">
                  R. Code: {props.activeQuestion?.ownerID}
                </p>
              </div>
            )}
          </div>

          {props.activeQuestion && (
            <div className="progress-track">
              <span className="progress-track-title d-block">Attempted</span>

              <div className="progress-track-bar">
                <div
                  className="progress-track-filler"
                  style={{
                    height: `${
                      (1 -
                        props.activeQuestion.currentQuestion /
                          props.activeQuestion.totalQuestions) *
                      100
                    }%`,
                  }}
                />
              </div>

              <span className="progress-track-count d-block">
                <span className="progress-track-completed">{`${props.activeQuestion.currentQuestion}`}</span>
                /
                <span className="progress-track-remaining">{`${props.activeQuestion.totalQuestions}`}</span>
              </span>
            </div>
          )}
        </div>
      </section>

      {showLightbox && (
        <Lightbox image={showLightbox} setShowLightbox={setShowLightbox} />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  activeQuestion: state.resource.activeQuestion,
  questionnaireBreadcrumbs: state.resource.questionnaireBreadcrumbs,
});

const mapDispatchToProps = (dispatch) => ({
  setActiveQuestion: (dataPayload) =>
    setActiveQuestionAction(dispatch, dataPayload),
  setLoader: (dataPayload) => setLoaderAction(dispatch, dataPayload),
});

export default Private(
  connect(mapStateToProps, mapDispatchToProps)(Questionnaire)
);
