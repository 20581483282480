import React from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import './styles.scss';

const ProgressBar = (props) => {
	const getPercent = () => {
		let percentage = props.progress / props.total * 100;
		if (isNaN(percentage)) {
			return 0;
		} else {
			return percentage.toFixed(2);
		}
	};

	return (
		<div style={{ width: 110, height: 110, position: 'relative', marginTop: '-18px' }}>
			<div className='percent'>
				<div>
					<h3 className='num'>{props.progress}</h3>
					<h3 className='den'>{props.total}</h3>
					<p className='lessons-text'>Questions</p>
				</div>
			</div>
			{/* Work in Progress */}
			<CircularProgressbar
				value={getPercent()}
				strokeWidth={5}
				counterClockwise={true}
				text={''}
				styles={{
					path: {
						stroke: '#0593F2',
					},
					trail:{
						stroke: '#d8f0fc',
					},
				}}
			/>
		</div>
	);
};

export default ProgressBar;
