import { connect } from 'react-redux';
import { useEffect, useState, Fragment } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { routeConstants } from '../../common/routeConstants';

import Logo from '../../assets/images/dashboard-logo.svg';
import StarIcon from '../../assets/images/star-icon.svg';
import ProductIcon from '../../assets/images/product-icon.svg';
import ParentAvatar from '../../assets/images/parent-avatar.svg';
import DashboardIcon from '../../assets/images/dashboard-icon.svg';
import MembershipIcon from '../../assets/images/membership-icon.svg';
import RightBlueArrow from '../../assets/images/right-arrow-blue.svg';
import TransactionIcon from '../../assets/images/transaction-icon.svg';
import ProductIconActive from '../../assets/images/product-icon-active.svg';
import DashboardIconActive from '../../assets/images/dashboard-icon-active.svg';
import MembershipIconActive from '../../assets/images/membership-icon-active.svg';
import TransactionIconActive from '../../assets/images/transaction-icon-active.svg';

import './styles.scss';

const NavigationStatic = (props) => {
  const [isParent, setIsParent] = useState(false);

  useEffect(() => {
    if (window.location.pathname.includes('parent')) {
      setIsParent(true);
    } else {
      setIsParent(false);
    }
  }, [window.location.pathname]); // eslint-disable-line

  return (
    <aside className="navigation-static">
      <div className="navigation-static-brand">
        <img src={Logo} className="logo navigation-static-logo" alt="Skipin" onClick={() => window.open(process.env.REACT_APP_STATIC_APP_URL)} />
      </div>

      {!props.hidePrivateData && (
        <Fragment>
          <div className="navigation-static-profile">
            <h6 className="profile-name">{props.profile?.name}</h6>
            <span className="profile-class d-block">
              Class - {props.profile?.classID?.class}
            </span>
            <div className="profile-gamification d-flex align-items-center justify-content-center">
              <img
                src={StarIcon}
                className="profile-gamification-stars"
                alt="Star"
              />
              <span className="profile-gamification-value">
                {props.profile?.rewardStars || 0}
              </span>
            </div>
          </div>

          <nav className="navigation-static-links d-flex flex-column">
            <NavLink
              to={
                isParent
                  ? routeConstants.PARENT_DASHBOARD
                  : routeConstants.DASHBOARD
              }
              className="navigation-link"
              activeClassName={
                (window.location.pathname === routeConstants.DASHBOARD ||
                  window.location.pathname ===
                    routeConstants.PARENT_DASHBOARD) &&
                'navigation-link-active'
              }
            >
              {window.location.pathname === routeConstants.DASHBOARD ||
              window.location.pathname === routeConstants.PARENT_DASHBOARD ? (
                <img
                  src={DashboardIconActive}
                  className={'navigation-link-icon'}
                  alt="Dashboard"
                />
              ) : (
                <img
                  src={DashboardIcon}
                  className={'navigation-link-icon'}
                  alt="Dashboard"
                />
              )}
              Dashboard
            </NavLink>
            <NavLink
              className="navigation-link"
              to={
                isParent
                  ? routeConstants.PARENT_MEMBERSHIP
                  : routeConstants.MEMBERSHIP
              }
              activeClassName={
                (window.location.pathname === routeConstants.MEMBERSHIP ||
                  window.location.pathname ===
                    routeConstants.PARENT_MEMBERSHIP) &&
                'navigation-link-active'
              }
            >
              {window.location.pathname === routeConstants.MEMBERSHIP ||
              window.location.pathname === routeConstants.PARENT_MEMBERSHIP ? (
                <img
                  src={MembershipIconActive}
                  className={'navigation-link-icon'}
                  alt="Dashboard"
                />
              ) : (
                <img
                  src={MembershipIcon}
                  className={'navigation-link-icon'}
                  alt="Dashboard"
                />
              )}
              My Membership
            </NavLink>
            <NavLink
              className="navigation-link"
              to={
                isParent
                  ? routeConstants.PARENT_TRANSACTIONS
                  : routeConstants.TRANSACTIONS
              }
              activeClassName={
                (window.location.pathname === routeConstants.TRANSACTIONS ||
                  window.location.pathname ===
                    routeConstants.PARENT_TRANSACTIONS) &&
                'navigation-link-active'
              }
            >
              {window.location.pathname === routeConstants.TRANSACTIONS ||
              window.location.pathname ===
                routeConstants.PARENT_TRANSACTIONS ? (
                <img
                  src={TransactionIconActive}
                  className={'navigation-link-icon'}
                  alt="Dashboard"
                />
              ) : (
                <img
                  src={TransactionIcon}
                  className={'navigation-link-icon'}
                  alt="Dashboard"
                />
              )}
              My Transactions
            </NavLink>
            <NavLink
              className="navigation-link"
              to={
                isParent
                  ? routeConstants.PARENT_MYPRODUCTS
                  : routeConstants.MYPRODUCTS
              }
              activeClassName={
                (window.location.pathname === routeConstants.MYPRODUCTS ||
                  window.location.pathname ===
                    routeConstants.PARENT_MYPRODUCTS) &&
                'navigation-link-active'
              }
            >
              {window.location.pathname === routeConstants.MYPRODUCTS ||
              window.location.pathname === routeConstants.PARENT_MYPRODUCTS ? (
                <img
                  src={ProductIconActive}
                  className={'navigation-link-icon'}
                  alt="Dashboard"
                />
              ) : (
                <img
                  src={ProductIcon}
                  className={'navigation-link-icon'}
                  alt="Dashboard"
                />
              )}
              My Products
            </NavLink>
            {isParent && (
              <NavLink
                className="navigation-link"
                to={routeConstants.PARENT_MODELPROJECTS}
                activeClassName={
                  window.location.pathname ===
                    routeConstants.PARENT_MODELPROJECTS &&
                  'navigation-link-active'
                }
              >
                {window.location.pathname ===
                routeConstants.PARENT_MODELPROJECTS ? (
                  <img
                    src={ProductIconActive}
                    className={'navigation-link-icon'}
                    alt="Projects"
                  />
                ) : (
                  <img
                    src={ProductIcon}
                    className={'navigation-link-icon'}
                    alt="Projects"
                  />
                )}
                Model Projects
              </NavLink>
            )}
          </nav>
          <Link
            to={
              isParent
                ? routeConstants.DASHBOARD
                : routeConstants.PARENT_DASHBOARD
            }
            className="switch-user-container"
          >
            <div className="user-type-img">
              <img src={ParentAvatar} alt="Avatar" />
            </div>
            <div className="label">
              {isParent ? `STUDENT'S DASHBOARD` : `PARENT'S DASHBOARD`}
            </div>
            <div className="arrow">
              <img src={RightBlueArrow} alt="Arrow" />
            </div>
          </Link>
        </Fragment>
      )}
    </aside>
  );
};

const mapStateToProps = (state) => ({
  profile: state.user.profile,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(NavigationStatic);
