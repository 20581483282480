import { Modal } from 'react-bootstrap';

import PrimaryButton from '../../PrimaryButton';
import styles from './styles.scss';

const ResourceCompletedDialog = (props) => {
  return (
    <div style={styles}>
      <Modal
        centered
        size="md"
        show={true}
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Body>
          <h4>Congratulations</h4>
          <h5 className='complete-subtitle'>You have successfully completed this resource.</h5>
        </Modal.Body>
        <Modal.Footer>
          <PrimaryButton onClick={props.onClick} name="Continue"  className="primary-button resource-complete-btn" />
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ResourceCompletedDialog;
