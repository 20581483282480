import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { showToast } from '../../../common/helpers';
import { AvailableSubjects } from '../../../common/constants';
import { setLoaderAction } from '../../../actions/siteActions';
import { routeConstants } from '../../../common/routeConstants';
import { choosePackagesAction } from '../../../actions/orderActions';

import Private from '../../../hocs/Private';
import Header from '../../../components/Header';
import packageService from '../../../services/packageService';
import PrimaryButton from '../../../components/PrimaryButton';
import MathsSubject from '../../../assets/images/maths-subject.svg';
import EnglishSubject from '../../../assets/images/english-subject.svg';
import ScienceSubject from '../../../assets/images/science-subject.svg';

import './styles.scss';

const SubjectChoice = (props) => {
  const history = useHistory();
  const [discount, setDiscount] = useState(0);
  const [packages, setPackages] = useState([]);
  const [showPackages, setShowPackages] = useState(false);
  const [selectedPackages, setSelectedPackages] = useState([]);
  const [hideIsAllPackage, setHideIsAllPackage] = useState(false);
  const [selectedPackageDetails, setSelectedPackageDetails] = useState({
    prices: {
      INR: 0,
      USD: 0,
    },
    title: '',
    permittedResources: 0,
  });

  const handleSubjectSelection = (fieldValue) => {
    let alreadySelected = false;

    selectedPackages.forEach((currentElement, currentIndex) => {
      if (fieldValue === currentElement) {
        alreadySelected = true;

        return alreadySelected;
      }
    });

    const updatedSelectedClasses = Array.from(selectedPackages);

    if (!alreadySelected) {
      updatedSelectedClasses.push(fieldValue);
      setSelectedPackages(updatedSelectedClasses);
    } else {
      setSelectedPackages(
        updatedSelectedClasses.filter(
          (currentItem) => fieldValue !== currentItem
        )
      );
    }
  };

  useEffect(() => {
    if (selectedPackages.length) {
      let selectedPackageDetailsCopy = Object.assign(
        {},
        selectedPackageDetails
      );

      selectedPackageDetailsCopy.title = '';
      selectedPackageDetailsCopy.prices.INR = 0;
      selectedPackageDetailsCopy.prices.USD = 0;
      selectedPackageDetailsCopy.permittedResources = 0;

      for (let sp of selectedPackages) {
        let details = packages.find((item) => item._id === sp);
        selectedPackageDetailsCopy.prices.INR += parseInt(details.prices.INR);
        selectedPackageDetailsCopy.prices.USD += parseInt(details.prices.USD);
        selectedPackageDetailsCopy.permittedResources += parseInt(
          details.permittedResources
        );
        selectedPackageDetailsCopy.title += selectedPackageDetailsCopy.title
          ? ` + ${details.title}`
          : details.title;
      }

      setSelectedPackageDetails(selectedPackageDetailsCopy);
    }
  }, [selectedPackages]); // eslint-disable-line

  useEffect(() => {
    getPackages();
    props.choosePackages({ packages: [], packagesIds: [], packageDiscount: 0 });
  }, []); // eslint-disable-line

  const getPackages = () => {
    props.setLoader(true);
    packageService
      .getPackages()
      .then((responseData) => {
        props.setLoader(false);
        if (responseData) {
          if (responseData.code === 200) {
            setPackages(responseData.data.packages);
            setDiscount(responseData.data.discount);
            if (responseData.data.length) {
              for (let packageItem of responseData.data) {
                if (packageItem.disabled) {
                  setHideIsAllPackage(true);
                  break;
                }
              }
            }
          }
        }
      })
      .catch((errorData) => {
        props.setLoader(false);
        showToast(errorData?.message);
      });
  };

  const getRenderingIcon = (subject) => {
    switch (subject) {
      case AvailableSubjects.Maths:
        return MathsSubject;

      case AvailableSubjects.Science:
        return ScienceSubject;

      case AvailableSubjects.English:
        return EnglishSubject;

      default:
        break;
    }
  };

  const choosePackageHandler = (isAll) => {
    let packageArr = [],
      packagesIds = [];
    if (isAll) {
      let packageDetails = packages.find((item) => item.isAll === true);
      packageArr.push(packageDetails);
      packagesIds.push(packageDetails._id);
    } else {
      packageArr = packages.filter((item) =>
        selectedPackages.includes(item._id)
      );
      packagesIds = selectedPackages;
    }

    if (packageArr.length) {
      props.choosePackages({
        packagesIds,
        packages: packageArr,
        packageDiscount: discount,
      });
      history.push(routeConstants.PACKAGESCHECKOUT);
    }
  };

  return (
    // <div className="global-container onboarding-container subjectchoice-container">
    <div className="onboarding-container subjectchoice-container">
      <Header />

      <section className="onboarding-container-dynamic">
        <div className="decoration-layer">
          <div className="decoration-layer-one"></div>
          <div className="decoration-layer-two"></div>
          <div className="decoration-layer-three"></div>
          <div className="decoration-layer-four"></div>
          <div className="decoration-layer-five"></div>
          <div className="decoration-layer-six"></div>
        </div>

        <div className="subjectchoice-container-content">
          <div className="subjectchoice-container-wrapper">
            <h1 className="subjectchoice-container-title">
              You’re almost done! Choose the subjects that you wish to study.
            </h1>
            <div className="subjectchoice-container-selections">
              <div className="subjects-wrapper d-flex align-item-center justify-content-center">
                {packages &&
                  packages.length !== 0 &&
                  packages.map((packageItem, index) => {
                    if (!packageItem.isAll) {
                      return (
                        <label
                          key={index}
                          className={`${
                            selectedPackages.includes(packageItem._id) === true
                              ? `subjects-label subjects-label--selected${packageItem.title?.toLowerCase()}`
                              : 'subjects-label'
                          }${
                            packageItem.disabled
                              ? ' subjects-label--disabled'
                              : ''
                          }`}
                          htmlFor={packageItem._id}
                        >
                          <img
                            src={getRenderingIcon(packageItem.title)}
                            alt={packageItem.title}
                            className="subjects-icon"
                          />
                          {packageItem.title}
                          <input
                            type="checkbox"
                            id={packageItem._id}
                            name="classRadio"
                            value={packageItem._id}
                            className="subjects-field"
                            onChange={(event) =>
                              handleSubjectSelection(event.target.value)
                            }
                            disabled={packageItem.disabled}
                          />
                        </label>
                      );
                    } else return <React.Fragment key={index} />;
                  })}
              </div>
            </div>
            <div className="subjectchoice-container-subtitle">
              <p className="subjects-subtitle">
                {selectedPackages.length
                  ? ` You have chosen ${selectedPackages.length}/${
                      packages.length - 1
                    } subjects`
                  : 'Choose 1 or more subjects'}
              </p>
            </div>
            {!showPackages && (
              <PrimaryButton
                name="Continue"
                disabled={!selectedPackages.length}
                onClick={() => setShowPackages(true)}
              />
            )}
          </div>

          {showPackages && (
            <div className="subjectchoice-container-packages">
              {((selectedPackages && selectedPackages.length !== 0) ||
                !hideIsAllPackage) && (
                <div className="row">
                  <div className="col-12">
                    <h4 className="package-title">
                      Here are the packages for you!
                    </h4>
                  </div>
                </div>
              )}
              <div className="row package-container">
                {selectedPackages &&
                  selectedPackageDetails.title &&
                  selectedPackages.length !== 0 &&
                  selectedPackages.length !== packages.length - 1 && (
                    <div
                      className={
                        hideIsAllPackage ? 'col-12' : 'col-12 col-sm-6'
                      }
                    >
                      <h4 className="package-title">Your Choice</h4>

                      <div className="package-wrapper">
                        <div className="circle circle-lg"></div>
                        <div className="circle circle-md"></div>
                        <div className="circle circle-sm"></div>
                        <div className="circle circle-xs"></div>
                        <div className="package-wrapper-subjects">
                          <div className="trapezoid">
                            <h5 className="subject-label">
                              {selectedPackageDetails.title}
                            </h5>
                          </div>
                        </div>
                        <div className="package-wrapper-price">
                          <h5>
                            <span className="currency">₹</span>
                            {selectedPackageDetails.prices.INR} /{' '}
                            <span className="currency">$</span>
                            {selectedPackageDetails.prices.USD}
                          </h5>
                        </div>
                        <div className="package-wrapper-tax">
                          <h5>
                            (₹{selectedPackageDetails.prices.INR * 1.18}{' '}
                            including GST)
                          </h5>
                        </div>
                        <div className="package-wrapper-description">
                          <p>LMS + App</p>
                          <p>Parent’s Portal</p>
                          <p>
                            {selectedPackageDetails.permittedResources}+
                            Resources
                          </p>
                          <p>Progress Track + Analytics</p>
                        </div>
                        <PrimaryButton
                          name="Choose"
                          className="package-wrapper-cta"
                          onClick={() => choosePackageHandler(false)}
                        />
                      </div>
                    </div>
                  )}

                {!hideIsAllPackage && (
                  <div
                    className={
                      !selectedPackages ||
                      selectedPackages.length === 0 ||
                      selectedPackages.length === packages.length - 1
                        ? 'col-12'
                        : 'col-12 col-sm-6'
                    }
                  >
                    <h4 className="package-title">Best Choice</h4>

                    {packages &&
                      packages.length !== 0 &&
                      packages.map((allPackage, index) => {
                        if (allPackage.isAll) {
                          return (
                            <div key={index} className="package-wrapper">
                              <div className="circle circle-lg"></div>
                              <div className="circle circle-md"></div>
                              <div className="circle circle-sm"></div>
                              <div className="circle circle-xs"></div>
                              <div className="package-wrapper-subjects">
                                <div className="trapezoid">
                                  <h5>All Subjects</h5>
                                </div>
                              </div>
                              <div className="package-wrapper-price">
                                <h5>
                                  <span className="currency">₹</span>
                                  {allPackage.prices.INR} /{' '}
                                  <span className="currency">$</span>
                                  {allPackage.prices.USD}
                                </h5>
                              </div>
                              <div className="package-wrapper-tax">
                                <h5>
                                  (₹{allPackage.prices.INR * 1.18} including
                                  GST)
                                </h5>
                              </div>
                              <div className="package-wrapper-description">
                                <p>LMS + App</p>
                                <p>Parent’s Portal</p>
                                <p>{allPackage.permittedResourcesText}</p>
                                <p>Progress Track + Analytics</p>
                              </div>
                              <PrimaryButton
                                name="Choose"
                                className="package-wrapper-cta"
                                onClick={() => choosePackageHandler(true)}
                              />
                            </div>
                          );
                        } else return <React.Fragment key={index} />;
                      })}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  setLoader: (dataPayload) => setLoaderAction(dispatch, dataPayload),
  choosePackages: (dataPayload) => choosePackagesAction(dispatch, dataPayload),
});

export default Private(
  connect(mapStateToProps, mapDispatchToProps)(SubjectChoice)
);
