import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { routeConstants } from '../../../common/routeConstants';

import Private from '../../../hocs/Private';
import Logo from '../../../assets/images/logo.svg';
import PrimaryButton from '../../../components/PrimaryButton';
import TransactionFailedIcon from '../../../assets/images/transaction-failed.svg';

import './styles.scss';
import Header from '../../../components/Header';

const PaymentFailure = (props) => {
  const history = useHistory();

  useEffect(() => {
    if (!props.currentOrder || !props.currentOrder?.order?.orderID) {
      history.push(routeConstants.DASHBOARD);
    }
  }, []); // eslint-disable-line

  const tryAgainHandler = () => {
    if (window.location.pathname === routeConstants.PACKAGESCHECKOUTFAILURE) {
      history.push(routeConstants.PACKAGESCHECKOUT);
    } else if (
      window.location.pathname === routeConstants.PRODUCTCHECKOUTFAILURE
    ) {
      history.push(routeConstants.CART);
    }
  };

  return (
    <div className="global-container onboarding-container paymentfailure-container">
     <Header />

      <section className="onboarding-container-dynamic">
        <div className="decoration-layer">
          <div className="decoration-layer-one"></div>
          <div className="decoration-layer-two"></div>
          <div className="decoration-layer-three"></div>
          <div className="decoration-layer-four"></div>
          <div className="decoration-layer-five"></div>
          <div className="decoration-layer-six"></div>
        </div>

        <div className="paymentfailure-container-content">
          <div className="paymentfailure-container-wrapper">
            <h1 className="paymentfailure-container-title">Transaction Failed</h1>
            <div className="paymentfailure-container-selections">
              <div className="paymentfailure-wrapper d-flex align-item-center justify-content-center">
                <img
                  className="paymentfailure-wrapper-icon"
                  src={TransactionFailedIcon}
                />
                <h5 className="paymentfailure-wrapper-title">
                  Order ID: {props.currentOrder?.order?.orderID}
                </h5>
                <h5 className="paymentfailure-wrapper-subtitle">
                  We were unable to process your payment. You can always try
                  again!
                </h5>
              </div>
              <PrimaryButton name="Try Again" onClick={tryAgainHandler} />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentOrder: state.order.currentOrder,
});

const mapDispatchToProps = (dispatch) => ({});

export default Private(
  connect(mapStateToProps, mapDispatchToProps)(PaymentFailure)
);
