import { useState, useEffect } from 'react';

import get from 'lodash/get';
import set from 'lodash/set';
import isEqual from 'lodash/isEqual';
import QuestionHeader from '../QuestionHeader';
import PrimaryButton from '../../PrimaryButton';
import WrongAnswerToast from '../../WrongAnswerToast';
import QuestionSuggestions from '../QuestionSuggestions';

import styles from './styles.scss';

const TBLTQuestion = (props) => {
  const [answerData, setAnswerData] = useState([]);
  const [correctAnswer, setCorrectAnswer] = useState([]);
  const [answerResponse, setAnswerResponse] = useState(null);

  const setDefaultCorrectAnswer = () => {
    let correctAnswerCopy = [];
    const answer = get(props.activeQuestion, 'answer');

    if (answer && answer.length) {
      for (let rowIndex = 0; rowIndex < answer.length; rowIndex++) {
        for (let colIndex = 0; colIndex < answer[rowIndex].length; colIndex++) {
          let item = answer[rowIndex][colIndex];
          if (item.type === 'title') {
            set(correctAnswerCopy, `[${rowIndex}][${colIndex}]`, item);
          } else if (item.type === 'ques') {
            set(correctAnswerCopy, `[${rowIndex}][${colIndex}]`, item);
          } else if (item.type === 'ans') {
            set(correctAnswerCopy, `[${rowIndex}][${colIndex}]`, {
              ...item,
              text: '',
            });
          }
        }
      }

      setCorrectAnswer(correctAnswerCopy);
    }
  };

  const handleAnswerUpdate = (fieldName, fieldValue) => {
    let correctAnswerCopy = Object.assign([], correctAnswer);
    set(correctAnswerCopy, fieldName, fieldValue);
    setCorrectAnswer(correctAnswerCopy);
  };

  const getAnswerData = () => {
    let answerDataCopy = Object.assign([], answerData);
    const answer = get(props.activeQuestion, 'answer', []);

    if (answer && answer.length) {
      for (let rowIndex = 0; rowIndex < answer.length; rowIndex++) {
        for (let colIndex = 0; colIndex < answer[rowIndex].length; colIndex++) {
          let item = answer[rowIndex][colIndex];

          if (item.type === 'title') {
            set(
              answerDataCopy,
              `[${rowIndex}][${colIndex}]`,
              <div className="tblt-item-answer">
                <b>{item.text}</b>
              </div>
            );
          } else if (item.type === 'ques') {
            set(
              answerDataCopy,
              `[${rowIndex}][${colIndex}]`,
              <div className="tblt-item-answer">{item.text}</div>
            );
          } else if (item.type === 'ans') {
            set(
              answerDataCopy,
              `[${rowIndex}][${colIndex}]`,
              <input
                type="text"
                placeholder="Type answer"
                className={`tblt-item-answer`}
                value={
                  (correctAnswer &&
                  correctAnswer[rowIndex] &&
                  correctAnswer[rowIndex][colIndex] &&
                  correctAnswer[rowIndex][colIndex].text) || ''
                }
                onChange={(event) =>
                  handleAnswerUpdate(
                    `[${rowIndex}][${colIndex}].text`,
                    event.currentTarget.value
                  )
                }
              />
            );
          }
        }
      }

      setAnswerData(answerDataCopy);
    }
  };

  useEffect(() => {
    getAnswerData();
  }, [correctAnswer]); // eslint-disable-line

  useEffect(() => {
    if (!isEqual(answerResponse, props.answerResponse)) {
      setAnswerResponse(props.answerResponse);
    }
  }, [props.answerResponse]); // eslint-disable-line

  useEffect(() => {
    setDefaultCorrectAnswer();
  }, [props.activeQuestion]); // eslint-disable-line

  const handleAnswerSubmit = () => {
    props.answerSubmitHandler(correctAnswer);
  };

  const checkSubmitEnabled = () => {
    let isSubmitDisabled = true;
    let isValid = true;
    if (correctAnswer.length) {
      for (let row of correctAnswer) {
        for (let col of row) {
          if ((!col || !col.text || col.text === '') && col.type === 'ans') {
            isValid = false;
            isSubmitDisabled = true;
            break;
          }

          if (isValid) {
            isSubmitDisabled = false;
          }
        }

        if (isSubmitDisabled) {
          break;
        }
      }
    }

    return isSubmitDisabled;
  };

  return (
    <div style={styles} className="tblt-container">
      <div className="content-scroller">
        {props.activeQuestion?.question?.length !== 0 && (
          <div className="tblt-container-content">
            <QuestionHeader question={props.activeQuestion.question} />

            <div className="tblt-container-item">
              {answerData &&
                answerData.length !== 0 &&
                answerData.map((tableRow, rowIndex) => {
                  return (
                    <div key={rowIndex} className="row">
                      {tableRow &&
                        tableRow.length !== 0 &&
                        tableRow.map((tableCol, colIndex) => {
                          if (rowIndex === 0) {
                            return (
                              <div
                                key={colIndex}
                                className="col tblt-item-col"
                                style={{
                                  borderTopLeftRadius: '10px',
                                  borderTopRightRadius: '10px',
                                }}
                              >
                                {tableCol}
                              </div>
                            );
                          } else if (rowIndex === answerData.length - 1) {
                            return (
                              <div
                                key={colIndex}
                                className="col tblt-item-col"
                                style={{
                                  borderBottomLeftRadius: '10px',
                                  borderBottomRightRadius: '10px',
                                }}
                              >
                                {tableCol}
                              </div>
                            );
                          } else
                            return (
                              <div key={colIndex} className="col tblt-item-col">
                                {tableCol}
                              </div>
                            );
                        })}
                    </div>
                  );
                })}
            </div>

            {props.activeQuestion?.questionSuggestions?.length !== 0 && (
              <QuestionSuggestions activeQuestion={props.activeQuestion} />
            )}
          </div>
        )}
      </div>

      <div className="tblt-container-footer">
        {answerResponse && !answerResponse.correct && <WrongAnswerToast />}

        <PrimaryButton
          name="Submit"
          showIcon={true}
          onClick={handleAnswerSubmit}
          disabled={checkSubmitEnabled()}
        />
      </div>
    </div>
  );
};

export default TBLTQuestion;
