import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { showToast } from '../../common/helpers';
import { setLoaderAction } from '../../actions/siteActions';
import { routeConstants } from '../../common/routeConstants';

import moment from 'moment';
import Private from '../../hocs/Private';
import packageService from '../../services/packageService';
import NavigationStatic from '../../components/NavigationStatic';
import BreadcrumbStatic from '../../components/BreadcrumbStatic';
import MathsSubject from '../../assets/images/maths-subject.svg';
import EnglishSubject from '../../assets/images/english-subject.svg';
import ScienceSubject from '../../assets/images/science-subject.svg';
import PrimaryButton from '../../components/PrimaryButton';

import './styles.scss';

const Membership = (props) => {
  const history = useHistory();
  const [membership, setMembership] = useState([]);
  const [advertisment, setAdvertisment] = useState(null);
  const [showPackagesCta, setShowPackagesCta] = useState(false);

  useEffect(() => {
    getMembership();
  }, []); // eslint-disable-line

  const getMembership = () => {
    props.setLoader(true);
    packageService
      .getMembership()
      .then((responseData) => {
        if (responseData) {
          props.setLoader(false);
          if (responseData.code === 200 && responseData.data) {
            setMembership(responseData.data.membership);
            setAdvertisment(responseData.data.advertisment);
            // setShowPackagesCta(responseData.data.showPackagesCta);
          }
        }
      })
      .catch((errorData) => {
        props.setLoader(false);
        showToast(errorData?.message);
      });
  };

  return (
    <div className="global-container content-container">
      <NavigationStatic />

      <section className="content-container-dynamic">
        <BreadcrumbStatic
          crumbs={[
            {
              name: 'Dashboard',
              route: window.location.pathname.includes('parent')
                ? routeConstants.PARENT_DASHBOARD
                : routeConstants.DASHBOARD,
            },
            {
              name: 'My Membership',
              route: routeConstants.MEMBERSHIP,
            },
          ]}
        />

        <div className="membership">
          <div className="membership-header">
            <h5 className="membership-header-title">My Membership</h5>
          </div>

          <div className="membership-content">
            {membership &&
              membership.length !== 0 &&
              membership.map((plan, index) => {
                return (
                  <div key={index} className="membership-content-item">
                    <div className="package-details">
                      <h5 className="package-details-title">Your Plan</h5>
                      <div className="package-details-subtitles">
                        <p>LMS + App</p>
                        <p>{plan.resources}+ Resources</p>
                      </div>
                      <div className="package-details-subtitles">
                        <p>Parent’s Portal</p>
                        <p>Progress Track + Analytics</p>
                      </div>
                      <h5 className="package-details-package">
                        Package:{' '}
                        {`${plan.packageCount} ${
                          plan.packageCount > 1 ? 'Subjects' : 'Subject'
                        }`}{' '}
                        <span>(₹{plan.amount}/-)</span>
                      </h5>
                      <h5 className="package-details-date">
                        Purchased On:{' '}
                        {moment(plan.createdAt).format('Do MMMM, YYYY')}
                      </h5>
                      <h5 className="package-details-date">
                        Expiring On:{' '}
                        {moment(plan.expiry).format('Do MMMM, YYYY')}
                      </h5>
                    </div>
                    <div className="package-icons">
                      {plan.isScience && (
                        <>
                          <div className="package-icons-item">
                            <img src={ScienceSubject} alt="Science" />
                            <h5>Science</h5>
                          </div>
                          {(plan.isEnglish || plan.isMaths) && (
                            <h5 className="package-icons-separator">+</h5>
                          )}
                        </>
                      )}
                      {plan.isMaths && (
                        <>
                          <div className="package-icons-item">
                            <img src={MathsSubject} alt="Maths" />
                            <h5>Maths</h5>
                          </div>
                          {plan.isEnglish && (
                            <h5 className="package-icons-separator">+</h5>
                          )}
                        </>
                      )}
                      {plan.isEnglish && (
                        <div className="package-icons-item">
                          <img src={EnglishSubject} alt="English" />
                          <h5>English</h5>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
          </div>

          {showPackagesCta && (
            <>
              <div className="membership-footer">
                <h5 className="membership-footer-title">
                  Experience all 3 subjects on <span>Skipin</span>
                </h5>
                <PrimaryButton
                  name="See all packages"
                  onClick={() => {
                    history.push(routeConstants.PACKAGES);
                  }}
                />
              </div>
            </>
          )}

          {advertisment && (
            <div className="membership-banner">
              <a href={advertisment.actionURL} target="_blank" rel="noreferrer">
                <img
                  alt="Ad"
                  src={advertisment.imageURL}
                  className="membership-banner-image"
                />
              </a>
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  setLoader: (dataPayload) => setLoaderAction(dispatch, dataPayload),
});

export default Private(
  connect(mapStateToProps, mapDispatchToProps)(Membership)
);
