import './styles.scss';

const AdvertismentCard = (props) => {
  return (
    <a
      target="_blank"
	  rel="noreferrer"
	  href={props.actionURL}
      className="advert-item"
      style={{
        background: `url(${props.imageURL}) no-repeat center/cover`,
      }}
    >
      <p className="label">{props.title}</p>
    </a>
  );
};

export default AdvertismentCard;
