import get from 'lodash/get';

import { useEffect, useState } from 'react';
import { showToast } from '../../../common/helpers';
import { QuestionSubTypes } from '../../../common/constants';
import { DialogTextsPositive, DialogTextsNegetive } from './dataManager';

import LQQuestion from '../LQQuestion';
import DSCQuestion from '../DSCQuestion';
import SAQQuestion from '../SAQQuestion';
import MCQQuestion from '../MCQQuestion';
import LAQQuestion from '../LAQQuestion';
import MTFQuestion from '../MTFQuestion';
import RQTQuestion from '../RQTQuestion';
import MCQMQuestion from '../MCQMQuestion';
import LAQEQuestion from '../LAQEQuestion';
import TBLSQuestion from '../TBLSQuestion';
import TBLTQuestion from '../TBLTQuestion';
import LAQWLQuestion from '../LAQWLQuestion';
import FITBSQuestion from '../FITBSQuestion';
import FITBTQuestion from '../FITBTQuestion';
import resourceService from '../../../services/resourceService';
import CorrectAnswerDialog from '../AnswerDialogs/CorrectAnswerDialog';
import IncorrectAnswerDialog from '../AnswerDialogs/IncorrectAnswerDialog';
import ResourceCompletedDialog from '../AnswerDialogs/ResourceCompletedDialog';

import './styles.scss';

const FreebieActiveQuestion = (props) => {
  const [answerResponse, setAnswerResponse] = useState(null);
  const [correctAnswerModalData, setCorrectAnswerModalData] = useState(null);
  const [incorrectAnswerModalData, setIncorrectAnswerModalData] = useState(
    null
  );
  const [showCorrectAnswerDialog, setShowCorrectAnswerDialog] = useState(false);
  const [showIncorrectAnswerDialog, setShowIncorrectAnswerDialog] = useState(
    false
  );
  const [
    showResourceCompletionDialog,
    setShowResourceCompletionDialog,
  ] = useState(false);

  useEffect(() => {
    if (answerResponse) {
      if (answerResponse?.correct) {
        if (props.activeQuestion.questionSubType === QuestionSubTypes.DSC) {
          getNextQuestion();
        } else {
          toggleCorrectAnswerDialog();
        }
      } else if (answerResponse.isCompleted || answerResponse.next) {
        toggleIncorrectAnswerDialog();
      }
    }
  }, [answerResponse]);

  const completeResourceHandler = (updateParent = false) => {
    setAnswerResponse(null);
    setShowCorrectAnswerDialog(false);
    setShowIncorrectAnswerDialog(false);

    if (updateParent) {
      setShowResourceCompletionDialog(false);
      props.resourceCompleted();
    }
  };

  const getNextQuestion = () => {
    const next = get(answerResponse, 'next', null);
    const isCompleted = get(answerResponse, 'isCompleted', false);
    if (!next) {
      if (isCompleted) {
        setShowResourceCompletionDialog(true);
        completeResourceHandler(false);
      } else {
        window.location.reload();
      }
    } else {
      nextQuestionHandler(next);
    }
  };

  const nextQuestionHandler = (next) => {
    let nextQuestion = next;
    if (!nextQuestion) {
      window.location.reload();
    } else {
      props.setLoader(true);
      resourceService
        .getFreebieWorksheetQuestion({ code: nextQuestion })
        .then((responseData) => {
          props.setLoader(false);
          if (responseData && responseData.code === 200) {
            completeResourceHandler(false);

            if (
              get(responseData, 'data.questionSubType') ===
              QuestionSubTypes.EMPTY
            ) {
              nextQuestion = get(responseData, 'data.next');
              return handleAnswerSubmit('EMPTY');
            }

            props.setActiveQuestion(responseData.data);
          }
        })
        .catch((errorData) => {
          props.setLoader(false);
          showToast(errorData?.message);
        });
    }
  };

  const handleAnswerSubmit = (correctAnswer) => {
    setAnswerResponse(null);
    if (!props.activeQuestion || !correctAnswer) {
      return;
    }

    props.setLoader(true);
    resourceService
      .answerFreebieWorksheetQuestion({ code: props.activeQuestion.code, correctAnswer, sessionID: props.sessionID })
      .then((responseData) => {
        props.setLoader(false);
        if (responseData) {
          if (responseData.code === 200 && responseData.data) {
            setAnswerResponse(responseData.data);
          }
        }
      })
      .catch((errorData) => {
        props.setLoader(false);
        showToast(errorData?.message);
      });
  };

  const getRenderingQuestion = () => {
    switch (props.activeQuestion.questionSubType) {
      case QuestionSubTypes.DSC:
        return (
          <DSCQuestion
            answerResponse={answerResponse}
            activeQuestion={props.activeQuestion}
            answerSubmitHandler={handleAnswerSubmit}
          />
        );

      case QuestionSubTypes.SAQ:
        return (
          <SAQQuestion
            answerResponse={answerResponse}
            activeQuestion={props.activeQuestion}
            answerSubmitHandler={handleAnswerSubmit}
          />
        );

      case QuestionSubTypes.MCQ:
        return (
          <MCQQuestion
            answerResponse={answerResponse}
            activeQuestion={props.activeQuestion}
            answerSubmitHandler={handleAnswerSubmit}
          />
        );

      case QuestionSubTypes.MCQM:
        return (
          <MCQMQuestion
            answerResponse={answerResponse}
            activeQuestion={props.activeQuestion}
            answerSubmitHandler={handleAnswerSubmit}
          />
        );

      case QuestionSubTypes.LQ:
        return (
          <LQQuestion
            answerResponse={answerResponse}
            activeQuestion={props.activeQuestion}
            answerSubmitHandler={handleAnswerSubmit}
          />
        );

      case QuestionSubTypes.MTF:
        return (
          <MTFQuestion
            answerResponse={answerResponse}
            activeQuestion={props.activeQuestion}
            answerSubmitHandler={handleAnswerSubmit}
          />
        );

      case QuestionSubTypes.FITBT:
        return (
          <FITBTQuestion
            answerResponse={answerResponse}
            activeQuestion={props.activeQuestion}
            answerSubmitHandler={handleAnswerSubmit}
          />
        );

      case QuestionSubTypes.FITBS:
        return (
          <FITBSQuestion
            answerResponse={answerResponse}
            activeQuestion={props.activeQuestion}
            answerSubmitHandler={handleAnswerSubmit}
          />
        );

      case QuestionSubTypes.TBLT:
        return (
          <TBLTQuestion
            answerResponse={answerResponse}
            activeQuestion={props.activeQuestion}
            answerSubmitHandler={handleAnswerSubmit}
          />
        );

      case QuestionSubTypes.TBLS:
        return (
          <TBLSQuestion
            answerResponse={answerResponse}
            activeQuestion={props.activeQuestion}
            answerSubmitHandler={handleAnswerSubmit}
          />
        );

      case QuestionSubTypes.LAQ:
      case QuestionSubTypes.LAQAI:
        return (
          <LAQQuestion
            answerResponse={answerResponse}
            activeQuestion={props.activeQuestion}
            answerSubmitHandler={handleAnswerSubmit}
          />
        );

      case QuestionSubTypes.LAQE:
        return (
          <LAQEQuestion
            answerResponse={answerResponse}
            activeQuestion={props.activeQuestion}
            answerSubmitHandler={handleAnswerSubmit}
          />
        );

      case QuestionSubTypes.LAQWL:
        return (
          <LAQWLQuestion
            answerResponse={answerResponse}
            activeQuestion={props.activeQuestion}
            answerSubmitHandler={handleAnswerSubmit}
          />
        );

      case QuestionSubTypes.RQT:
        return (
          <RQTQuestion
            answerResponse={answerResponse}
            activeQuestion={props.activeQuestion}
            answerSubmitHandler={handleAnswerSubmit}
          />
        );

      default:
        break;
    }
  };

  const randomizePositiveTexts = () => {
    const maxPossibleIndice = Math.floor(DialogTextsPositive.length);
    const minPossibleIndice = 0;
    const randomIndice = Math.floor(
      Math.random() * (maxPossibleIndice - minPossibleIndice) +
        minPossibleIndice
    );

    return DialogTextsPositive[randomIndice];
  };

  const toggleCorrectAnswerDialog = () => {
    const randomText = randomizePositiveTexts();

    setCorrectAnswerModalData(randomText);
    setShowCorrectAnswerDialog(!showCorrectAnswerDialog);
  };

  const randomizeNegetiveTexts = () => {
    const maxPossibleIndice = Math.floor(DialogTextsNegetive.length);
    const minPossibleIndice = 0;
    const randomIndice = Math.floor(
      Math.random() * (maxPossibleIndice - minPossibleIndice) +
        minPossibleIndice
    );

    return DialogTextsNegetive[randomIndice];
  };

  const toggleIncorrectAnswerDialog = () => {
    const randomText = randomizeNegetiveTexts();

    setIncorrectAnswerModalData(randomText);
    setShowIncorrectAnswerDialog(!showIncorrectAnswerDialog);
  };

  return (
    <div className="question-container">
      {props.activeQuestion && <>{getRenderingQuestion()}</>}

      {showCorrectAnswerDialog && (
        <CorrectAnswerDialog
          onClick={getNextQuestion}
          answerResponse={answerResponse}
          modalData={correctAnswerModalData}
          activeQuestion={props.activeQuestion}
        />
      )}

      {showIncorrectAnswerDialog && (
        <IncorrectAnswerDialog
          onClick={getNextQuestion}
          answerResponse={answerResponse}
          modalData={incorrectAnswerModalData}
          activeQuestion={props.activeQuestion}
        />
      )}

      {showResourceCompletionDialog && (
        <ResourceCompletedDialog
          onClick={() => completeResourceHandler(true)}
        />
      )}
    </div>
  );
};

export default FreebieActiveQuestion;
