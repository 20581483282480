import { parse } from 'query-string';
import { connect } from 'react-redux';
import { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { showToast } from '../../../common/helpers';
import { AvailableSubjects } from '../../../common/constants';
import { routeConstants } from '../../../common/routeConstants';
import { setLoaderAction } from '../../../actions/siteActions';

import InputText from '../../../components/InputText';
import ParentPrivate from '../../../hocs/ParentPrivate';
import EmptyState from '../../../components/EmptyState';
import classService from '../../../services/classService';
import SearchIcon from '../../../assets/images/search-icon.svg';
import NavigationStatic from '../../../components/NavigationStatic';
import BreadcrumbStatic from '../../../components/BreadcrumbStatic';
import MathsSubject from '../../../assets/images/maths-subject.svg';
import EmptyLessonIcon from '../../../assets/images/empty-lesson.svg';
import LessonProgressCard from '../../../components/LessonProgressCard';
import EnglishSubject from '../../../assets/images/english-subject.svg';
import ScienceSubject from '../../../assets/images/science-subject.svg';

import './styles.scss';

const LessonProgress = (props) => {
  const history = useHistory();
  const [search, setSearch] = useState('');
  const [subject, setSubject] = useState('');
  const [lessons, setLessons] = useState([]);
  const [filteredLessons, setFilteredLessons] = useState([]);

  useEffect(() => {
    props.setLoader(true);
    classService
      .getParentSubjectDetails({
        subjectID: props.match.params.subjectID,
      })
      .then((responseData) => {
        props.setLoader(false);
        if (responseData) {
          if (responseData.code === 200) {
            setSubject(responseData.data.subject);
            setLessons(responseData.data.lessons);
            setFilteredLessons(responseData.data.lessons);
          }
        }
      })
      .catch((errorData) => {
        props.setLoader(false);
        showToast(errorData?.message);
      });
  }, []); // eslint-disable-linev

  const getRenderingIcon = () => {
    switch (subject.toLowerCase()) {
      case AvailableSubjects.Maths.toLowerCase():
        return MathsSubject;

      case AvailableSubjects.Science.toLowerCase():
        return ScienceSubject;

      case AvailableSubjects.English.toLowerCase():
        return EnglishSubject;

      default:
        return MathsSubject;
    }
  };

  const searchHandler = (searchedValue) => {
    setSearch(searchedValue);
    setFilteredLessons(
      lessons.filter(
        (lesson) =>
          lesson.chapter &&
          lesson.chapter.toLowerCase().includes(searchedValue.toLowerCase())
      )
    );
  };

  const getBreadcrumbs = () => {
    return [
      {
        name: 'Dashboard',
        route: routeConstants.PARENT_DASHBOARD,
      },
      subject && {
        name: subject,
        route: routeConstants.PARENT_SUBJECTDETAILS.replace(
          ':subjectID',
          props.match.params.subjectID
        ),
      },
    ];
  };

  return (
    <div className="global-container content-container">
      <NavigationStatic />

      <section className="content-container-dynamic">
        <BreadcrumbStatic crumbs={getBreadcrumbs()} />

        <div className="lesson-progress-container">
          <div className="lesson-progress-body">
            <div className="row lesson-progress-body-header">
              <div className="lesson-heading-container">
                <img
                  alt="Subject"
                  src={getRenderingIcon(subject)}
                  className="lesson-progress-header-icon"
                />
                <h1 className="lesson-progress-header-title">{subject}</h1>
              </div>
              <div className="input-container">
                <InputText
                  type="text"
                  value={search}
                  icon={SearchIcon}
                  placeholder="Search for lesson"
                  onChange={(event) => searchHandler(event.target.value)}
                />
              </div>
            </div>

            <div className="lessons-grid">
              {filteredLessons && filteredLessons.length !== 0 ? (
                filteredLessons.map((lesson, index) => {
                  return (
                    <LessonProgressCard
                      key={index}
                      lesson={lesson}
                      number={index + 1 < 10 ? `0${index + 1}` : index + 1}
                    />
                  );
                })
              ) : (
                <EmptyState
                  img={EmptyLessonIcon}
                  label="OOPS! No lessons found"
                />
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  setLoader: (dataPayload) => setLoaderAction(dispatch, dataPayload),
});

export default ParentPrivate(
  connect(mapStateToProps, mapDispatchToProps)(LessonProgress)
);
