import LightboxComponent from 'react-image-lightbox';

const Lightbox = (props) => {
  return (
    <LightboxComponent
      mainSrc={props.image}
      onCloseRequest={() => props.setShowLightbox(null)}
    />
  );
};

export default Lightbox;
