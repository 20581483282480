import moment from 'moment';
import { forwardRef } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { addProductsAction } from '../../actions/orderActions';

import AddToCartIcon from '../../assets/images/add-to-cart-blue-icon.svg';

import './styles.scss';

const ProductCard = forwardRef((props, ref) => {
  const handleCartProduct = () => {
    if (props.product) {
      props.addProducts({
        products: [...(props.products || []), props.product],
        productsIds: [...(props.productsIds || []), props.product._id],
      });
    }
  };

  const cardDetailContent = () => {
    if (props.type === 'products') {
      return (
        <div className="product-price-cart-container">
          <p className="product-price">
            ₹{props.prices?.INR} / ${props.prices?.USD}
          </p>
          {!props.productsIds?.includes(props.product?._id) && (
            <div onClick={handleCartProduct} className="cart-icon">
              <img src={AddToCartIcon} alt="add to cart" />
            </div>
          )}
        </div>
      );
    }
    if (props.type === 'mySessions') {
      return (
        <div className="product-price">
          <Link to="#">View</Link>
        </div>
      );
    }
    if (props.type === 'upcomingSessions') {
      return (
        <div className="product-price">
          <p>{moment(props.startDate).format('Do MMM - hh:mm A')}</p>
        </div>
      );
    }
    if (props.type === 'myProducts') {
      return (
        <div className="product-description">
          {props.description && <p>{props.description}</p>}
        </div>
      );
    }
  };
  return (
    <div
      ref={ref}
      id="product-card"
      className="product"
      onClick={props.onClick || undefined}
      style={props.onClick ? { cursor: 'pointer' } : {}}
    >
      <div className="product-image-container">
        <img alt="Thumbnail" className="product-image" src={props.thumbnail} />
      </div>
      <div className="product-detail">
        <h4 className="product-title">{props.title}</h4>

        <div className="product-type-container">{cardDetailContent()}</div>
      </div>
    </div>
  );
});

const mapStateToProps = (state) => ({
  products: state.order.products,
  productsIds: state.order.productsIds,
});

const mapDispatchToProps = (dispatch) => ({
  addProducts: (dataPayload) => addProductsAction(dispatch, dataPayload),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductCard);
