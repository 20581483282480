import React from 'react';
import Loader from '../../assets/images/loader.gif';
import styles from './PageLoader.scss';

const PageLoader = () => {
  return (
    <div
      style={styles}
      className="global-loader d-flex align-items-center justify-content-center"
    >
      <div className="global-loader-wrapper text-center">
        <img alt="Loading" src={Loader} className="global-loader-image" />
        <h4 className="global-loader-text">Loading...</h4>
      </div>
    </div>
  );
};

export default PageLoader;
