import PrimaryButton from '../../PrimaryButton';
import QuestionHeader from '../QuestionHeader';

import styles from './styles.scss';

const DSCQuestion = (props) => {
  const handleAnswerSubmit = () => {
    props.answerSubmitHandler('DSC');
  };

  return (
    <div style={styles} className="dsc-container">
      <div className="content-scroller">
        {props.activeQuestion?.question?.length !== 0 && (
          <div className="dsc-container-content">
            <QuestionHeader question={props.activeQuestion.question} />
          </div>
        )}
      </div>

      <div className="dsc-container-footer">
        <PrimaryButton
          name="Next"
          showIcon={true}
          onClick={handleAnswerSubmit}
        />
      </div>
    </div>
  );
};

export default DSCQuestion;
