import ArrowRight from '../../assets/images/arrow-right-white.svg';

import './styles.scss';

const PrimaryButton = (props) => {
  return (
    <button
      type="button"
      name={props.name}
      onClick={props.onClick}
      className={props.className ? `primary-button ${props.className}` : 'primary-button'}
      {...props}
    >
      {props.name}
      {props.showIcon && <img src={ArrowRight} className="primary-button-icon" alt="Arrow" />}
    </button>
  );
};

export default PrimaryButton;
