import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { routeConstants } from '../../../common/routeConstants';
import {
  resetPackagesAction,
  resetProductsAction,
} from '../../../actions/orderActions';

import Private from '../../../hocs/Private';
import Logo from '../../../assets/images/logo.svg';
import PrimaryButton from '../../../components/PrimaryButton';
import TransactionSuccess from '../../../assets/images/transaction-success.svg';

import './styles.scss';
import Header from '../../../components/Header';

const PaymentSuccess = (props) => {
  const history = useHistory();

  useEffect(() => {
    if (props.currentOrderType === 'PACKAGE') {
      props.resetPackages();
    } else if (props.currentOrderType === 'PRODUCT') {
      props.resetProducts();
    }

    if (!props.currentOrder || !props.currentOrder?.order?.orderID) {
      history.push(routeConstants.DASHBOARD);
    }
  }, []); // eslint-disable-line

  return (
    <div className='global-container onboarding-container paymentsuccess-container'>
      <Header />

      <section className='onboarding-container-dynamic'>
        <div className='decoration-layer'>
          <div className='decoration-layer-one'></div>
          <div className='decoration-layer-two'></div>
          <div className='decoration-layer-three'></div>
          <div className='decoration-layer-four'></div>
          <div className='decoration-layer-five'></div>
          <div className='decoration-layer-six'></div>
        </div>

        <div className='paymentsuccess-container-content'>
          <div className='paymentsuccess-container-wrapper'>
            <h1 className='paymentsuccess-container-title'>
              TRANSACTION SUCCESSFUL
            </h1>
            <div className='paymentsuccess-container-selections'>
              <div className='paymentsuccess-wrapper d-flex align-item-center justify-content-center'>
                <img
                  className='paymentsuccess-wrapper-icon'
                  src={TransactionSuccess}
                />
                <h5 className='paymentsuccess-wrapper-title'>
                  Order ID: {props.currentOrder?.order?.orderID}
                </h5>
                <h5 className='paymentsuccess-wrapper-subtitle'>
                  Your payment has been successfully completed! Start enjoying
                  Skipin.
                </h5>
              </div>
              <PrimaryButton
                name='Skipin Dashboard'
                onClick={() => history.push(routeConstants.DASHBOARD)}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentOrder: state.order.currentOrder,
  currentOrderType: state.order.currentOrderType,
});

const mapDispatchToProps = (dispatch) => ({
  resetPackages: (dataPayload) => resetPackagesAction(dispatch, dataPayload),
  resetProducts: (dataPayload) => resetProductsAction(dispatch, dataPayload),
});

export default Private(
  connect(mapStateToProps, mapDispatchToProps)(PaymentSuccess)
);
