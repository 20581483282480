import { Modal } from 'react-bootstrap';
import { useState, useEffect } from 'react';

import moment from 'moment';
import isEqual from 'lodash/isEqual';
import QuestionHeader from '../QuestionHeader';
import PrimaryButton from '../../PrimaryButton';

import ReadingIllustration from '../../../assets/images/reading-illustration.svg';

import './styles.scss';

const RQTQuestion = (props) => {
  let [timer, setTimer] = useState(0);
  const [startTime, setStartTime] = useState(null);
  const [showOverlay, setShowOverlay] = useState(true);
  const [timerInterval, setTimerInterval] = useState(null);
  const [answerResponse, setAnswerResponse] = useState(null);

  useEffect(() => {
    setStartTime(null);
    setShowOverlay(true);
  }, [props.activeQuestion]);

  useEffect(() => {
    if (
      timerInterval &&
      props.answerResponse &&
      !isEqual(answerResponse, props.answerResponse)
    ) {
      clearInterval(timerInterval);
      setAnswerResponse(props.answerResponse);
    }
  }, [props.answerResponse]);

  const handleAnswerSubmit = () => {
    props.answerSubmitHandler(
      Math.ceil(moment().diff(moment(startTime), 'seconds') / 60)
    );
  };

  const startTimer = () => {
    setShowOverlay(false);
    setStartTime(Date.now());
    const timerIntervalId = setInterval(() => {
      setTimer(++timer);
    }, 1000);
    setTimerInterval(timerIntervalId);
  };

  const getFormattedTimer = () => {
    // TODO: Handle Minute Conversions
    if (timer < 60) {
      return `${timer} seconds`;
    } else if (Math.floor(timer / 60) > 1) {
      return `${Math.floor(timer / 60)} minutes ${timer % 60} seconds`;
    } else {
      return `${Math.floor(timer / 60)} minute ${timer % 60} seconds`;
    }
  };

  return (
    <div className='rqt-container'>
      <div className='content-scroller'>
        {props.activeQuestion?.question?.length !== 0 && (
          <div className='rqt-container-content'>
            <QuestionHeader question={props.activeQuestion.question} />
          </div>
        )}
      </div>

      <div className='rqt-container-footer d-flex align-items-center justify-content-between'>
        <PrimaryButton
          name='Next'
          showIcon={true}
          onClick={handleAnswerSubmit}
        />
        <p style={{ marginBottom: 0 }}>Time Spent: {getFormattedTimer()}</p>
      </div>

      {showOverlay && (
        <Modal
          centered
          size='md'
          show={true}
          backdrop='static'
          backdropClassName='rqt-overlay'
          aria-labelledby='contained-modal-title-vcenter'
        >
          <Modal.Body>
            <img
              src={ReadingIllustration}
              className='reading-illustration'
              alt='Reading'
            />
            <h3 className='reading-title'>Timed Reading Question</h3>
            <p className='reading-description'>
              A timer would run as you're reading this question. Click
              Continue below to get started.
            </p>
          </Modal.Body>
          <Modal.Footer>
            <div className='rqt-continue-btn' style={{marginTop: '72px'}}>
              <PrimaryButton onClick={startTimer} name='Continue'  />
            </div>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

export default RQTQuestion;
