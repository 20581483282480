import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import { setLoaderAction } from '../../../actions/siteActions';
import { routeConstants } from '../../../common/routeConstants';
import { showToast, shortenText } from '../../../common/helpers';

import ParentPrivate from '../../../hocs/ParentPrivate';
import EmptyState from '../../../components/EmptyState';
import UploadCard from '../../../components/UploadCard';
import userService from '../../../services/userService';
import TabularModal from '../../../components/TabularModal';
import SectionTitle from '../../../components/SectionTitle';
import BreadcrumbStatic from '../../../components/BreadcrumbStatic';
import NavigationStatic from '../../../components/NavigationStatic';
import uploadIcon from '../../../assets/images/upload-icon-blue.svg';
import EmptyUploadsIcon from '../../../assets/images/empty-uploads.svg';

import './styles.scss';

const AllUploads = (props) => {
  const [uploads, setUploads] = useState([]);
  const [uploadAttachments, setUploadAttachments] = useState([]);

  useEffect(() => {
    props.setLoader(true);
    userService
      .getUploads()
      .then((responseData) => {
        if (responseData) {
          props.setLoader(false);
          if (responseData.code === 200 && responseData.data) {
            setUploads(responseData.data);
          }
        }
      })
      .catch((errorData) => {
        props.setLoader(false);
        showToast(errorData?.message);
      });
  }, []); // eslint-disable-line

  return (
    <div className="global-container content-container">
      <NavigationStatic />
      <BreadcrumbStatic
        crumbs={[
          {
            name: 'Dashboard',
            route: routeConstants.PARENT_DASHBOARD,
          },
          {
            name: 'All Uploads',
            route: routeConstants.PARENT_ALLUPLOADS,
          },
        ]}
      />

      <div className="all-products">
        <SectionTitle icon={uploadIcon} title="All Uploads" />

        <div className="all-products-grid">
          {uploads && uploads.length !== 0 ? (
            uploads.map((upload, index) => {
              return (
                <UploadCard
                  key={index}
                  title={upload.project?.title}
                  thumbnail={upload.project?.thumbnail}
                  description={shortenText(
                    upload.project?.description?.texts &&
                      upload.project?.description.texts[0],
                    60
                  )}
                  onClick={() => setUploadAttachments(upload.attachments || [])}
                />
              );
            })
          ) : (
            <EmptyState img={EmptyUploadsIcon} label="OOPS! No uploads found" />
          )}
        </div>
      </div>

      {uploadAttachments.length !== 0 && (
        <TabularModal
          rows={uploadAttachments}
          title="View Uploaded Files"
          hideModal={() => setUploadAttachments([])}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  setLoader: (dataPayload) => setLoaderAction(dispatch, dataPayload),
});

export default ParentPrivate(
  connect(mapStateToProps, mapDispatchToProps)(AllUploads)
);
