import { combineReducers } from 'redux';
import authReducer from './authReducer';
import siteReducer from './siteReducer';
import userReducer from './userReducer';
import orderReducer from './orderReducer';
import resourceReducer from './resourceReducer';

export default combineReducers({
  auth: authReducer,
  site: siteReducer,
  user: userReducer,
  order: orderReducer,
  resource: resourceReducer,
});
