import { connect } from 'react-redux';
import { Fragment, useEffect, useState } from 'react';
import { AvailableSubjects } from '../../common/constants';
import { setLoaderAction } from '../../actions/siteActions';
import { routeConstants } from '../../common/routeConstants';
import { showToast, shortenText } from '../../common/helpers';

import ParentPrivate from '../../hocs/ParentPrivate';
import EmptyState from '../../components/EmptyState';
import UploadCard from '../../components/UploadCard';
import SectionTitle from '../../components/SectionTitle';
import TabularModal from '../../components/TabularModal';
import resourceService from '../../services/resourceService';
import MathsSubject from '../../assets/images/maths-subject.svg';
import NavigationStatic from '../../components/NavigationStatic';
import BreadcrumbStatic from '../../components/BreadcrumbStatic';
import EnglishSubject from '../../assets/images/english-subject.svg';
import ScienceSubject from '../../assets/images/science-subject.svg';
import EmptyUploadsIcon from '../../assets/images/empty-uploads.svg';

import './styles.scss';

const ModelProjects = (props) => {
  const [subjects, setSubjects] = useState([]);
  const [uploadAttachments, setUploadAttachments] = useState([]);

  useEffect(() => {
    props.setLoader(true);
    resourceService
      .getModelProjects()
      .then((responseData) => {
        if (responseData) {
          props.setLoader(false);
          if (responseData.code === 200 && responseData.data) {
            setSubjects(responseData.data);
          }
        }
      })
      .catch((errorData) => {
        props.setLoader(false);
        showToast(errorData?.message);
      });
  }, []); // eslint-disable-line

  const getRenderingIcon = (subject) => {
    switch (subject.toLowerCase()) {
      case AvailableSubjects.Maths.toLowerCase():
        return MathsSubject;

      case AvailableSubjects.Science.toLowerCase():
        return ScienceSubject;

      case AvailableSubjects.English.toLowerCase():
        return EnglishSubject;

      default:
        return MathsSubject;
    }
  };

  return (
    <div className='global-container content-container'>
      <NavigationStatic />
      <BreadcrumbStatic
        crumbs={[
          {
            name: 'Dashboard',
            route: routeConstants.PARENT_DASHBOARD,
          },
          {
            name: 'Model Projects',
            route: routeConstants.PARENT_MODELPROJECTS,
          },
        ]}
      />

      <div className='model-projects'>
        <SectionTitle icon={''} title='Model Projects' />

        <div className='model-container'>
          {subjects && subjects.length !== 0 ? (
            subjects.map((subject, index) => {
              return (
                <Fragment key={index}>
                  <SectionTitle
                    title={subject.subject}
                    icon={getRenderingIcon(subject.subject)}
                  />
                  <div className='model-projects-grid'>
                    {subject.projects.map((upload, index) => {
                      return (
                        <UploadCard
                          key={index}
                          title={upload.project?.title}
                          thumbnail={upload.project?.thumbnail}
                          description={shortenText(
                            upload.project?.description?.texts &&
                              upload.project?.description.texts[0],
                            60
                          )}
                          onClick={() =>
                            setUploadAttachments(upload.attachments || [])
                          }
                        />
                      );
                    })}
                  </div>
                </Fragment>
              );
            })
          ) : (
            <EmptyState
              img={EmptyUploadsIcon}
              label='Nothing here! Model projects will be here soon.'
            />
          )}
        </div>
      </div>

      {uploadAttachments.length !== 0 && (
        <TabularModal
          rows={uploadAttachments}
          title='View Uploaded Files'
          hideModal={() => setUploadAttachments([])}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  setLoader: (dataPayload) => setLoaderAction(dispatch, dataPayload),
});

export default ParentPrivate(
  connect(mapStateToProps, mapDispatchToProps)(ModelProjects)
);
