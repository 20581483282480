import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import { showToast } from '../../common/helpers';
import { setLoaderAction } from '../../actions/siteActions';
import { routeConstants } from '../../common/routeConstants';

import Private from '../../hocs/Private';
import EmptyState from '../../components/EmptyState';
import ProductCard from '../../components/ProductCard';
import SectionTitle from '../../components/SectionTitle';
import ProductIcon from '../../assets/images/product.svg';
import EmptyProductIcon from '../../assets/images/empty-product.svg';
import resourceService from '../../services/resourceService';
import BreadcrumbStatic from '../../components/BreadcrumbStatic';
import NavigationStatic from '../../components/NavigationStatic';

import './styles.scss';

const AllProducts = (props) => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    props.setLoader(true);
    resourceService
      .getProducts()
      .then((responseData) => {
        if (responseData) {
          props.setLoader(false);
          if (responseData.code === 200 && responseData.data) {
            setProducts(responseData.data);
          }
        }
      })
      .catch((errorData) => {
        props.setLoader(false);
        showToast(errorData?.message);
      });
  }, []); // eslint-disable-line

  return (
    <div className="global-container content-container">
      <NavigationStatic />
      <BreadcrumbStatic
        crumbs={[
          {
            name: 'Dashboard',
            route: window.location.pathname.includes('parent')
              ? routeConstants.PARENT_DASHBOARD
              : routeConstants.DASHBOARD,
          },
          {
            name: 'All Products',
            route: routeConstants.ALLPRODUCTS,
          },
        ]}
      />

      <div className="all-products">
        <SectionTitle icon={ProductIcon} title="All Products" />

        <div className="all-products-grid">
          {products && products.length !== 0 ? (
            products.map((product, index) => {
              return (
                <ProductCard
                  key={index}
                  type="products"
                  product={product}
                  title={product.title}
                  prices={product.prices}
                  thumbnail={product.thumbnail}
                />
              );
            })
          ) : (
            <EmptyState
              img={EmptyProductIcon}
              label="OOPS! No products found"
            />
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  setLoader: (dataPayload) => setLoaderAction(dispatch, dataPayload),
});

export default Private(
  connect(mapStateToProps, mapDispatchToProps)(AllProducts)
);
