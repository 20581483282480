import { useState, useEffect } from 'react';
import { QuestionHeaderTypes } from '../../../common/constants';

import isEqual from 'lodash/isEqual';
import QuestionHeader from '../QuestionHeader';
import PrimaryButton from '../../PrimaryButton';
import WrongAnswerToast from '../../WrongAnswerToast';
import QuestionSuggestions from '../QuestionSuggestions';

import styles from './styles.scss';

const MCQQuestion = (props) => {
  const { activeQuestion } = props;
  const [wrongAnswer, setWrongAnswer] = useState(null);
  const [correctAnswer, setCorrectAnswer] = useState(null);
  const [answerResponse, setAnswerResponse] = useState(null);

  useEffect(() => {
    if (!isEqual(answerResponse, props.answerResponse)) {
      setAnswerResponse(props.answerResponse);
      if (props.answerResponse) {
        if (!props.answerResponse.correct) {
          setWrongAnswer(correctAnswer);
        } else {
          setWrongAnswer(null);
        }
      }
    }
  }, [props.answerResponse]);

  useEffect(() => {
    setCorrectAnswer(null);
  }, [props.activeQuestion]);

  const handleAnswerSubmit = () => {
    props.answerSubmitHandler(correctAnswer);
  };

  return (
    <div style={styles} className="mcq-container">
      <div className="content-scroller">
        {activeQuestion?.question?.length !== 0 && (
          <div className="mcq-container-content">
            <QuestionHeader question={activeQuestion.question} />

            <div className="mcq-container-item">
              {activeQuestion &&
              activeQuestion?.answer &&
              activeQuestion.answer.length !== 0 &&
              activeQuestion.answerType === QuestionHeaderTypes.TEXT
                ? activeQuestion.answer.map((answerItem, index) => {
                    return (
                      <div className="mcq-text-item" key={index}>
                        <input
                          type="radio"
                          name="answer"
                          id={answerItem.id}
                          value={answerItem.id}
                          className={`answer-radio ${
                            answerResponse &&
                            !answerResponse.corrrect &&
                            answerItem.id === wrongAnswer &&
                            'answer-radio-wrong'
                          }`}
                          checked={answerItem.id === correctAnswer}
                          onChange={(event) =>
                            setCorrectAnswer(event.currentTarget.value)
                          }
                        />
                        <label htmlFor={answerItem.id}>{answerItem.text}</label>
                      </div>
                    );
                  })
                : activeQuestion.answerType === QuestionHeaderTypes.IMAGE && (
                    <div className="row">
                      {activeQuestion.answer.map((answerItem, index) => {
                        return (
                          <div className="col-6 mcq-text-item" key={index}>
                            <input
                              type="radio"
                              name="answer"
                              id={answerItem.id}
                              value={answerItem.id}
                              className={`answer-radio ${
                                answerResponse &&
                                !answerResponse.corrrect &&
                                answerItem.id === wrongAnswer &&
                                'answer-radio-wrong'
                              }`}
                              checked={answerItem.id === correctAnswer}
                              onChange={(event) =>
                                setCorrectAnswer(event.currentTarget.value)
                              }
                            />
                            <label htmlFor={answerItem.id}>
                              <img src={answerItem.url} />
                            </label>
                          </div>
                        );
                      })}
                    </div>
                  )}
            </div>

            {activeQuestion?.questionSuggestions?.length !== 0 && (
              <QuestionSuggestions activeQuestion={activeQuestion} />
            )}
          </div>
        )}
      </div>

      <div className="mcq-container-footer">
        {answerResponse && !answerResponse.correct && <WrongAnswerToast />}

        <PrimaryButton
          name="Submit"
          showIcon={true}
          disabled={!correctAnswer}
          onClick={handleAnswerSubmit}
        />
      </div>
    </div>
  );
};

export default MCQQuestion;
