import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { showToast } from '../../../common/helpers';
import { ResourceTypes } from '../../../common/constants';
import { setLoaderAction } from '../../../actions/siteActions';
import { routeConstants } from '../../../common/routeConstants';
import {
  setActiveQuestionAction,
  setQuestionnaireBreadcrumbsAction,
} from '../../../actions/resourceActions';

import Private from '../../../hocs/Private';
import StudyKit from '../../Resources/StudyKit';
import classService from '../../../services/classService';
import PrimaryButton from '../../../components/PrimaryButton';
import NavigationStatic from '../../../components/NavigationStatic';
import BreadcrumbStatic from '../../../components/BreadcrumbStatic';

import './styles.scss';

const LessonDetails = (props) => {
  const history = useHistory();
  const [resources, setResources] = useState({});
  const [lessonDetails, setLessonDetails] = useState({});
  const [resourceTypes, setResourceTypes] = useState([]);
  const [showStudyKit, setShowStudyKit] = useState(false);
  const [showWalkthroughModal, setShowWalkthroughModal] = useState(false);

  useEffect(() => {
    getLessonDetails();
  }, [props.match.params.lessonID]); // eslint-disable-line

  const getLessonDetails = () => {
    props.setLoader(true);
    classService
      .getLessonDetails({ lessonID: props.match.params.lessonID })
      .then((responseData) => {
        if (responseData) {
          props.setLoader(false);
          if (responseData.code === 200 && responseData.data) {
            if (responseData.data.walkthrough === true) {
              const englishTag = responseData.data.lesson?.englishTag;
              const subjectRoute = routeConstants.SUBJECTDETAILS.replace(
                ':subjectID',
                responseData.data.lesson?.subjectID?._id
              );
              props.setActiveQuestion(responseData.data.question);
              props.setQuestionnaireBreadcrumbs([
                {
                  name: 'Dashboard',
                  route: routeConstants.DASHBOARD,
                },
                {
                  name: responseData.data.lesson?.subjectID?.subject,
                  route: englishTag ? `${subjectRoute}?et=${englishTag}` : subjectRoute,
                },
                {
                  name: responseData.data.lesson?.chapter,
                  route: routeConstants.LESSONDETAILS.replace(
                    ':lessonID',
                    responseData.data.lesson?._id
                  ),
                },
                {
                  name: ResourceTypes.WALKTHROUGH,
                  route: '#',
                },
              ]);
              if (
                responseData.data.question &&
                responseData.data.question.currentQuestion === 1
              ) {
                setShowWalkthroughModal(true);
              } else {
                history.replace(routeConstants.WALKTHROUGH);
              }
            } else {
              setShowStudyKit(true);
              setResources({
                [ResourceTypes.WORKSHEET]: responseData.data.worksheets,
                [ResourceTypes.PROJECT]: responseData.data.projects,
                [ResourceTypes.ACTIVITY]: responseData.data.activities,
              });
              setResourceTypes(responseData.data.types);
              setLessonDetails(responseData.data.lesson);
              props.setActiveQuestion(null);
            }
          }
        }
      })
      .catch((errorData) => {
        props.setLoader(false);
        showToast(errorData?.message);
      });
  };

  const retryWalkthrough = (question) => {
    props.setActiveQuestion(question);
    const englishTag = lessonDetails.englishTag;
    const subjectRoute = routeConstants.SUBJECTDETAILS.replace(
      ':subjectID',
      lessonDetails.subjectID?._id
    );
    props.setQuestionnaireBreadcrumbs([
      {
        name: 'Dashboard',
        route: routeConstants.DASHBOARD,
      },
      {
        name: lessonDetails.subjectID?.subject,
        route: englishTag ? `${subjectRoute}?et=${englishTag}` : subjectRoute,
      },
      {
        name: lessonDetails.chapter,
        route: routeConstants.LESSONDETAILS.replace(
          ':lessonID',
          lessonDetails._id
        ),
      },
      {
        name: ResourceTypes.WALKTHROUGH,
        route: '#',
      },
    ]);
    history.push(routeConstants.WALKTHROUGH);
  };

  const getBreadcrumbs = () => {
    if (lessonDetails && lessonDetails.chapter) {
      const englishTag = lessonDetails.englishTag;
      const subjectRoute = routeConstants.SUBJECTDETAILS.replace(
        ':subjectID',
        lessonDetails.subjectID?._id
      );

      return [
        {
          name: 'Dashboard',
          route: routeConstants.DASHBOARD,
        },
        {
          name: lessonDetails.subjectID?.subject,
          route: englishTag ? `${subjectRoute}?et=${englishTag}` : subjectRoute,
        },
        {
          name: lessonDetails.chapter,
          route: '#',
        },
      ];
    } else {
      return [
        {
          name: 'Dashboard',
          route: routeConstants.DASHBOARD,
        },
      ];
    }
  };

  return (
    <div className="global-container content-container">
      <NavigationStatic />

      <section className="content-container-dynamic">
        <BreadcrumbStatic crumbs={getBreadcrumbs()} />

        {showStudyKit && (
          <StudyKit
            {...props}
            resources={resources}
            lessonDetails={lessonDetails}
            resourceTypes={resourceTypes}
            retryWalkthrough={retryWalkthrough}
          />
        )}
      </section>

      <Modal
        centered
        size="md"
        backdrop="static"
        show={showWalkthroughModal}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Body>
          <h5>
            You are starting a walkthrough. This is a stress-free score-free
            assessment to know how much you already know on this topic.
          </h5>
        </Modal.Body>
        <Modal.Footer>
          <PrimaryButton
            name="Continue"
            onClick={() => history.replace(routeConstants.WALKTHROUGH)}
          />
        </Modal.Footer>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  setActiveQuestion: (dataPayload) =>
    setActiveQuestionAction(dispatch, dataPayload),
  setQuestionnaireBreadcrumbs: (dataPayload) =>
    setQuestionnaireBreadcrumbsAction(dispatch, dataPayload),
  setLoader: (dataPayload) => setLoaderAction(dispatch, dataPayload),
});

export default Private(
  connect(mapStateToProps, mapDispatchToProps)(LessonDetails)
);
