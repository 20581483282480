import { parse } from 'query-string';
import { connect } from 'react-redux';
import { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { showToast } from '../../../common/helpers';
import { AvailableSubjects, ResourceTypes } from '../../../common/constants';
import { setLoaderAction } from '../../../actions/siteActions';
import { routeConstants } from '../../../common/routeConstants';
import SearchIcon from '../../../assets/images/search-icon.svg';

import Private from '../../../hocs/Private';
import InputText from '../../../components/InputText';
import classService from '../../../services/classService';
import LessonIcon from '../../../assets/images/lessons-icon.svg';
import NavigationStatic from '../../../components/NavigationStatic';
import BreadcrumbStatic from '../../../components/BreadcrumbStatic';
import MathsSubject from '../../../assets/images/maths-subject.svg';
import EnglishSubject from '../../../assets/images/english-subject.svg';
import ScienceSubject from '../../../assets/images/science-subject.svg';
import EmptyLessonIcon from '../../../assets/images/empty-lesson.svg';
import EmptyState from '../../../components/EmptyState';

import './styles.scss';

const SubjectDetails = (props) => {
  const history = useHistory();
  const [search, setSearch] = useState('');
  const [subject, setSubject] = useState('');
  const [lessons, setLessons] = useState([]);
  const [journey, setJourney] = useState(null);
  const [filteredLessons, setFilteredLessons] = useState([]);

  useEffect(() => {
    props.setLoader(true);
    classService
      .getSubjectDetails({
        subjectID: props.match.params.subjectID,
        englishTag: parse(window.location.search)?.et?.toUpperCase() || '',
      })
      .then((responseData) => {
        props.setLoader(false);
        if (responseData) {
          if (responseData.code === 200) {
            setSubject(responseData.data.subject);
            setLessons(responseData.data.lessons);
            setJourney(responseData.data.journey);
            setFilteredLessons(responseData.data.lessons);
          }
        }
      })
      .catch((errorData) => {
        props.setLoader(false);
        showToast(errorData?.message);
      });
  }, []); // eslint-disable-line

  const getRenderingIcon = () => {
    switch (subject.toLowerCase()) {
      case AvailableSubjects.Maths.toLowerCase():
        return MathsSubject;

      case AvailableSubjects.Science.toLowerCase():
        return ScienceSubject;

      case AvailableSubjects.English.toLowerCase():
        return EnglishSubject;

      default:
        return MathsSubject;
    }
  };

  const searchHandler = (searchedValue) => {
    setSearch(searchedValue);
    setFilteredLessons(
      lessons.filter(
        (lesson) =>
          lesson.chapter &&
          lesson.chapter.toLowerCase().includes(searchedValue.toLowerCase())
      )
    );
  };

  const lessonSelectionHandler = (lesson) => {
    if (!lesson.disabled) {
      history.push(
        routeConstants.LESSONDETAILS.replace(':lessonID', lesson._id)
      );
    }
  };

  const getBreadcrumbs = () => {
    const englishTag = parse(window.location.search)?.et;
    const subjectRoute = routeConstants.SUBJECTDETAILS.replace(
      ':subjectID',
      props.match.params.subjectID
    );

    return [
      {
        name: 'Dashboard',
        route: routeConstants.DASHBOARD,
      },
      subject && {
        name: subject,
        route: englishTag ? `${subjectRoute}?et=${englishTag}` : subjectRoute,
      },
    ];
  };


  const getLinearBgColor=(subject)=>{
    switch(subject.toLowerCase()){
      case AvailableSubjects.Maths.toLowerCase():
        return 'linear-gradient(90deg, #F47F7F, #FDDFDF)'
        
      case AvailableSubjects.Science.toLowerCase():
        return 'linear-gradient(90deg, #fac055, #feeac3)'

      case AvailableSubjects.English.toLowerCase():
        return 'linear-gradient(90deg, #42E476, #D0F9DD)'

      default :
        return 'grey'
        

    }
  }
  const handleJourney = () => {
    if (journey) {
      if (journey.resourceType === ResourceTypes.WALKTHROUGH) {
        history.replace(
          routeConstants.LESSONDETAILS.replace(':lessonID', journey.chapterID)
        );
      } else if (journey.resourceType === ResourceTypes.WORKSHEET) {
        history.replace(
          routeConstants.RESOURCEDETAILS.replace(
            ':resourceType',
            ResourceTypes.WORKSHEET.toLowerCase()
          ).replace(':resourceID', journey.resourceID)
        );
      }
    }
  };

  return (
    <div className="global-container content-container">
      <NavigationStatic />

      <section className="content-container-dynamic">
        <BreadcrumbStatic crumbs={getBreadcrumbs()} />

        <div className="subjectdetails-container">
          <div className="subjectdetails-header-container">
            <div className="subjectdetails-header">
              <img
                alt="Subject"
                src={getRenderingIcon(subject)}
                className="subjectdetails-header-icon"
              />
              <h1 className="subjectdetails-header-title">{subject}</h1>
            </div>
            {journey && (
              <div className="continue-section">
                <div className="left">
                  <p className="subtitle">Start where you left...</p>
                  <h3 className="subject">
                    {journey.resourceType === ResourceTypes.WALKTHROUGH
                      ? journey.chapter?.chapter
                      : journey.resourceTitle}
                  </h3>
                  <h5 className="resource-type">{journey.resourceType}</h5>
                </div>
                <div className="progress-content">
                  <div className="progress-value">
                    <h2 className="num">{journey.completedQuestions}</h2>
                    <h2 className="den">{journey.totalQuestions}</h2>
                  </div>
                  <Link to="#" onClick={handleJourney} className="link">
                    CONTINUE
                  </Link>
                </div>
              </div>
            )}
          </div>
          <div className="subjectdetails-body">
            <div className="row subjectdetails-body-header">
              <div className="lesson-heading-container">
                <img src={LessonIcon} alt="" className="lesson-icon" />
                <h5 className="lesson-title">
                  Lessons ({filteredLessons.length})
                </h5>
              </div>

              <div className="input-container">
                <InputText
                  type="text"
                  placeholder="Search for lesson"
                  onChange={(event) => searchHandler(event.target.value)}
                  value={search}
                  icon={SearchIcon}
                />
              </div>
            </div>
           { 
           
           <div className="row d-flex align-item-center items-container">
            {filteredLessons.length !== 0  && <div className="dividing-line"></div>}
              {filteredLessons &&
                filteredLessons.length !== 0 ?
                filteredLessons.map((lesson, index) => {
                  return (
                    <div
                      key={index}
                      // className={`col-5 lesson-item ${
                      //   lesson.disabled && 'lesson-item-disabled'
                      // }`}
                      className={lesson.disabled ? 'col-5 lesson-item lesson-item-disabled' : 'col-5 lesson-item'}
                      onClick={() => lessonSelectionHandler(lesson)}
                    >
                      <h5 className="lesson-item-number" style={{background:getLinearBgColor(subject)}}>{lesson.number}</h5>
                      <p className="lesson-item-name">{lesson.chapter}</p>
                    </div>
                  );
                })
                :
                <EmptyState
                  img={EmptyLessonIcon}
                  label='OOPS! No lessons found'
                />
              }
            </div>}
          </div>
        </div>
      </section>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  setLoader: (dataPayload) => setLoaderAction(dispatch, dataPayload),
});

export default Private(
  connect(mapStateToProps, mapDispatchToProps)(SubjectDetails)
);
