import { useState, useEffect } from 'react';

import get from 'lodash/get';
import set from 'lodash/set';
import isEqual from 'lodash/isEqual';
import QuestionHeader from '../QuestionHeader';
import PrimaryButton from '../../PrimaryButton';
import WrongAnswerToast from '../../WrongAnswerToast';
import QuestionSuggestions from '../QuestionSuggestions';

import styles from './styles.scss';

const LQQuestion = (props) => {
  const [correctAnswer, setCorrectAnswer] = useState([]);
  const [answerResponse, setAnswerResponse] = useState(null);

  const getAnswerData = () => {
    const answer = get(props.activeQuestion, 'answer');
    setCorrectAnswer(answer);
  };

  useEffect(() => {
    if (!isEqual(answerResponse, props.answerResponse)) {
      setAnswerResponse(props.answerResponse);
    }
  }, [props.answerResponse]);

  useEffect(() => {
    getAnswerData();
  }, [props.activeQuestion]);

  const handleAnswerSubmit = () => {
    props.answerSubmitHandler(correctAnswer);
  };

  const onDragOver = (event) => {
    event.preventDefault();
  };

  const onDrop = (event, index) => {
    event.preventDefault();
    let correctAnswerCopy = Object.assign([], correctAnswer);
    set(correctAnswerCopy, `[${index}].ans`, event.dataTransfer.getData('text/plain'));
    setCorrectAnswer(correctAnswerCopy);
  };

  const checkSubmitEnabled = () => {
    let isSubmitDisabled = true;
    let isValid = true;
    if (correctAnswer.length) {
      for (let ans of correctAnswer) {
        if (!ans.ans || ans.ans === '') {
          isValid = false;
          isSubmitDisabled = true;
          break;
        }

        if (isValid) {
          isSubmitDisabled = false;
        }
      }
    }

    return isSubmitDisabled;
  };

  return (
    <div style={styles} className="lq-container">
      <div className="content-scroller">
        {props.activeQuestion?.question?.length !== 0 && (
          <div className="lq-container-content">
            <QuestionHeader question={props.activeQuestion.question} />

            <div className="lq-container-item">
              {correctAnswer && correctAnswer.length !== 0 && (
                <div className="row">
                  {correctAnswer.map((answerItem, index) => {
                    return (
                      <div key={index} className="col-5 lq-item">
                        <div className="lq-item-label">{answerItem.label}</div>
                        <div
                          onDragOver={onDragOver}
                          onDrop={(event) => onDrop(event, index)}
                          className={`lq-item-answer ${
                            !answerItem.ans && 'lq-item-answer--faded'
                          }`}
                        >
                          {answerItem.ans || 'Drop Here...'}
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>

            {props.activeQuestion?.questionSuggestions?.length !== 0 && (
              <QuestionSuggestions
                onDragOver={onDragOver}
                activeQuestion={props.activeQuestion}
              />
            )}
          </div>
        )}
      </div>

      <div className="lq-container-footer">
        {answerResponse && !answerResponse.correct && <WrongAnswerToast />}

        <PrimaryButton
          name="Submit"
          showIcon={true}
          onClick={handleAnswerSubmit}
          disabled={checkSubmitEnabled()}
        />
      </div>
    </div>
  );
};

export default LQQuestion;
