import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { showToast } from '../../common/helpers';
import { setLoaderAction } from '../../actions/siteActions';
import { routeConstants } from '../../common/routeConstants';

import Private from '../../hocs/Private';
import InputText from '../../components/InputText';
import userService from '../../services/userService';
import SectionTitle from '../../components/SectionTitle';
import TrashIcon from '../../assets/images/trash-icon.svg';
import PrimaryButton from '../../components/PrimaryButton';
import InputTextArea from '../../components/InputTextArea';
import BreadcrumbStatic from '../../components/BreadcrumbStatic';
import NavigationStatic from '../../components/NavigationStatic';

import './styles.scss';

const Support = (props) => {
  const history = useHistory();
  const [success, setSuccess] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    description: '',
    attachments: [],
    mobileNumber: '',
  });

  useEffect(() => {
    if (props.profile) {
      setFormData({
        ...formData,
        name: props.profile.name,
        email: props.profile.email,
        mobileNumber: `+${props.profile.formattedNumber}`,
      });
    }
  }, []); // eslint-disable-line

  const handleFieldsUpdate = (fieldName, fieldValue) => {
    setFormData({
      ...formData,
      [fieldName]: fieldValue,
    });
  };

  const checkSubmitDisabled = () => {
    if (
      !formData.name ||
      !formData.email ||
      !/.+@.+\..+/.test(formData.email) ||
      !formData.mobileNumber ||
      !formData.description
    ) {
      return true;
    }
    return false;
  };

  const submitHandler = () => {
    props.setLoader(true);
    const dataPayload = {
      ...formData,
      mobileNumber: formData.mobileNumber.replace('+', ''),
    };
    userService
      .support(dataPayload)
      .then((responseData) => {
        if (responseData) {
          props.setLoader(false);
          if (responseData.code === 200 && responseData.data) {
            setSuccess(true);
          }
        }
      })
      .catch((errorData) => {
        props.setLoader(false);
        showToast(errorData?.message);
      });
  };

  const uploadFileHandler = (event) => {
    const file = event.target.files[0];
    const fileData = new FormData();
    fileData.append('file', file);
    fileData.append('directoryName', props.resourceData?._id || null);
    props.setLoader(true);
    userService
      .uploadSupportFile(fileData)
      .then((responseData) => {
        if (responseData) {
          props.setLoader(false);
          if (responseData.code === 200 && responseData.data) {
            setFormData({
              ...formData,
              attachments: [
                ...formData.attachments,
                {
                  name: file.name,
                  url: responseData.data?.url,
                  type: getFileType(file.type),
                },
              ],
            });
          }
        }
      })
      .catch((errorData) => {
        props.setLoader(false);
        showToast(errorData?.message);
      });
  };

  const getFileType = (type) => {
    switch (type) {
      case 'image/png':
        return 'png';

      case 'image/jpeg':
        return 'jpeg';

      case 'image/jpg':
        return 'jpg';

      default:
        return 'jpg';
    }
  };

  const removeAttachment = (index) => {
    let attachmentsCopy = Object.assign([], formData.attachments);
    attachmentsCopy = formData.attachments.filter(
      (item, itemIndex) => itemIndex !== index
    );
    setFormData({ ...formData, attachments: attachmentsCopy });
  };

  return (
    <div className="global-container content-container">
      <NavigationStatic />
      <BreadcrumbStatic
        crumbs={[
          {
            name: 'Support',
            route: routeConstants.SUPPORT,
          },
        ]}
      />

      <div className="support">
        <SectionTitle title="Support" />

        <div className="support-container">
          <div className="support-container-field">
            <InputText
              type="text"
              value={formData.name}
              placeholder="Your name"
              onChange={(event) =>
                handleFieldsUpdate('name', event.currentTarget.value)
              }
            />
          </div>
          <div className="support-container-field">
            <InputText
              type="email"
              value={formData.email}
              placeholder="Your email address"
              onChange={(event) =>
                handleFieldsUpdate('email', event.currentTarget.value)
              }
            />
          </div>
          <div className="support-container-field">
            <InputText
              type="text"
              disabled={true}
              value={formData.mobileNumber}
              placeholder="Your contact number"
            />
          </div>
          <div className="support-container-field">
            <InputTextArea
              rows={4}
              type="text"
              value={formData.description}
              placeholder="Please describe your problem here..."
              onChange={(event) =>
                handleFieldsUpdate('description', event.currentTarget.value)
              }
            />
          </div>
          <div className="support-container-field">
            <label className="support-files" htmlFor="uploader">
              <input
                type="file"
                id="uploader"
                name="uploader"
                onChange={uploadFileHandler}
                className="support-files-input"
                accept="image/jpg,image/png,image/jpeg"
              />
              Attach Files
            </label>
          </div>

          <div className="support-container-attachments">
            {formData.attachments.length !== 0 &&
              formData.attachments.map((attachment, index) => {
                return (
                  <div key={index} className="attachment col-6">
                    <h6 className="attachment-number">{index + 1}</h6>
                    <div className="attachment-name">{attachment.name}</div>
                    <img
                      alt="Remove"
                      src={TrashIcon}
                      className="attachment-remove"
                      onClick={() => removeAttachment(index)}
                    />
                  </div>
                );
              })}
          </div>

          <PrimaryButton
            name="Submit"
            onClick={submitHandler}
            disabled={checkSubmitDisabled()}
          />
        </div>

        <Modal
          centered
          size="md"
          show={success}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
        >
          <Modal.Body>
            <h5>
              Your support request have been successfully submitted. Someone
              from our team will reach out to you soon.
            </h5>
          </Modal.Body>
          <Modal.Footer>
            <PrimaryButton
              onClick={() => history.push(routeConstants.DASHBOARD)}
              name="Go To Dashboard"
            />
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  profile: state.user.profile,
});

const mapDispatchToProps = (dispatch) => ({
  setLoader: (dataPayload) => setLoaderAction(dispatch, dataPayload),
});

export default Private(connect(mapStateToProps, mapDispatchToProps)(Support));
