export const DialogTextsPositive = [
    {
      title: 'Great Going!',
      subTitle: 'Go ahead and try something new.',
    },
    {
      title: 'Perfect!',
      subTitle: 'Go ahead and try something new.',
    },
    {
      title: `That's Correct!`,
      subTitle: 'Go ahead and try something new.',
    },
    {
      title: 'You are Right!',
      subTitle: 'Go ahead and try something new.',
    },
  ];
  
  export const DialogTextsNegetive = [
    {
      title: `We've hit a bump!`,
      subTitle: 'Check the explanation and move on.',
    },
    {
      title: 'This seems to be a slip up!',
      subTitle: 'Check the explanation and move on.',
    },
    {
      title: 'Hurdle along the way?',
      subTitle: 'Check the explanation and move on.',
    },
    {
      title: 'Having hiccups?',
      subTitle: 'Check the explanation and move on.',
    },
  ];

  export const DialogTextsNeutral = [
    {
      title: 'You have completed this question.',
      subTitle: 'Check the suggested answer and move ahead.',
    },
    {
      title: 'Thanks for trying.',
      subTitle: 'Check the suggested answer and move ahead.',
    },
    {
      title: `You have gone one step further.`,
      subTitle: 'Check the suggested answer and move ahead.',
    },
    {
      title: 'This is done',
      subTitle: 'Check the suggested answer and move ahead.',
    },
  ];