import React, { useState } from "react";

import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { showToast } from "../../common/helpers";
import { logoutAction } from '../../actions/authActions';
import { setLoaderAction } from "../../actions/siteActions";
import { routeConstants } from "../../common/routeConstants";

import Header from "../../components/Header";
import userService from "../../services/userService";
import PrimaryButton from "../../components/PrimaryButton";
import AppStoreImage from "../../assets/images/app-store.png";
import GooglePlayImage from "../../assets/images/google-play.png";

import "./styles.scss";

const DownloadApp = (props) => {
  const history = useHistory();

  React.useEffect(() => {
    if(props.profile?.mobileNumber) {
      setMobileNumber(props.profile.mobileNumber);
    }

    props.logout();
  }, []); // eslint-disable-line

  const [mobileNumber, setMobileNumber] = useState("");

  const getAppDownloadLink = () => {
    props.setLoader(true);
    userService
      .downloadApp({ mobileNumber })
      .then((responseData) => {
        if (responseData && responseData.data) {
          props.setLoader(false);
          showToast("Download link has been sent.");
        }
      })
      .catch((errorData) => {
        props.setLoader(false);
        showToast(errorData?.message);
      });
  };

  return (
    <div className="onboarding-container download-container">
      <Header hideMenu={true}/>

      <section className="onboarding-container-dynamic">
        <div className="decoration-layer">
          <div className="decoration-layer-one"></div>
          <div className="decoration-layer-two"></div>
          <div className="decoration-layer-three"></div>
          <div className="decoration-layer-four"></div>
          <div className="decoration-layer-five"></div>
          <div className="decoration-layer-six"></div>
        </div>

        <div className="download">
          <div className="row">
            <div className="col-12">
              <h2>Your package has expired</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="download-left">
                <a
                  href="https://play.google.com/store/apps/details?id=io.wiseowllearning.skipin"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <img
                    className="download-left-icon"
                    alt="Play Store"
                    src={GooglePlayImage}
                  />
                </a>
                <a
                  href="https://apps.apple.com/us/app/skipin/id1537130813"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <img
                    className="download-left-icon"
                    alt="App Store"
                    src={AppStoreImage}
                  />
                </a>
              </div>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="download-right">
                <input
                  type="text"
                  maxLength={10}
                  value={mobileNumber}
                  placeholder="98XXXXXXXX"
                  className="download-right-input"
                  onChange={(event) =>
                    setMobileNumber(event.currentTarget.value)
                  }
                />
                <PrimaryButton
                  name="Get Download Link"
                  onClick={getAppDownloadLink}
                  disabled={mobileNumber.length !== 10}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="download-footer">
              <PrimaryButton
                name="Login"
                onClick={() => history.push(routeConstants.HOME)}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

const mapStateToProps = (state) => ({
  profile: state.user.profile
});

const mapDispatchToProps = (dispatch) => ({
  logout: (dataPayload) => logoutAction(dispatch, dataPayload),
  setLoader: (dataPayload) => setLoaderAction(dispatch, dataPayload),
});

export default connect(mapStateToProps, mapDispatchToProps)(DownloadApp);
