import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { showToast } from '../../common/helpers';
import { ResourceTypes } from '../../common/constants';
import { setLoaderAction } from '../../actions/siteActions';
import { routeConstants } from '../../common/routeConstants';

import Private from '../../hocs/Private';
import InputText from '../../components/InputText';
import ProductCard from '../../components/ProductCard';
import SectionTitle from '../../components/SectionTitle';
import SearchIcon from '../../assets/images/search-icon.svg';
import EmptyProducts from '../../assets/images/empty-product.svg';
import resourceService from '../../services/resourceService';
import BreadcrumbStatic from '../../components/BreadcrumbStatic';
import NavigationStatic from '../../components/NavigationStatic';

import './styles.scss';
import EmptyState from '../../components/EmptyState';

const MyProducts = (props) => {
  const history = useHistory();
  const [search, setSearch] = useState('');
  const [products, setProducts] = useState([]);
  const [searchedProducts, setSearchedProducts] = useState([]);

  useEffect(() => {
    props.setLoader(true);
    resourceService
      .getMyProducts()
      .then((responseData) => {
        if (responseData) {
          props.setLoader(false);
          if (responseData.code === 200 && responseData.data) {
            setProducts(responseData.data);
            setSearchedProducts(responseData.data);
          }
        }
      })
      .catch((errorData) => {
        props.setLoader(false);
        showToast(errorData?.message);
      });
  }, []); // eslint-disable-line

  const searchHandler = (searchedValue) => {
    let searchedProductsCopy = Object.assign([], products);
    if (searchedValue) {
      let lowerSearchedValue = searchedValue.toLowerCase().trim();
      searchedProductsCopy = products.filter(
        (item) =>
          item &&
          (item.title?.toLowerCase()?.includes(lowerSearchedValue) ||
            item.chapterID?.chapter
              ?.toLowerCase()
              ?.includes(lowerSearchedValue) ||
            item.chapterID?.subjectID?.subject
              ?.toLowerCase()
              ?.includes(lowerSearchedValue))
      );
    }
    setSearch(searchedValue);
    setSearchedProducts(searchedProductsCopy);
  };

  const goToResourceDetails = (resourceID) => {
    history.push(
      routeConstants.RESOURCEDETAILS.replace(
        ':resourceType',
        ResourceTypes.PROJECT.toLowerCase()
      ).replace(':resourceID', resourceID)
    );
  };

  return (
    <div className='global-container content-container'>
      <NavigationStatic />
      <BreadcrumbStatic
        crumbs={[
          {
            name: 'Dashboard',
            route: window.location.pathname.includes('parent')
              ? routeConstants.PARENT_DASHBOARD
              : routeConstants.DASHBOARD,
          },
          {
            name: 'My Products',
            route: routeConstants.MYPRODUCTS,
          },
        ]}
      />

      <div className='my-products'>
        <SectionTitle title='My Products' />

        <div className='my-products-search'>
          <InputText
            type='text'
            value={search}
            icon={SearchIcon}
            className='search-input'
            placeholder='Search here...'
            onChange={(event) => searchHandler(event.target.value)}
          />
        </div>

        <div className='my-products-grid'>
          {searchedProducts && searchedProducts.length !== 0 ? (
            searchedProducts.map((product, index) => {
              return (
                <ProductCard
                  key={index}
                  type='myProducts'
                  title={product.title}
                  thumbnail={product.thumbnail}
                  onClick={() => goToResourceDetails(product._id)}
                  description={`${product.chapterID?.subjectID?.subject} / ${product.chapterID?.chapter}`}
                />
              );
            })
          ) : (
            <div className='my-products-empty-state'>
              <EmptyState
                img={EmptyProducts}
                label={
                  props.match.params.search
                    ? 'No products found'
                    : 'Nothing here! Time to buy some new products.'
                }
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  setLoader: (dataPayload) => setLoaderAction(dispatch, dataPayload),
});

export default Private(
  connect(mapStateToProps, mapDispatchToProps)(MyProducts)
);
