import { connect } from 'react-redux';
import { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { showToast } from '../../../common/helpers';
import { setLoaderAction } from '../../../actions/siteActions';
import { routeConstants } from '../../../common/routeConstants';
import { setProfileAction } from '../../../actions/userActions';

import Private from '../../../hocs/Private';
import Logo from '../../../assets/images/logo.svg';
import userService from '../../../services/userService';
import classService from '../../../services/classService';
import PrimaryButton from '../../../components/PrimaryButton';
import OnboardingStatic from '../../../components/OnboardingStatic';

import './styles.scss';
import Header from '../../../components/Header';

const CompleteProfile = (props) => {
  const history = useHistory();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [classes, setClasses] = useState([]);
  const [selectedClass, setSelectedClass] = useState('');
  const classMap = {
    5: 'five',
    6: 'six',
    7: 'seven',
    8: 'eight',
  };

  useEffect(() => {
    props.setLoader(true);
    classService
      .getClasses()
      .then((responseData) => {
        props.setLoader(false);
        if (responseData) {
          if (responseData.code === 200) {
            setClasses(responseData.data);
          }
        }
      })
      .catch((errorData) => {
        props.setLoader(false);
        showToast(errorData?.message);
      });
  }, []); // eslint-disable-line

  const checkSubmitDisabled = () => {
    if (!name || !email || !selectedClass || !/.+@.+\..+/.test(email)) {
      return true;
    }
    return false;
  };

  const submitHandler = () => {
    props.setLoader(true);
    userService
      .updateProfile({
        name,
        email,
        classID: selectedClass,
      })
      .then((responseData) => {
        props.setLoader(false);
        if (responseData) {
          if (responseData.code === 200) {
            props.setProfile(responseData.data);
            history.push(routeConstants.PACKAGES);
          }
        }
      })
      .catch((errorData) => {
        props.setLoader(false);
        showToast(errorData?.message);
      });
  };

  return (
    // <div className="global-container onboarding-container completeprofile-container">
    <div className="onboarding-container completeprofile-container">
      <Header />

      <OnboardingStatic />

      <section className="onboarding-container-dynamic">
        <div className="decoration-layer">
          <div className="decoration-layer-one"></div>
          <div className="decoration-layer-two"></div>
          <div className="decoration-layer-three"></div>
          <div className="decoration-layer-four"></div>
          <div className="decoration-layer-five"></div>
          <div className="decoration-layer-six"></div>
        </div>

        <div className="completeprofile-container-content">
          <div className="completeprofile-container-wrapper">
            <h1 className="completeprofile-container-title">
            Register yourself in two easy steps
            </h1>

            <div className="completeprofile-container-fields">
              <div className="completeprofile-container-input">
                <label className="completeprofile-container-label">
                  Full name
                </label>
                <input
                  type="text"
                  value={name}
                  placeholder="Aayush Dutta"
                  onChange={(event) => setName(event.target.value)}
                  className="completeprofile-container-field"
                />
              </div>

              <div className="completeprofile-container-input">
                <label className="completeprofile-container-label">
                  Email address
                </label>
                <input
                  type="email"
                  value={email}
                  placeholder="abc@example.com"
                  onChange={(event) => setEmail(event.target.value)}
                  className="completeprofile-container-field"
                />
              </div>
            </div>

            <div className="completeprofile-container-selections">
              <span className="selections-title">Select your class</span>

              <div className="selections-wrapper d-flex align-item-center justify-content-center">
                {classes &&
                  classes.length !== 0 &&
                  classes.map((classItem, index) => {
                    return (
                      <label
                        key={index}
                        className={
                          selectedClass === classItem._id
                            ? `selections-label selections-label--${
                                classMap[classItem.class]
                              } selections-label--selected`
                            : `selections-label selections-label--${
                                classMap[classItem.class]
                              }`
                        }
                        htmlFor={`class-${classItem._id}`}
                      >
                        {classItem.class}
                        <input
                          type="radio"
                          name="classRadio"
                          value={classItem._id}
                          id={`class-${classItem._id}`}
                          className="selections-field"
                          onChange={(event) =>
                            setSelectedClass(event.target.value)
                          }
                        />
                      </label>
                    );
                  })}
              </div>
            </div>

            <PrimaryButton
              name="Submit"
              onClick={submitHandler}
              disabled={checkSubmitDisabled()}
            />
          </div>
        </div>
      </section>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  setLoader: (dataPayload) => setLoaderAction(dispatch, dataPayload),
  setProfile: (dataPayload) => setProfileAction(dispatch, dataPayload),
});

export default Private(
  connect(mapStateToProps, mapDispatchToProps)(CompleteProfile)
);
