import { Modal, Table } from 'react-bootstrap';
import './styles.scss';

const TabularModal = (props) => {
  return (
    <Modal centered size="md" show={true} onHide={props.hideModal}>
      <Modal.Header closeButton>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Table responsive>
          <tbody>
            {props.rows &&
              props.rows.length !== 0 &&
              props.rows.map((row, index) => {
                return (
                  <tr key={index}>
                    <td>
                      {`File ${index + 1} - `}
                      <a href={row.url} target="_blank" rel="noreferrer">
                        {row.name}
                      </a>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </Modal.Body>
    </Modal>
  );
};

export default TabularModal;
