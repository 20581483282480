import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { showToast } from '../../../common/helpers';
import { SessionTypes } from '../../../common/constants';
import { setLoaderAction } from '../../../actions/siteActions';
import { routeConstants } from '../../../common/routeConstants';

import Private from '../../../hocs/Private';
import userService from '../../../services/userService';
import ProductCard from '../../../components/ProductCard';
import SectionTitle from '../../../components/SectionTitle';
import BreadcrumbStatic from '../../../components/BreadcrumbStatic';
import NavigationStatic from '../../../components/NavigationStatic';
import MySessionIcon from '../../../assets/images/my-sessions-icon.svg';
import WhiteMySessionIcon from '../../../assets/images/white-my-sessions-icon.svg';
import UpcomingSessionIcon from '../../../assets/images/upcoming-sessions-icon.svg';
import WhiteUpcomingSessionIcon from '../../../assets/images/white-upcoming-sessions-icon.svg';
import RightChevron from '../../../assets/images/right-chevron-icon.svg';

import './styles.scss';

const AllSessions = (props) => {
  const history = useHistory();
  const [sessions, setSessions] = useState({});
  const [selectedType, setSelectedType] = useState('');

  useEffect(() => {
    getSessionByType(SessionTypes.PAST);
  }, []); // eslint-disable-line

  const getSessionByType = (type) => {
    setSelectedType(type);
    if (!sessions[type] || !sessions[type].length) {
      props.setLoader(true);
      userService
        .getSessions({
          type,
        })
        .then((responseData) => {
          if (responseData) {
            if (responseData.code === 200 && responseData.data) {
              props.setLoader(false);
              setSessions({
                ...sessions,
                [type]: responseData.data || [],
              });
            }
          }
        })
        .catch((errorData) => {
          props.setLoader(false);
          showToast(errorData?.message);
        });
    }
  };

  const onClickHandler = (sessionID) => {
    if (window.location.pathname.includes('parent')) {
      history.push(
        routeConstants.PARENT_SESSIONDETAILS.replace(':sessionID', sessionID)
      );
    } else {
      history.push(
        routeConstants.SESSIONDETAILS.replace(':sessionID', sessionID)
      );
    }
  };

  return (
    <div className="global-container content-container">
      <NavigationStatic />
      <BreadcrumbStatic
        crumbs={[
          {
            name: 'Dashboard',
            route: window.location.pathname.includes('parent')
              ? routeConstants.PARENT_DASHBOARD
              : routeConstants.DASHBOARD,
          },
          {
            name: 'All Sessions',
            route: routeConstants.ALLSESSIONS,
          },
        ]}
      />

      <div className="allsessions">
        <SectionTitle title="All Sessions" />

        <div className="allsessions-body">
          <div className="allsessions-body-left">
            <div
              className={
                selectedType === SessionTypes.PAST
                  ? 'session-type session-type-selected'
                  : 'session-type'
              }
              onClick={() => getSessionByType(SessionTypes.PAST)}
            >
              {
             selectedType ===SessionTypes.PAST ? 
             <img alt="Session" src={WhiteMySessionIcon} /> :
             <img alt="Session" src={MySessionIcon} />
            }
              <div className="session-type-info">
                <h5 className="session-type-title">My Sessions</h5>
              </div>
              {/* <div className="chevron">
              <img src={RightChevron} alt='' />
              </div> */}
            </div>
            <div
              className={
                selectedType === SessionTypes.UPCOMING
                  ? 'session-type session-type-selected'
                  : 'session-type'
              }
              onClick={() => getSessionByType(SessionTypes.UPCOMING)}
            >
             {
             selectedType === SessionTypes.UPCOMING ? 
             <img alt="Session" src={WhiteUpcomingSessionIcon} /> :
             <img alt="Session" src={UpcomingSessionIcon} />
            
            }
              <div className="session-type-info">
                <h5 className="session-type-title">Upcoming Sessions</h5>
              </div>
              {/* <div className="chevron">
                <img src={RightChevron} alt='' />
              </div> */}
            </div>
          </div>
          <div className="allsessions-body-right">
            <div className="content">
              {selectedType ? (
                sessions &&
                sessions[selectedType] &&
                sessions[selectedType].length !== 0 ? (
                  sessions[selectedType].map((session, index) => {
                    return (
                      <ProductCard
                        key={index}
                        title={session.title}
                        thumbnail={session.thumbnail}
                        startDate={session.startDate}
                        type={
                          selectedType === SessionTypes.UPCOMING
                            ? 'upcomingSessions'
                            : 'mySessions'
                        }
                        onClick={() => onClickHandler(session._id)}
                      />
                    );
                  })
                ) : (
                  <p className="session-msg">No sessions found</p>
                )
              ) : (
                <p className="session-msg">Please select a type</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  setLoader: (dataPayload) => setLoaderAction(dispatch, dataPayload),
});

export default Private(
  connect(mapStateToProps, mapDispatchToProps)(AllSessions)
);
