import { reduxConstants } from '../common/reduxConstants';

const siteReducer = (
  state = {
    loader: false,
  },
  action
) => {
  switch (action.type) {
    case reduxConstants.LOADER:
      return {
        ...state,
        loader: action.payload,
      };

    case reduxConstants.LOGOUT:
      return {
        loader: false,
      };

    default: {
      return state;
    }
  }
};

export default siteReducer;
