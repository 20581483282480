import { connect } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { loginAction } from '../../../actions/authActions';
import { setLoaderAction } from '../../../actions/siteActions';
import { routeConstants } from '../../../common/routeConstants';
import { baseAxiosInstance, cdnAxiosInstance } from '../../../services';
import { getDeviceIdentifier, showToast } from '../../../common/helpers';

import Public from '../../../hocs/Public';
import Logo from '../../../assets/images/logo.svg';
import authService from '../../../services/authService';
import PrimaryButton from '../../../components/PrimaryButton';
import OnboardingStatic from '../../../components/OnboardingStatic';

import './styles.scss';
import Header from '../../../components/Header';

const OTPEntry = (props) => {
  let timerTicker = null;
  const history = useHistory();
  const ref1 = useRef();
  const ref2 = useRef();
  const ref3 = useRef();
  const ref4 = useRef();
  const [otp, setOtp] = useState({
    1: '',
    2: '',
    3: '',
    4: '',
  });
  const [timerObject, setTimerObject] = useState({
    timerLimit: 59,
    resendCount: 1,
    isTimerEnabled: true,
    isResendEnabled: false,
  });

  useEffect(() => {
    if (!history?.location?.state?.mobileNumber) {
      history.push(routeConstants.HOME);
    }
  }, []); // eslint-disable-line

  const handleFieldUpdate = (fieldName, fieldValue) => {
    if (fieldValue.length > 1) {
      return
    }

    let otpCopy = Object.assign({}, otp);
    otpCopy[fieldName] = fieldValue;
    setOtp(otpCopy);

    if (/^\d+$/.test(fieldValue)) {
      switch (fieldName) {
        case '1':
          ref2.current.focus();
          break;

        case '2':
          ref3.current.focus();
          break;

        case '3':
          ref4.current.focus();
          break;

        default:
          break;
      }
    }
  };

  const getOtp = () => {
    return `${otp[1]}${otp[2]}${otp[3]}${otp[4]}`;
  };

  const onKeyDown = (keyCode, fieldName) => {
    if (keyCode === 8 && !otp[fieldName]) {
      switch (fieldName) {
        case '2':
          ref1.current.focus();
          break;

        case '3':
          ref2.current.focus();
          break;

        case '4':
          ref3.current.focus();
          break;

        default:
          break;
      }
    }
  };

  const submitHandler = () => {
    props.setLoader(true);
    authService
      .verify({
        otp: getOtp(),
        countryCode: '91',
        deviceIdentifier: getDeviceIdentifier(),
        mobileNumber: history.location.state.mobileNumber,
      })
      .then((responseData) => {
        if (responseData) {
          if (responseData.code === 200) {
            baseAxiosInstance.defaults.headers.common[
              'Authorization'
            ] = `${responseData.data?.accessToken}`;

            cdnAxiosInstance.defaults.headers.common[
              'Authorization'
            ] = `${responseData.data?.accessToken}`;
            props.login(responseData.data);
          }
        }
      })
      .catch((errorData) => {
        props.setLoader(false);
        showToast(errorData?.message);
      });
  };

  const enableResendTimer = () => {
    let timerObj = Object.assign({}, timerObject);

    timerTicker = setInterval(() => {
      timerObj.timerLimit--;
      if (timerObj.timerLimit === 0) {
        timerObj.isTimerEnabled = false;
        timerObj.isResendEnabled = true;

        clearInterval(timerTicker);
      }

      setTimerObject({ ...timerObj });
    }, 1000);
  };

  const resendOtp = () => {
    props.setLoader(true);
    authService
      .login({
        countryCode: '91',
        mobileNumber: history?.location?.state?.mobileNumber,
      })
      .then((responseData) => {
        props.setLoader(false);
      })
      .catch((errorData) => {
        props.setLoader(false);
      });
  };

  const handleResendRequest = () => {
    if (timerObject.resendCount <= 5) {
      resendOtp();
      setTimerObject({
        timerLimit: 59,
        isTimerEnabled: true,
        isResendEnabled: false,
        resendCount: timerObject.resendCount + 1,
      });
    }
  };

  useEffect(() => {
    enableResendTimer();
  }, [timerObject.resendCount]); // eslint-disable-line

  return (
    // <div className='global-container onboarding-container otpentry-container'>
    <div className='onboarding-container otpentry-container'>
      <header className='global-header'>
        <div className='global-header-left'>
          <Link to='#' onClick={() => window.open(process.env.REACT_APP_STATIC_APP_URL)}>
            <img src={Logo} className='global-header-logo' alt='SKIPIN' />
          </Link>
        </div>

        <div className='global-header-right'></div>
      </header>

      <OnboardingStatic />

      <section className='onboarding-container-dynamic'>
        <div className='decoration-layer'>
          <div className='decoration-layer-one'></div>
          <div className='decoration-layer-two'></div>
          <div className='decoration-layer-three'></div>
          <div className='decoration-layer-four'></div>
          <div className='decoration-layer-five'></div>
          <div className='decoration-layer-six'></div>
        </div>

        <div className='otpentry-container-content'>
          <h1 className='otpentry-container-title'>
            We have sent you a 4 digit OTP on
          </h1>
          <p className='otpentry-container-mobile'>
            +91-{history?.location?.state?.mobileNumber}
          </p>

          <div className='otpentry-container-input'>
            <label className='otpentry-container-label'>
              Enter the OTP received
            </label>
            <div className='otpentry-container-inputwrapper flex-wrapper d-flex align-items-center justify-content-start'>
              <input
                ref={ref1}
                type='number'
                value={otp[1]}
                className='otpentry-container-field'
                placeholder='X'
                maxLength={1}
                onChange={(event) =>
                  handleFieldUpdate('1', event.currentTarget.value)
                }
              />
              <input
                ref={ref2}
                type='number'
                value={otp[2]}
                className='otpentry-container-field'
                placeholder='X'
                maxLength={1}
                onChange={(event) =>
                  handleFieldUpdate('2', event.currentTarget.value)
                }
                onKeyDown={(event) => onKeyDown(event.keyCode, '2')}
              />
              <input
                ref={ref3}
                type='number'
                value={otp[3]}
                className='otpentry-container-field'
                placeholder='X'
                maxLength={1}
                onChange={(event) =>
                  handleFieldUpdate('3', event.currentTarget.value)
                }
                onKeyDown={(event) => onKeyDown(event.keyCode, '3')}
              />
              <input
                ref={ref4}
                type='number'
                value={otp[4]}
                className='otpentry-container-field'
                placeholder='X'
                maxLength={1}
                onChange={(event) =>
                  handleFieldUpdate('4', event.currentTarget.value)
                }
                onKeyDown={(event) => onKeyDown(event.keyCode, '4')}
              />
            </div>
          </div>

          {timerObject.resendCount <= 5 && (
            <div className='otpentry-container-fallback d-flex align-items-center justify-content-center'>
              <span className='fallback-text'>Didn't receive it?</span>
              {timerObject.isTimerEnabled ? (
                <span className='fallback-timer'>{`00:${
                  timerObject.timerLimit > 9
                    ? timerObject.timerLimit
                    : `0${timerObject.timerLimit}`
                }`}</span>
              ) : (
                <span className='fallback-resend' onClick={handleResendRequest}>
                  Resend
                </span>
              )}
            </div>
          )}

          <PrimaryButton
            name='Verify & Proceed'
            onClick={submitHandler}
            disabled={getOtp().length !== 4}
          />
        </div>
      </section>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  login: (dataPayload) => loginAction(dispatch, dataPayload),
  setLoader: (dataPayload) => setLoaderAction(dispatch, dataPayload),
});

export default Public(connect(mapStateToProps, mapDispatchToProps)(OTPEntry));
