import { useEffect, useState } from 'react';

import './styles.scss';

const News = (props) => {
  const [currentNews, setCurrentNews] = useState(null);

  useEffect(() => {
    let newsIndex = 0;
    setCurrentNews(props.news[newsIndex]);

    const newsInterval = setInterval(() => {
      newsIndex++;
      if (newsIndex === props.news.length) {
        newsIndex = 0;
      }
      setCurrentNews(props.news[newsIndex]);
    }, 10000);

    return () => {
      if (newsInterval) {
        clearInterval(newsInterval);
      }
    };
  }, []); // eslint-disable-line

  return currentNews ? (
    <div className="news">
      <p className="news-text">
        {currentNews.content}
        {currentNews.url && (
          <a href={currentNews.url} target="_blank" rel="noreferrer">
            View
          </a>
        )}
      </p>
    </div>
  ) : (
    <></>
  );
};

export default News;
