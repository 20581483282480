import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { showToast } from '../../../common/helpers';
import { useState, useEffect, Fragment } from 'react';
import { PaymentGateways } from '../../../common/constants';
import { setLoaderAction } from '../../../actions/siteActions';
import { routeConstants } from '../../../common/routeConstants';
import {
  setCurrentOrderAction,
  removePackageAction,
} from '../../../actions/orderActions';

import get from 'lodash/get';
import Private from '../../../hocs/Private';
import Header from '../../../components/Header';
import LinkButton from '../../../components/LinkButton';
import PrimaryButton from '../../../components/PrimaryButton';
import packageService from '../../../services/packageService';
import PaymentGatewayModal from '../../../components/PaymentGatewayModal';
import TrashIcon from '../../../assets/images/trash-icon.svg';
import './styles.scss';

const Checkout = (props) => {
  const history = useHistory();
  const [breakdown, setBreakdown] = useState({
    rounded: 0,
    discount: 0,
    gstAmount: 0,
    totalAmount: 0,
    initialAmount: 0,
    couponDiscount: 0,
  });
  const [couponDetails, setCouponDetails] = useState({
    coupon: '',
    couponError: '',
    couponType: null,
    couponDiscount: 0,
    isCouponApplied: false,
    isGiftCardApplied: false,
  });
  const [showPaymentGateways, setShowPaymentGateways] = useState(false);

  useEffect(() => {
    props.setCurrentOrder({ currentOrder: null, currentOrderType: null });
  }, []); // eslint-disable-line

  useEffect(() => {
    if (props.packages && props.packages.length) {
      let initialAmount = 0,
        totalAmount = 0,
        discount = 0,
        gstAmount = 0,
        couponDiscount = 0,
        rounded = 0;
      for (let packageItem of props.packages) {
        initialAmount += parseInt(packageItem.prices.INR);
      }

      totalAmount = initialAmount;
      discount = (props.packageDiscount || 0) * props.packages.length;
      totalAmount -= discount;
      if (couponDetails.isCouponApplied || couponDetails.isGiftCardApplied) {
        if (couponDetails.couponType) {
          couponDiscount = couponDetails.couponDiscount;
        } else {
          couponDiscount = (initialAmount * couponDetails.couponDiscount) / 100;
        }
        couponDiscount =
          couponDiscount > totalAmount ? totalAmount : couponDiscount;
        totalAmount -= couponDiscount;
      }
      gstAmount = 0.18 * totalAmount;
      totalAmount += gstAmount;
      rounded = Math.ceil(totalAmount);

      setBreakdown({
        rounded: getValidMinimum(rounded),
        discount: getValidMinimum(discount),
        gstAmount: getValidMinimum(gstAmount),
        totalAmount: getValidMinimum(totalAmount),
        initialAmount,
        couponDiscount,
      });
    } else {
      history.push(routeConstants.PACKAGES);
    }
  }, [props.packages, couponDetails.couponDiscount]); // eslint-disable-line

  const getValidMinimum = (number) => {
    return number < 0 ? 0 : number;
  };

  const applyCouponHandler = () => {
    if (!couponDetails.coupon || couponDetails.coupon === '') {
      setCouponDetails({
        ...couponDetails,
        couponDiscount: 0,
        isCouponApplied: false,
        isGiftCardApplied: false,
      });
      return;
    } else {
      if (couponDetails.coupon.length > 15) {
        verifyGiftCard();
      } else {
        verifyCoupon();
      }
    }
  };

  const removeCoupon = () => {
    setCouponDetails({
      ...couponDetails,
      couponDiscount: 0,
      isCouponApplied: false,
      isGiftCardApplied: false,
    });
  };

  const verifyCoupon = () => {
    props.setLoader(true);

    packageService
      .verifyCoupon({
        currency: 'INR',
        packageID: props.packagesIds,
        coupon: couponDetails.coupon,
      })
      .then((responseData) => {
        props.setLoader(false);

        if (responseData) {
          if (responseData.code === 200) {
            setCouponDetails({
              ...couponDetails,
              couponError: '',
              isCouponApplied: true,
              isGiftCardApplied: false,
              couponType: responseData.data.isAgent,
              couponDiscount: responseData.data.maxDiscount,
            });
          }
        }
      })
      .catch((errorData) => {
        props.setLoader(false);
        showToast(errorData?.message);
        setCouponDetails({
          ...couponDetails,
          couponError: errorData?.message,
        });
      });
  };

  const verifyGiftCard = () => {
    props.setLoader(true);

    packageService
      .verifyGiftCard({
        currency: 'INR',
        packageID: props.packagesIds,
        giftCard: couponDetails.coupon,
      })
      .then((responseData) => {
        props.setLoader(false);

        if (responseData) {
          if (responseData.code === 200) {
            setCouponDetails({
              ...couponDetails,
              couponError: '',
              couponType: true,
              isCouponApplied: false,
              isGiftCardApplied: true,
              couponDiscount: responseData.data.amount,
            });
          }
        }
      })
      .catch((errorData) => {
        props.setLoader(false);
        showToast(errorData?.message);
        setCouponDetails({
          ...couponDetails,
          couponError: errorData?.message,
        });
      });
  };

  const setCouponHandler = (coupon) => {
    setCouponDetails({
      ...couponDetails,
      coupon: coupon.toUpperCase(),
    });
  };

  const proceedCheckoutHandler = (gateway) => {
    if (couponDetails.isGiftCardApplied) {
      redeemUserPackage(gateway);
    } else {
      addUserPackage(gateway);
    }
  };

  const addUserPackage = (gateway) => {
    props.setLoader(true);
    let dataPayload = {
      gateway,
      currency: 'INR',
      packageID: props.packagesIds,
    };
    if (couponDetails.isCouponApplied) {
      dataPayload.coupon = couponDetails.coupon.toUpperCase();
    }

    packageService
      .addUserPackage(dataPayload)
      .then((responseData) => {
        props.setLoader(false);

        if (responseData) {
          if (responseData.code === 200) {
            props.setCurrentOrder({
              currentOrder: responseData.data,
              currentOrderType: 'PACKAGE',
            });

            const orderAmount = get(responseData.data, 'order.orderAmount');
            if (orderAmount !== 0) {
              if (gateway === PaymentGateways.RAZORPAY) {
                triggerRazorpayCheckout(responseData.data);
              } else {
                window.location.href = routeConstants.PACKAGESPAYMENT;
              }
            } else {
              verifyOrder(responseData.data);
            }
          }
        }
      })
      .catch((errorData) => {
        props.setLoader(false);
        showToast(errorData?.message);
      });
  };

  const redeemUserPackage = (gateway) => {
    props.setLoader(true);
    let dataPayload = {
      gateway,
      currency: 'INR',
      packageID: props.packagesIds,
    };
    if (couponDetails.isGiftCardApplied) {
      dataPayload.giftCard = couponDetails.coupon;
    }

    packageService
      .redeemUserPackage(dataPayload)
      .then((responseData) => {
        props.setLoader(false);

        if (responseData) {
          if (responseData.code === 200) {
            props.setCurrentOrder({
              currentOrder: responseData.data,
              currentOrderType: 'PACKAGE',
            });

            const orderAmount = get(responseData.data, 'order.orderAmount');
            if (orderAmount !== 0) {
              if (gateway === PaymentGateways.RAZORPAY) {
                triggerRazorpayCheckout(responseData.data);
              } else {
                window.location.href = routeConstants.PACKAGESPAYMENT;
              }
            } else {
              verifyOrder(responseData.data);
            }
          }
        }
      })
      .catch((errorData) => {
        props.setLoader(false);
        showToast(errorData?.message);
      });
  };

  const triggerRazorpayCheckout = (order) => {
    const orderDetails = order?.order;
    const razorpayInstance = new window.Razorpay({
      theme: {
        color: '#BBDEF5',
      },
      name: 'Wise Owl Learning',
      handler: (response) => {
        if (
          response &&
          response.razorpay_payment_id &&
          response.razorpay_signature
        ) {
          verifyOrder(order, response.razorpay_payment_id);
        }
      },
      key: get(orderDetails, 'appId'),
      prefill: {
        name: get(orderDetails, 'userName'),
        email: get(orderDetails, 'userEmail'),
        contact: get(orderDetails, 'userNumber'),
      },
      order_id: get(orderDetails, 'createdOrder.id'),
      amount: get(orderDetails, 'createdOrder.amount'),
      currency: get(orderDetails, 'createdOrder.currency'),
      description: get(orderDetails, 'createdOrder.receipt'),
      modal: {
        ondismiss: () => failOrder(order),
      },
    });

    razorpayInstance.open();
    setShowPaymentGateways(false);
  };

  const verifyOrder = (orderDetails, paymentGatewayID) => {
    props.setLoader(true);
    packageService
      .verifyPayment({ userPackageID: orderDetails._id, paymentGatewayID })
      .then((responseData) => {
        props.setLoader(false);
        if (responseData) {
          if (responseData.code === 200) {
            history.push(routeConstants.PACKAGESCHECKOUTSUCCESS);
          }
        }
      })
      .catch((errorData) => {
        props.setLoader(false);
        showToast(errorData?.message);
      });
  };

  const failOrder = (orderDetails) => {
    props.setLoader(true);
    packageService
      .failPayment({ userPackageID: orderDetails._id })
      .then((responseData) => {
        props.setLoader(false);
        if (responseData) {
          if (responseData.code === 200) {
            history.push(routeConstants.PACKAGESCHECKOUTFAILURE);
          }
        }
      })
      .catch((errorData) => {
        props.setLoader(false);
        showToast(errorData?.message);
      });
  };

  return (
    <div className='global-container onboarding-container checkout-container'>
      <Header />

      <section className='onboarding-container-dynamic'>
        <div className='decoration-layer'>
          <div className='decoration-layer-one'></div>
          <div className='decoration-layer-two'></div>
          <div className='decoration-layer-three'></div>
          <div className='decoration-layer-four'></div>
          <div className='decoration-layer-five'></div>
          <div className='decoration-layer-six'></div>
        </div>

        <div className='checkout-container-content'>
          <div className='checkout-container-wrapper'>
            <div className='checkout-container-content'>
              <h1 className='checkout-container-title'>Your Order Summary</h1>
              <div className='checkout-wrapper d-flex align-item-center justify-content-center'>
                <div className='package-row package-row-heading-container'>
                  <div className='package-row-heading'>Package</div>
                  <div className='package-row-heading'>Price</div>
                </div>

                <div>
                  {props.packages &&
                    props.packages.length !== 0 &&
                    props.packages.map((packageItem, index) => {
                      return (
                        <div key={index} className='package-row packge-item-container'>
                          <div className='header-left'>
                            <div className='title heading'>
                              {packageItem.title}
                            </div>
                            <div className='subtitle'>
                              {packageItem.permittedResourcesText}
                            </div>
                            <div
                              className='remove-package'
                              onClick={() =>
                                props.removePackage(packageItem._id)
                              }
                            >
                              <img src={TrashIcon} alt='' />
                              <div className='remove-label'>Remove</div>
                            </div>
                          </div>
                          <div className='header-right'>
                            ₹{packageItem.prices.INR}
                          </div>
                        </div>
                      );
                    })}
                </div>
                <div className='row d-flex align-items-center justify-content-center coupon-container'>
                  <div className='coupon-content'>
                    <h5>Apply Coupon / Gift Card</h5>
                    <div className='coupon-content-input'>
                      <input
                        type='text'
                        placeholder='ABCDEF12345'
                        value={couponDetails.coupon}
                        onChange={(event) =>
                          setCouponHandler(event.currentTarget.value)
                        }
                        disabled={
                          couponDetails?.isCouponApplied ||
                          couponDetails?.isGiftCardApplied
                        }
                      />
                      {couponDetails?.isCouponApplied ||
                      couponDetails?.isGiftCardApplied ? (
                        <div className='applied-container'>
                          <img src={TrashIcon} alt='' onClick={removeCoupon} />
                          <p className='applied-text'>Applied</p>
                        </div>
                      ) : (
                        <LinkButton
                          className='coupon-btn'
                          name='Apply'
                          onClick={applyCouponHandler}
                        />
                      )}
                    </div>
                  </div>
                </div>

                <div className='d-flex align-items-center justify-content-center summary'>
                  <div className='breakup-container'>
                    <h5 className='title'>
                      Subtotal:
                      <span className='value'>₹{breakdown.initialAmount}</span>
                    </h5>
                    {breakdown.discount !== 0 && (
                      <h5 className='title'>
                        (-) Offer Discount:
                        <span className='value'>₹{breakdown.discount}</span>
                      </h5>
                    )}
                    {breakdown.couponDiscount !== 0 && (
                      <h5 className='title'>
                        (-){' '}
                        {couponDetails.isCouponApplied
                          ? 'Coupon'
                          : couponDetails.isGiftCardApplied
                          ? 'Gift Card'
                          : ''}{' '}
                        Discount:
                        <span className='value'>
                          ₹{breakdown.couponDiscount}
                        </span>
                      </h5>
                    )}
                    <h5 className='title'>
                      GST (18%):
                      <span className='value'>₹{breakdown.gstAmount}</span>
                    </h5>
                    <div className='bottom-border'></div>
                    <h5 className='total'>
                      Total: <span className='value'>₹{breakdown.rounded}</span>
                    </h5>
                  </div>
                </div>
              </div>
              {breakdown.discount + breakdown.couponDiscount !== 0 && (
                <div className='checkout-footer'>
                  <p>
                    You saved{' '}
                    <b>₹{breakdown.couponDiscount + breakdown.discount}/-</b>
                  </p>
                </div>
              )}

              <div className='pay-btn'>
                <PrimaryButton
                  onClick={() =>
                    breakdown.rounded === 0
                      ? proceedCheckoutHandler(PaymentGateways.RAZORPAY)
                      : setShowPaymentGateways(true)
                  }
                  name={
                    breakdown.rounded === 0
                      ? 'Proceed'
                      : `Pay ₹${breakdown.rounded}/-`
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      {showPaymentGateways && (
        <PaymentGatewayModal
          setLoader={props.setLoader}
          onClick={proceedCheckoutHandler}
          onHide={() => setShowPaymentGateways(false)}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  packages: state.order.packages,
  packagesIds: state.order.packagesIds,
  packageDiscount: state.order.packageDiscount,
});

const mapDispatchToProps = (dispatch) => ({
  setLoader: (dataPayload) => setLoaderAction(dispatch, dataPayload),
  setCurrentOrder: (dataPayload) =>
    setCurrentOrderAction(dispatch, dataPayload),
  removePackage: (dataPayload) => removePackageAction(dispatch, dataPayload),
});

export default Private(connect(mapStateToProps, mapDispatchToProps)(Checkout));
