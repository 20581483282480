import { useState } from 'react';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { showToast } from '../../../common/helpers';
import { setLoaderAction } from '../../../actions/siteActions';
import { routeConstants } from '../../../common/routeConstants';

import Swal from 'sweetalert2';
import Public from '../../../hocs/Public';
import Logo from '../../../assets/images/logo.svg';
import authService from '../../../services/authService';
import PrimaryButton from '../../../components/PrimaryButton';
import OnboardingStatic from '../../../components/OnboardingStatic';

import './styles.scss';

const MobileEntry = (props) => {
  const history = useHistory();
  const [mobileNumber, setMobileNumber] = useState('');

  const handleFieldUpdate = (fieldValue) => {
    if (fieldValue && !/^\d+$/.test(fieldValue)) {
      return
    }

    setMobileNumber(fieldValue);
  };

  const submitHandler = () => {
    props.setLoader(true);
    authService
      .login({ mobileNumber, countryCode: '91' })
      .then((responseData) => {
        props.setLoader(false);
        if (responseData) {
          if (responseData.code === 200) {
            history.push(routeConstants.ONBOARDINGOTP, {
              mobileNumber,
            });
          }
        }
      })
      .catch((errorData) => {
        props.setLoader(false);
        if(errorData?.code === 422) {
          Swal.fire({
            icon: 'error',
            title: 'Oops!',
            confirmButtonText: 'Okay',
            html: `${errorData.message} <br/><a href="${process.env.REACT_APP_STATIC_APP_DOWNLOAD_URL}" target="_blank" rel="noreferrer noopener">Head over to download our app to get started.</a>`
          })
        } else {
          showToast(errorData?.message);
        }
      });
  };

  return (
    // <div className='global-container onboarding-container mobileentry-container'>
    <div className='onboarding-container mobileentry-container'>
      <header className='global-header'>
        <div className='global-header-left'>
          <Link to='#' onClick={() => window.open(process.env.REACT_APP_STATIC_APP_URL)}>
            <img src={Logo} className='global-header-logo' alt='SKIPIN' />
          </Link>
        </div>

        <div className='global-header-right'></div>
      </header>

      <OnboardingStatic />

      <section className='onboarding-container-dynamic'>
        <div className='decoration-layer'>
          <div className='decoration-layer-one'></div>
          <div className='decoration-layer-two'></div>
          <div className='decoration-layer-three'></div>
          <div className='decoration-layer-four'></div>
          <div className='decoration-layer-five'></div>
          <div className='decoration-layer-six'></div>
        </div>

        <div className='mobileentry-container-content'>
          <h1 className='mobileentry-container-title'>Let's Get Started</h1>
          <p className='mobileentry-container-description'>
            Every Skipin account is connected to a unique mobile number
          </p>

          <div className='mobileentry-container-input'>
            <label className='mobileentry-container-label'>
              Enter your mobile number
            </label>
            <div className='flex-wrapper d-flex align-items-center justify-content-start'>
              <input
                type='text'
                className='mobileentry-container-field'
                value='+91'
                readOnly
              />
              <input
                type='text'
                maxLength={10}
                value={mobileNumber}
                placeholder='98XXXXXXXX'
                className='mobileentry-container-field'
                onChange={(event) =>
                  handleFieldUpdate(event.currentTarget.value)
                }
              />
            </div>
          </div>

          <PrimaryButton
            name='Send OTP'
            showIcon={true}
            onClick={submitHandler}
            disabled={mobileNumber.length !== 10}
          />
        </div>
      </section>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  setLoader: (dataPayload) => setLoaderAction(dispatch, dataPayload),
});

export default Public(
  connect(mapStateToProps, mapDispatchToProps)(MobileEntry)
);
