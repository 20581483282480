export const QuestionSubTypes = {
  LQ: 'LQ',
  DSC: 'DSC',
  SAQ: 'SAQ',
  MCQ: 'MCQ',
  MTF: 'MTF',
  LAQ: 'LAQ',
  RQT: 'RQT',
  LAQE: 'LAQE',
  MCQM: 'MCQM',
  TBLT: 'TBLT',
  TBLS: 'TBLS',
  FITBT: 'FITBT',
  FITBS: 'FITBS',
  EMPTY: 'EMPTY',
  LAQAI: 'LAQAI',
  LAQWL: 'LAQWL',
};

export const QuestionHeaderTypes = {
  IMAGE: 'image',
  TEXT: 'text',
  VIDEO: 'video',
  AUDIO: 'audio',
  URL: 'url'
};

export const ResourceTypes = {
  PROJECT: 'Project',
  ACTIVITY: 'Activity',
  WORKSHEET: 'Worksheet',
  WALKTHROUGH: 'Walkthrough',
};

export const searchTypes = {
  GLOBAL: 'Global',
  LESSON: 'Lesson',
};

export const PaymentGateways = {
  CASHFREE: 'Cashfree',
  RAZORPAY: 'Razorpay',
};

export const AvailableSubjects = {
  Maths: 'Maths',
  Science: 'Science',
  English: 'English',
};

export const AvailableEnglishTags = {
  GRAMMAR: 'GRAMMAR',
  READING: 'READING',
  WRITING: 'WRITING',
};

export const SessionTypes = {
  PAST: 'PAST',
  UPCOMING: 'UPCOMING',
};

export const CashfreeWalletCodes = [
  { name: 'FreeCharge', value: '4001' },
  { name: 'MobiKwik', value: '4002' },
  { name: 'OLA Money', value: '4003' },
  { name: 'Reliance Jio Money', value: '4004' },
  { name: 'Airtel money', value: '4006' },
  { name: 'Paytm', value: '4007' },
  { name: 'Amazon Pay', value: '4008' },
  { name: 'Phonepe', value: '4009' },
];

export const RAZORPAY_SDK_URL = 'https://checkout.razorpay.com/v1/checkout.js';
export const CASHFREE_SDK_URL =
  'https://www.cashfree.com/assets/cashfree.sdk.v1.2.js';
export const CASHFREE_ENVIRONMENT = 'TEST'; //use TEST/PROD
