import React, { Fragment, useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import BreadcrumbStatic from '../../components/BreadcrumbStatic';
import NavigationStatic from '../../components/NavigationStatic';
import { routeConstants } from '../../common/routeConstants';
import News from '../../components/News';
import './styles.scss';
import InputText from '../../components/InputText';
import SearchIcon from '../../assets/images/search-icon.svg';
import SubjectTitleIcon from '../../assets/images/subject.svg';
import ProductIcon from '../../assets/images/product.svg';
import MySessionsIcon from '../../assets/images/my-sessions-icon.svg';
import SectionTitle from '../../components/SectionTitle';
import ScienceIcon from '../../assets/images/science-subject.svg';
import MathsIcon from '../../assets/images/maths-subject.svg';
import EnglishIcon from '../../assets/images/english-subject.svg';
import SubjectIcon from '../../components/SubjectIcon';
import ProductCard from '../../components/ProductCard';
import BlueArrow from '../../assets/images/blue-arrow.svg';
import RightGreyIcon from '../../assets/images/right-arrow-grey.svg';
import AdvertismentCard from '../../components/AdvertismentCard';
import { connect } from 'react-redux';
import { setLoaderAction } from '../../actions/siteActions';
import userService from '../../services/userService';
import { showToast } from '../../common/helpers';
import { AvailableSubjects, ResourceTypes } from '../../common/constants';
import EnglishTagSelection from '../../components/EnglishTagSelection';
import { Carousel } from 'react-bootstrap';
import ProgressBar from '../../components/ProgressBar';
import Private from '../../hocs/Private';
import { setProfileAction } from '../../actions/userActions';

const Dashboard = (props) => {
  const [search, setSearch] = useState('');
  const [dashboardData, setDashboardData] = useState({});
  const [showEnglishTagSelection, setShowEnglishTagSelection] = useState(null);

  const history = useHistory();

  useEffect(() => {
    props.setLoader(true);
    userService
      .getDashboard()
      .then((responseData) => {
        if (responseData) {
          props.setLoader(false);
          if (responseData.code === 200 && responseData.data) {
            const data = responseData.data;
            if (data && !data.profile?.journey?.profile?.completed) {
              return history.push(routeConstants.DOWNLOADAPP);
            }
            props.setProfile(data.profile);

            if (data && !data.subjects.length) {
              return history.push(routeConstants.DOWNLOADAPP);
            }

            setDashboardData(data);
          }
        }
      })
      .catch((errorData) => {
        props.setLoader(false);
        showToast(errorData?.message);
      });
  }, []); // eslint-disable-line

  const getRenderingSubjectIcons = (subject) => {
    switch (subject) {
      case AvailableSubjects.Maths:
        return MathsIcon;

      case AvailableSubjects.Science:
        return ScienceIcon;

      case AvailableSubjects.English:
        return EnglishIcon;

      default:
        break;
    }
  };

  const handleSubjectRedirection = (subject, subjectID) => {
    if (subject === AvailableSubjects.English) {
      setShowEnglishTagSelection(subjectID);
    } else {
      history.push(
        routeConstants.SUBJECTDETAILS.replace(':subjectID', subjectID)
      );
    }
  };

  const handleJourney = () => {
    const journeyData = dashboardData?.profile?.journey?.content;
    if (journeyData) {
      if (journeyData.resourceType === ResourceTypes.WALKTHROUGH) {
        history.replace(
          routeConstants.LESSONDETAILS.replace(
            ':lessonID',
            journeyData.chapterID
          )
        );
      } else if (journeyData.resourceType === ResourceTypes.WORKSHEET) {
        history.replace(
          routeConstants.RESOURCEDETAILS.replace(
            ':resourceType',
            ResourceTypes.WORKSHEET.toLowerCase()
          ).replace(':resourceID', journeyData.resourceID)
        );
      }
    }
  };

  const searchHandler = (event) => {
    if (event.keyCode === 13) {
      history.push(routeConstants.SEARCHPARAMS.replace(':search', search));
    }
  };

  return (
    <div className="global-container content-container">
      <NavigationStatic />
      <BreadcrumbStatic
        crumbs={[
          {
            name: 'Dashboard',
            route: routeConstants.DASHBOARD,
          },
        ]}
      />

      {dashboardData &&
        dashboardData.news &&
        dashboardData.news.length !== 0 && <News news={dashboardData.news} />}

      <div className="dashboard">
        <InputText
          type="text"
          value={search}
          icon={SearchIcon}
          className="search-input"
          onKeyDown={searchHandler}
          placeholder="Search here..."
          onChange={(event) => setSearch(event.target.value)}
        />

        <div className='dashboard-purchased-section'>
          <section className="subjects-continue">
            <div className="my-subjects">
              <SectionTitle icon={SubjectTitleIcon} title="My Subjects" />
              <div className="my-subjects-list">
                {dashboardData?.subjects &&
                  dashboardData?.subjects.length !== 0 &&
                  dashboardData?.subjects.map((subject, index) => {
                    return (
                      <SubjectIcon
                        key={index}
                        label={subject.subject}
                        className="my-subjects-icon"
                        icon={getRenderingSubjectIcons(subject.subject)}
                        onClick={() =>
                          handleSubjectRedirection(subject.subject, subject._id)
                        }
                      />
                    );
                  })}
              </div>
            </div>

            {dashboardData?.profile?.journey?.content && (
              <div className="continue-section">
                <p className="subtitle">Start where you left...</p>
                <div className="progress-content">
                  <div>
                    <h3 className="subject">
                      {dashboardData.profile.journey.content.resourceType ===
                      ResourceTypes.WALKTHROUGH
                        ? dashboardData.profile.journey.content.chapter?.chapter
                        : dashboardData.profile.journey.content.resourceTitle}
                    </h3>
                    <h5 className="resource-type">
                      {dashboardData.profile.journey.content.resourceType}
                    </h5>
                    <Link to="#" onClick={handleJourney} className="link">
                      <span>
                        CONTINUE 
                      </span>
                      <img src={RightGreyIcon} alt='' />
                    </Link>
                  </div>
                  <div className="circular-progress">
                    <ProgressBar
                      total={
                        dashboardData?.profile?.journey?.content?.totalQuestions
                      }
                      progress={
                        dashboardData?.profile?.journey?.content
                          ?.completedQuestions
                      }
                    />
                  </div>
                </div>
              </div>
            )}
          </section>
        </div>

        {/* Prodcuts Section */}

        {dashboardData &&
          dashboardData.products &&
          dashboardData.products.length !== 0 && (
            <section className="products section">
              <SectionTitle icon={ProductIcon} title="Products" />
              <div className="products-container">
                <div className="product-list">
                  {dashboardData.products.map((product, index) => {
                    if (index < 3) {
                      return (
                        <ProductCard
                          key={index}
                          type="products"
                          product={product}
                          title={product.title}
                          prices={product.prices}
                          thumbnail={product.thumbnail}
                        />
                      );
                    } else {
                      return <Fragment key={index}></Fragment>;
                    }
                  })}
                </div>
                {dashboardData.products.length > 3 && (
                  <Link
                    to={routeConstants.ALLPRODUCTS}
                    className="view-all-products"
                  >
                    <div className="content">
                      <div className="all-products-arrow">
                        <img src={BlueArrow} alt="Right" className="icon" />
                      </div>
                      <p className="label">View All Products</p>
                    </div>
                  </Link>
                )}
              </div>
            </section>
          )}
        {/* Image Banner */}

        {dashboardData &&
          dashboardData.banners &&
          dashboardData.banners.length !== 0 && (
            <Carousel className="dashboard-image-banner">
              {dashboardData.banners.map((banner, index) => {
                return (
                  <Carousel.Item key={index}>
                    <a href={banner.actionURL} target="_blank" rel="noreferrer">
                      <img
                        alt="Banner"
                        src={banner.imageURL}
                        className="banner-image"
                      />
                    </a>
                  </Carousel.Item>
                );
              })}
            </Carousel>
          )}

        {/* Sessions */}

        {dashboardData &&
          dashboardData.sessions &&
          dashboardData.sessions.length !== 0 && (
            <section className="sessions section">
              <SectionTitle icon={MySessionsIcon} title="Sessions" />

              <div className="products-container">
                <div className="product-list">
                  {dashboardData.sessions.map((mySession, index) => {
                    if (index < 3) {
                      return (
                        <ProductCard
                          key={index}
                          title={mySession.title}
                          thumbnail={mySession.thumbnail}
                          startDate={mySession.startDate}
                          type={
                            mySession.upcomingSession
                              ? 'upcomingSessions'
                              : 'mySessions'
                          }
                          onClick={() =>
                            history.push(
                              routeConstants.SESSIONDETAILS.replace(
                                ':sessionID',
                                mySession._id
                              )
                            )
                          }
                        />
                      );
                    } else {
                      return <Fragment key={index}></Fragment>;
                    }
                  })}
                </div>
                {dashboardData.sessions.length > 3 && (
                  <Link
                    to={routeConstants.ALLSESSIONS}
                    className="view-all-products"
                  >
                    <div className="content">
                      <div className="all-products-arrow">
                        <img src={BlueArrow} alt="Right" className="icon" />
                      </div>
                      <p className="label">View All Sessions</p>
                    </div>
                  </Link>
                )}
              </div>
            </section>
          )}

        {/* Advertisments Section */}

        {dashboardData &&
          dashboardData.advertisements &&
          dashboardData.advertisements.length !== 0 && (
            <section className="advert">
              <SectionTitle title="Advertisments" />
              <div className="advert-list">
                {dashboardData.advertisements.map((advert, index) => {
                  return (
                    <AdvertismentCard
                      key={index}
                      title={advert.title}
                      imageURL={advert.imageURL}
                      actionURL={advert.actionURL}
                    />
                  );
                })}
              </div>
            </section>
          )}
      </div>

      {showEnglishTagSelection && (
        <EnglishTagSelection
          subjectID={showEnglishTagSelection}
          route={routeConstants.SUBJECTDETAILS}
          hideModal={() => setShowEnglishTagSelection(false)}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  setLoader: (dataPayload) => setLoaderAction(dispatch, dataPayload),
  setProfile: (dataPayload) => setProfileAction(dispatch, dataPayload),
});

export default Private(connect(mapStateToProps, mapDispatchToProps)(Dashboard));
