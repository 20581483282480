import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import { showToast } from '../../../common/helpers';
import { setLoaderAction } from '../../../actions/siteActions';
import { routeConstants } from '../../../common/routeConstants';

import Private from '../../../hocs/Private';
import userService from '../../../services/userService';
import NavigationStatic from '../../../components/NavigationStatic';
import BreadcrumbStatic from '../../../components/BreadcrumbStatic';
import PlayIconWhite from '../../../assets/images/play-white-icon.svg';
import VideoPlaceholder from '../../../assets/images/black-solid-background.png';

import './styles.scss';

const VideoDetails = (props) => {
  const [startVideo, setStartVideo] = useState(false);
  const [videoDetails, setVideoDetails] = useState(null);

  useEffect(() => {
    getVideoDetails();
  }, []); // eslint-disable-line

  const getVideoDetails = () => {
    props.setLoader(true);
    userService
      .getVideoDetails({ videoID: props.match.params.videoID })
      .then((responseData) => {
        if (responseData) {
          props.setLoader(false);
          if (responseData.code === 200 && responseData.data) {
            setVideoDetails(responseData.data);
          }
        }
      })
      .catch((errorData) => {
        props.setLoader(false);
        showToast(errorData?.message);
      });
  };

  const getBreadcrumbs = () => {
    if (videoDetails) {
      const englishTag = videoDetails.chapterID?.englishTag;
      const subjectRoute = routeConstants.SUBJECTDETAILS.replace(
        ':subjectID',
        videoDetails.subjectID?._id
      );
      return [
        {
          name: 'Dashboard',
          route: window.location.pathname.includes('parent')
            ? routeConstants.PARENT_DASHBOARD
            : routeConstants.DASHBOARD,
        },
        {
          name: videoDetails.subjectID?.subject,
          route: englishTag ? `${subjectRoute}?et=${englishTag}` : subjectRoute,
        },
        {
          name: videoDetails.chapterID?.chapter,
          route: routeConstants.LESSONDETAILS.replace(
            ':lessonID',
            videoDetails.chapterID?._id
          ),
        },
        {
          name: videoDetails.title,
          route: routeConstants.VIDEODETAILS.replace(
            ':videoID',
            videoDetails._id
          ),
        },
      ];
    } else {
      return [
        {
          name: 'Dashboard',
          route: window.location.pathname.includes('parent')
            ? routeConstants.PARENT_DASHBOARD
            : routeConstants.DASHBOARD,
        },
      ];
    }
  };

  return (
    <div className="global-container content-container">
      <NavigationStatic />

      <section className="content-container-dynamic">
        <BreadcrumbStatic crumbs={getBreadcrumbs()} />

        {videoDetails && (
          <div className="videodetails">
            <div className="videodetails-header">
              <h5 className="videodetails-header-title">
                {videoDetails.title}
              </h5>
            </div>

            <div className="videodetails-content">
              {!startVideo ? (
                <div className="videodetails-content-thumbnail">
                  <img src={VideoPlaceholder} alt="Video" />
                  <div className="thumbnail-overlay">
                    <img
                      src={PlayIconWhite}
                      onClick={() => setStartVideo(true)}
                      className="play-icon"
                    />
                  </div>
                </div>
              ) : (
                <div className="videodetails-content-player">
                  {videoDetails.source === 'Youtube' ? (
                    <iframe
                      allowfullscreen
                      frameBorder="0"
                      className="player-video"
                      allow="autoplay; fullscreen; picture-in-picture"
                      src={`https://www.youtube.com/embed/${videoDetails.url}?autoplay=1`}
                    />
                  ) : videoDetails.source === 'Vimeo' ? (
                    <iframe
                      allowfullscreen
                      frameBorder="0"
                      className="player-video"
                      allow="autoplay; fullscreen; picture-in-picture"
                      src={`https://player.vimeo.com/video/${videoDetails.url}?autoplay=1&badge=0`}
                    />
                  ) : (
                    <video className="player-video" autoPlay controls>
                      <source src={videoDetails.url} />
                    </video>
                  )}
                </div>
              )}
            </div>
          </div>
        )}
      </section>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  setLoader: (dataPayload) => setLoaderAction(dispatch, dataPayload),
});

export default Private(
  connect(mapStateToProps, mapDispatchToProps)(VideoDetails)
);
