import { reduxConstants } from '../common/reduxConstants';

export const choosePackagesAction = (dispatch, dataPayload) => {
  dispatch({
    payload: dataPayload,
    type: reduxConstants.CHOOSEPACKAGES,
  });
};

export const setCurrentOrderAction = (dispatch, dataPayload) => {
  dispatch({
    payload: dataPayload,
    type: reduxConstants.SETCURRENTORDER,
  });
};

export const removePackageAction = (dispatch, dataPayload) => {
  dispatch({
    payload: dataPayload,
    type: reduxConstants.REMOVEPACKAGES,
  });
};

export const addProductsAction = (dispatch, dataPayload) => {
  dispatch({
    payload: dataPayload,
    type: reduxConstants.ADDPRODUCTS,
  });
};

export const removeProductsAction = (dispatch, dataPayload) => {
  dispatch({
    payload: dataPayload,
    type: reduxConstants.REMOVEPRODUCTS,
  });
};

export const resetPackagesAction = (dispatch, dataPayload) => {
  dispatch({
    payload: dataPayload,
    type: reduxConstants.RESETPACKAGES,
  });
};

export const resetProductsAction = (dispatch, dataPayload) => {
  dispatch({
    payload: dataPayload,
    type: reduxConstants.RESETPRODUCTS,
  });
};
