export const reduxConstants = {
  LOGIN: `AUTH/login`,
  LOGOUT: `AUTH/logout`,
  LOADER: `SITE/LOADER`,
  PROFILE: `USER/PROFILE`,
  SETCURRENTORDER: 'ORDER/SET',
  CHOOSEPACKAGES: 'ORDER/PACKAGES',
  REMOVEPACKAGES: 'ORDER/REMOVEPACKAGES',
  SETACTIVEQUESTION: 'RESOURCE/SETACTIVEQUESTION',
  SETQUESTIONNAIREBREADCRUMB: 'RESOURCE/SETQUESTIONNAIREBREADCRUMB',
  ADDPRODUCTS: 'ORDER/ADDPRODUCTS',
  REMOVEPRODUCTS: 'ORDER/REMOVEPRODUCTS',
  RESETPACKAGES: 'ORDER/RESETPACKAGES',
  RESETPRODUCTS: 'ORDER/RESETPRODUCTS',
  PARENTLOGIN: `AUTH/PARENTLOGIN`,
  PARENTOTP: `AUTH/PARENTOTP`,
};
