import { reduxConstants } from '../common/reduxConstants';
import {
  baseAxiosInstance,
  cdnAxiosInstance,
  parentAxiosInstance,
} from '../services/';

const authReducer = (
  state = {
    userDetails: {
      accessToken: null,
    },
    parentDetails: {
      accessToken: null,
    },
    parentOtpTs: null
  },
  action
) => {
  switch (action.type) {
    case reduxConstants.LOGIN:
      return {
        ...state,
        userDetails: action.payload,
      };

    case reduxConstants.LOGOUT:
      return {
        ...state,
        userDetails: {},
        parentDetails: {
          accessToken: null,
        },
      };

    case reduxConstants.PARENTLOGIN:
      return {
        ...state,
        parentDetails: {
          accessToken: action.payload,
        }
      };

      case reduxConstants.PARENTOTP:
      return {
        ...state,
        parentOtpTs: Date.now()
      };

    case 'persist/REHYDRATE':
      // Check if Payload Exists
      if (action.payload) {
        // Check if Payload Contains User Key
        if (action.payload.auth) {
          // Check if User Key Has Own Property Value
          if (action.payload.auth.hasOwnProperty('userDetails')) {
            baseAxiosInstance.defaults.headers.common[
              'Authorization'
            ] = `${action.payload.auth.userDetails.accessToken}`;

            cdnAxiosInstance.defaults.headers.common[
              'Authorization'
            ] = `${action.payload.auth.userDetails.accessToken}`;
          }

          if (action.payload.auth.hasOwnProperty('parentDetails')) {
            parentAxiosInstance.defaults.headers.common[
              'Authorization'
            ] = `${action.payload.auth.parentDetails.accessToken}`;
          }
        }
      }
      return state;

    default: {
      return state;
    }
  }
};

export default authReducer;
