import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setLoaderAction } from '../../../actions/siteActions';
import { routeConstants } from '../../../common/routeConstants';
import { removeProductsAction } from '../../../actions/orderActions';

import Private from '../../../hocs/Private';
import SectionTitle from '../../../components/SectionTitle';
import PrimaryButton from '../../../components/PrimaryButton';
import BreadcrumbStatic from '../../../components/BreadcrumbStatic';
import NavigationStatic from '../../../components/NavigationStatic';
import TrashIcon from '../../../assets/images/trash-icon.svg';
import EmptyCartIcon from '../../../assets/images/empty-cart.svg';

import './styles.scss';
import EmptyState from '../../../components/EmptyState';

const Cart = (props) => {
  const history = useHistory();

  // useEffect(() => {
  //   if (!props.products || !props.products.length) {
  //     return history.push(routeConstants.DASHBOARD);
  //   }
  // }, [props.products]); // eslint-disable-line

  const getSubtotal = () => {
    let subtotal = 0;
    if (props.products && props.products.length) {
      for (let product of props.products) {
        subtotal += parseInt(product.prices?.INR || 0);
      }
    }

    return subtotal;
  };



  return (
    <div className="global-container content-container">
      <NavigationStatic />
      <BreadcrumbStatic
        crumbs={[
          {
            name: 'My Cart',
            route: routeConstants.CART,
          },
        ]}
      />

      <div className="cart">
        <SectionTitle title="My Cart" />

    {
       props.products?.length !== 0 ?

        <div className="cart-content">
          <div className="cart-content-header">
            <h5 className="col-10">Item</h5>
            <h5 className="col-2">Price</h5>
          </div>

          {props.products &&
            props.products.length !== 0 &&
            props.products.map((product, index) => {
              return (
                <div key={index} className="cart-content-item">
                  <div className="productdata col-10">
                    <div className="productdata-left">
                      <div className="productdata-img-container">
                        <img src={product.thumbnail} alt="Product" />
                      </div>
                      <div
                        onClick={() => props.removeProducts(product._id)}
                        className="cart-content-remove-icon-container"
                      >
                        <img src={TrashIcon} alt="Remove" />
                        <h5>Remove</h5>
                      </div>
                    </div>
                    <div className="productdata-right">
                      <h5 className="productdata-right-title">
                        {product.title}
                      </h5>
                      <h5 className="productdata-right-subtitle">
                        {`${product.chapterID?.chapter} / ${product.chapterID?.subjectID?.subject}`}
                      </h5>
                    </div>
                  </div>
                  <div className="productprice col-2">
                    ₹ {product.prices?.INR}
                  </div>
                </div>
              );
            })}

          <div className="cart-content-separator"></div>
          <div className="cart-content-total">
            <h5 className="col-10 cart-content-subtotal">Subtotal:</h5>
            <h5 className="col-2 cart-subtotal-value">₹ {getSubtotal()}</h5>
          </div>

          <div className="cart-content-cta">
            <PrimaryButton
              name="Checkout"
              onClick={() => history.push(routeConstants.CARTCHECKOUT)}
            />
          </div>
        </div>

        :
        <EmptyState 
            img={EmptyCartIcon}
            label='OOPS! No items found in the cart'
        />
        }
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  products: state.order.products,
  productsIds: state.order.productsIds,
});

const mapDispatchToProps = (dispatch) => ({
  setLoader: (dataPayload) => setLoaderAction(dispatch, dataPayload),
  removeProducts: (dataPayload) => removeProductsAction(dispatch, dataPayload),
});

export default Private(connect(mapStateToProps, mapDispatchToProps)(Cart));
