import React from 'react';
import './styles.scss';

const UploadCard = (props) => {
  return (
    <div key={'1'} className="upload-card" onClick={props.onClick || null}>
      <img alt="Resource" src={props.thumbnail} className="upload-card-icon" />
      <div className="res-info">
        <h5 className="upload-card-title">{props.title}</h5>
        <p className="upload-card-description">{props.description}</p>
      </div>
    </div>
  );
};

export default UploadCard;
