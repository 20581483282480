import { Modal } from 'react-bootstrap';
import { QuestionSubTypes } from '../../../common/constants';
import { parseExplanation, parseAnswer } from './dataManager';

import PrimaryButton from '../../PrimaryButton';
import inCorrectAnswerSmiley from '../../../assets/images/oops-icon.svg';

import  './styles.scss';

const IncorrectAnswerDialog = (props) => {
  const { answerResponse, activeQuestion } = props;

  return (
    <div className='correct-answer'>
      <Modal
        centered
        show={true}
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        size={
          activeQuestion?.questionSubType === QuestionSubTypes.LAQAI
            ? 'lg'
            : 'md'
        }
      >
        <Modal.Body>
          <img className='correct-answer-img' alt="Incorrect" src={inCorrectAnswerSmiley}></img>
          <h4 className='correct-answer-title'>{props.modalData?.title}</h4>
          <h5 className='correct-answer-subtitle'>{props.modalData?.subTitle}</h5>
          {parseAnswer(answerResponse, activeQuestion)}
          {parseExplanation(answerResponse)}
        </Modal.Body>
        <Modal.Footer>
          <PrimaryButton onClick={props.onClick} name="Continue" />
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default IncorrectAnswerDialog;
