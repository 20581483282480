import thunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage';
import rootReducer from '../reducers/rootReducer';

import { createLogger } from 'redux-logger';
import { persistStore, persistReducer } from 'redux-persist';
import { createStore, applyMiddleware, compose } from 'redux';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['siteReducer', 'orderReducer'],
};

const reduxLogger = createLogger();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const presistedReducer = persistReducer(persistConfig, rootReducer);

const appMiddlewares =
  process.env.NODE_ENV !== 'production' ? [thunk, reduxLogger] : [thunk];

const reduxStore = createStore(
  presistedReducer,
  {},
  composeEnhancers(applyMiddleware(...appMiddlewares))
);

const persistedStore = persistStore(reduxStore);

export default { reduxStore, persistedStore };
