import get from 'lodash/get';
import {
  QuestionSubTypes,
  QuestionHeaderTypes,
} from '../../../common/constants';

export const parseExplanation = (answerResponse) => {
  return (
    <>
      {answerResponse?.explanation &&
        (answerResponse.explanation.primaryImage ||
          answerResponse.explanation.secondaryImage ||
          (answerResponse.explanation.texts &&
            answerResponse.explanation.texts.length !== 0) ||
          (answerResponse.explanation.subTexts &&
            answerResponse.explanation.subTexts.length !== 0)) && (
          <div className="modal-body-explanation">
            <h5>Answer Explanation: </h5>

            {answerResponse?.explanation?.primaryImage && (
              <img src={answerResponse.explanation.primaryImage} />
            )}

            {answerResponse?.explanation?.texts &&
              answerResponse.explanation.texts.length !== 0 &&
              answerResponse.explanation.texts.map((text, index) => (
                <p key={index}>{text}</p>
              ))}

            {answerResponse?.explanation?.secondaryImage && (
              <img src={answerResponse.explanation.secondaryImage} />
            )}

            {answerResponse?.explanation?.subTexts &&
              answerResponse?.explanation?.subTexts.length !== 0 &&
              answerResponse.explanation.subTexts.map((subText, index) => (
                <p key={index}>{subText}</p>
              ))}
          </div>
        )}
    </>
  );
};

export const parseAnswer = (answerResponse, activeQuestion) => {
  if (answerResponse && answerResponse.answer && activeQuestion) {
    return (
      <div className="modal-body-answer">
        <h5>Correct Answer: </h5>
        {getRenderingAnswer(answerResponse.answer, activeQuestion)}
      </div>
    );
  }
};

const getRenderingAnswer = (correctAnswer, activeQuestion) => {
  const answerType = get(activeQuestion, 'answerType');

  switch (activeQuestion.questionSubType) {
    case QuestionSubTypes.SAQ:
      return <p>{correctAnswer}</p>;

    case QuestionSubTypes.MCQ:
      if (answerType === QuestionHeaderTypes.IMAGE) {
        for (let ans of activeQuestion.answer) {
          if (ans.id === correctAnswer) {
            return <img src={ans.url} />;
          }
        }
      } else if (answerType === QuestionHeaderTypes.TEXT) {
        for (let ans of activeQuestion.answer) {
          if (ans.id === correctAnswer) {
            return <p>{ans.text}</p>;
          }
        }
      }

    case QuestionSubTypes.MCQM:
      if (answerType === QuestionHeaderTypes.IMAGE) {
        return activeQuestion.answer.map((ans, index) => {
          if (correctAnswer.includes(ans.id)) {
            return <img key={index} src={ans.url} />;
          } else return <div key={index}></div>;
        });
      } else if (answerType === QuestionHeaderTypes.TEXT) {
        return activeQuestion.answer.map((ans, index) => {
          if (correctAnswer.includes(ans.id)) {
            return <p key={index}>{ans.text}</p>;
          } else return <div key={index}></div>;
        });
      }

    case QuestionSubTypes.LQ:
      return (
        correctAnswer &&
        correctAnswer.length !== 0 &&
        correctAnswer.map((label, index) => {
          return <p key={index}>{`${label.label} - ${label.ans}`}</p>;
        })
      );

    case QuestionSubTypes.FITBT:
    case QuestionSubTypes.FITBS:
      let questionDataArr = [];
      const question = get(activeQuestion, 'question', []);

      if (question && question.length) {
        for (let ques of question) {
          if (ques.type === QuestionHeaderTypes.IMAGE) {
            questionDataArr.push(
              <img src={ques.value} className="fitbs-image" />
            );
          }

          if (ques.type === QuestionHeaderTypes.TEXT) {
            let questionText = ques.value;

            if (correctAnswer && correctAnswer.length) {
              for (let ca of correctAnswer) {
                if (questionText.includes(`##${ca.field}##`)) {
                  questionText = questionText.replace(
                    `##${ca.field}##`,
                    ca.ans
                  );
                }
              }
            }
            questionDataArr.push(<p className="fitbs-text">{questionText}</p>);
          }
        }
      }

      return <>{questionDataArr}</>;

    case QuestionSubTypes.LAQ:
    case QuestionSubTypes.LAQE:
      return (
        <>
          <p>
            <b>Your Answer:</b>
          </p>
          <p>{correctAnswer.userAnswer}</p>

          <p>
            <b>Ideal Answer:</b>
          </p>
          <pre>{correctAnswer.questionAnswer}</pre>
        </>
      );

    case QuestionSubTypes.TBLT:
    case QuestionSubTypes.TBLS:
      return (
        <>
          {correctAnswer &&
            correctAnswer.length !== 0 &&
            correctAnswer.map((tableRow, rowIndex) => (
              <div className="row" key={rowIndex}>
                {tableRow &&
                  tableRow.length !== 0 &&
                  tableRow.map((tableCol, colIndex) => {
                    if (rowIndex === 0) {
                      return (
                        <div
                          key={colIndex}
                          className="col tbls-item-col"
                          style={{
                            borderTopLeftRadius: '10px',
                            borderTopRightRadius: '10px',
                          }}
                        >
                          <div className="tbls-item-answer">
                            {tableCol.type === 'title' ? (
                              <b>{tableCol.text}</b>
                            ) : (
                              tableCol.text
                            )}
                          </div>
                        </div>
                      );
                    } else if (rowIndex === correctAnswer.length - 1) {
                      return (
                        <div
                          key={colIndex}
                          className="col tbls-item-col"
                          style={{
                            borderBottomLeftRadius: '10px',
                            borderBottomRightRadius: '10px',
                          }}
                        >
                          <div className="tbls-item-answer">
                            {tableCol.type === 'title' ? (
                              <b>{tableCol.text}</b>
                            ) : (
                              tableCol.text
                            )}
                          </div>
                        </div>
                      );
                    } else {
                      return (
                        <div key={colIndex} className="col tbls-item-col">
                          <div className="tbls-item-answer">
                            {tableCol.type === 'title' ? (
                              <b>{tableCol.text}</b>
                            ) : (
                              tableCol.text
                            )}
                          </div>
                        </div>
                      );
                    }
                  })}
              </div>
            ))}
        </>
      );

    case QuestionSubTypes.MTF:
      let mtfQuestionData = [];
      const mtfQuestion = get(activeQuestion, 'answer.ques') || [];
      if (mtfQuestion.length && correctAnswer && correctAnswer.length) {
        for (
          let questionIndex = 0, answerIndex = 0;
          questionIndex < mtfQuestion.length;
          questionIndex++, answerIndex++
        ) {
          mtfQuestionData.push(
            <div key={questionIndex} className="row">
              <div
                className="col mtf-item-col"
                style={
                  questionIndex === 0
                    ? {
                        borderTopLeftRadius: '10px',
                        borderTopRightRadius: '10px',
                      }
                    : questionIndex === mtfQuestion.length - 1
                    ? {
                        borderBottomLeftRadius: '10px',
                        borderBottomRightRadius: '10px',
                      }
                    : {}
                }
              >
                <div className="mtf-item-answer">
                  {mtfQuestion[questionIndex].type ===
                  QuestionHeaderTypes.TEXT ? (
                    mtfQuestion[questionIndex].text
                  ) : (
                    <img
                      alt="Match The Following"
                      src={mtfQuestion[questionIndex].url}
                    />
                  )}
                </div>
              </div>
              <div
                className="col mtf-item-col"
                style={
                  questionIndex === 0
                    ? {
                        borderTopLeftRadius: '10px',
                        borderTopRightRadius: '10px',
                      }
                    : questionIndex === mtfQuestion.length - 1
                    ? {
                        borderBottomLeftRadius: '10px',
                        borderBottomRightRadius: '10px',
                      }
                    : {}
                }
              >
                <div className="mtf-item-answer">
                  {correctAnswer[questionIndex].type ===
                  QuestionHeaderTypes.TEXT ? (
                    correctAnswer[questionIndex].text
                  ) : (
                    <img
                      alt="Match The Following"
                      src={correctAnswer[questionIndex].url}
                    />
                  )}
                </div>
              </div>
            </div>
          );
        }
      }

      return mtfQuestionData;

    case QuestionSubTypes.LAQWL:
      return (
        <>
          <p>
            <b>Your Answer:</b>
          </p>
          <pre>{correctAnswer.userAnswer}</pre>
        </>
      );

    case QuestionSubTypes.LAQAI:
      return (
        <>
          <p>
            <b>Your Score:</b>
          </p>
          <p>{correctAnswer.score}%</p>
          <div className="row mt">
            <div className="col-6">
              <p>
                <b>Your Answer:</b>
              </p>
              <pre>{correctAnswer.userAnswer}</pre>
            </div>
            <div className="col-6">
              <p>
                <b>Ideal Answer:</b>
              </p>
              <pre>{correctAnswer.questionAnswer?.ans || 'N/A'}</pre>
            </div>
          </div>
        </>
      );

    case QuestionSubTypes.RQT:
      return (
        <>
          <p>
            <b>Time taken by you for reading:</b>
          </p>
          <p>{correctAnswer.userAnswer} minute(s)</p>

          <p>
            <b>Ideal time taken for reading:</b>
          </p>
          <p>{correctAnswer.questionAnswer} minute(s)</p>
        </>
      );

    default:
      break;
  }
};
