import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { PaymentGateways, RAZORPAY_SDK_URL } from '../../common/constants';

import CashFreeLogo from '../../assets/images/cashfree-logo.png';
import RazorPay from '../../assets/images/razorpay.png';

import PrimaryButton from '../PrimaryButton';

import styles from './styles.scss';

const PaymentGatewayModal = (props) => {
  const [selectedGateway, setSelectedGateway] = useState(null);

  const onClick = () => {
    if (selectedGateway === PaymentGateways.RAZORPAY) {
      props.setLoader(true);
      const script = document.createElement('script');
      script.src = RAZORPAY_SDK_URL;
      script.async = true;
      script.onload = () => {
        props.setLoader(false);
        props.onClick(selectedGateway);
      };
      document.head.appendChild(script);
    } else {
      props.onClick(selectedGateway);
    }
  };

  return (
    <div style={styles}>
      <Modal
        centered
        size='md'
        show={true}
        onHide={props.onHide}
        aria-labelledby='contained-modal-title-vcenter'
        className='payment-gateway-modal'
      >
        <Modal.Body>
          <h4 className='select-payment-title'>Choose Payment Gateway</h4>
          <div className='gateway-container'>
            <div className='gateway-container-options'>
              <div
                onClick={() => setSelectedGateway(PaymentGateways.CASHFREE)}
                className={`gateway-item ${
                  selectedGateway === PaymentGateways.CASHFREE &&
                  'gateway-item--selected'
                }`}
              >
                <img
                  src={CashFreeLogo}
                  alt='cashfree'
                  className='gateway-item-img'
                />
              </div>

              <div
                onClick={() => setSelectedGateway(PaymentGateways.RAZORPAY)}
                className={`gateway-item ${
                  selectedGateway === PaymentGateways.RAZORPAY &&
                  'gateway-item--selected'
                }`}
              >
                <img
                  src={RazorPay}
                  alt='razorpay'
                  className='gateway-item-img'
                />
              </div>
            </div>

            <div className='gateway-container-description'>
              <h5 className='description-title'>Offer:</h5>
              <p className='description-subtitle'>
                With Razorpay, get 10% discount using ICICI Bank Credit & Debit
                Cards on minimum purchase of ₹1500/- .
              </p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <PrimaryButton
            onClick={onClick}
            name='Proceed'
            className='primary-button proceed-pay-btn'
          />
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default PaymentGatewayModal;
