import { useState, useEffect } from 'react';

import get from 'lodash/get';
import set from 'lodash/set';
import isEqual from 'lodash/isEqual';
import QuestionHeader from '../QuestionHeader';
import PrimaryButton from '../../PrimaryButton';
import WrongAnswerToast from '../../WrongAnswerToast';
import QuestionSuggestions from '../QuestionSuggestions';

import styles from './styles.scss';
import { QuestionHeaderTypes } from '../../../common/constants';

const MTFQuestion = (props) => {
  const [correctAnswer, setCorrectAnswer] = useState([]);
  const [answerResponse, setAnswerResponse] = useState(null);

  const setDefaultCorrectAnswer = () => {
    let correctAnswerCopy = [];
    const answer = get(props.activeQuestion, 'correctAnswer');

    if (answer && answer.length) {
      for (let answerItem of answer) {
        correctAnswerCopy.push({ type: answerItem.type, value: '', id: '' });
      }

      setCorrectAnswer(correctAnswerCopy);
    }
  };

  useEffect(() => {
    if (!isEqual(answerResponse, props.answerResponse)) {
      setAnswerResponse(props.answerResponse);
    }
  }, [props.answerResponse]);

  useEffect(() => {
    setDefaultCorrectAnswer();
  }, [props.activeQuestion]);

  const handleAnswerSubmit = () => {
    let answer = [];
    if (correctAnswer && correctAnswer.length) {
      for (let answerItem of correctAnswer) {
        answer.push(answerItem.id);
      }
    }

    props.answerSubmitHandler(answer);
  };

  const onDragOver = (event) => {
    event.preventDefault();
  };

  const onDrop = (event, index) => {
    event.preventDefault();
    let correctAnswerCopy = Object.assign([], correctAnswer);
    set(
      correctAnswerCopy,
      `${index}`,
      JSON.parse(event.dataTransfer.getData('text/plain'))
    );
    setCorrectAnswer(correctAnswerCopy);
  };

  const checkSubmitEnabled = () => {
    let isSubmitDisabled = true;
    let isValid = true;
    if (correctAnswer.length) {
      for (let row of correctAnswer) {
        if (!row || !row.id || row.id === '') {
          isValid = false;
          isSubmitDisabled = true;
          break;
        }

        if (isValid) {
          isSubmitDisabled = false;
        }
      }
    }

    return isSubmitDisabled;
  };

  const getRenderingQuestion = () => {
    let questionData = [];
    const question = get(props.activeQuestion, 'answer.ques') || [];
    if (question.length && correctAnswer.length) {
      for (
        let questionIndex = 0, answerIndex = 0;
        questionIndex < question.length;
        questionIndex++, answerIndex++
      ) {
        questionData.push(
          <div key={questionIndex} className="row">
            <div
              className="col mtf-item-col"
              style={
                questionIndex === 0
                  ? {
                      borderTopLeftRadius: '10px',
                      borderTopRightRadius: '10px',
                    }
                  : questionIndex === question.length - 1
                  ? {
                      borderBottomLeftRadius: '10px',
                      borderBottomRightRadius: '10px',
                    }
                  : {}
              }
            >
              <div className="mtf-item-answer">
                {question[questionIndex].type === QuestionHeaderTypes.TEXT ? (
                  question[questionIndex].text
                ) : (
                  <img
                    alt="Match The Following"
                    src={question[questionIndex].url}
                  />
                )}
              </div>
            </div>
            <div
              className="col mtf-item-col"
              style={
                questionIndex === 0
                  ? {
                      borderTopLeftRadius: '10px',
                      borderTopRightRadius: '10px',
                    }
                  : questionIndex === question.length - 1
                  ? {
                      borderBottomLeftRadius: '10px',
                      borderBottomRightRadius: '10px',
                    }
                  : {}
              }
            >
              <div
                className="mtf-item-answer"
                onDragOver={onDragOver}
                onDrop={(event) => onDrop(event, answerIndex)}
                className={`mtf-item-answer ${
                  (!correctAnswer ||
                    !correctAnswer[answerIndex] ||
                    (!correctAnswer[answerIndex].text &&
                      !correctAnswer[answerIndex].url)) &&
                  'mtf-item-answer--faded'
                }`}
              >
                {correctAnswer &&
                  (correctAnswer[answerIndex].type ===
                  QuestionHeaderTypes.TEXT ? (
                    correctAnswer[answerIndex].text || 'Drop Here...'
                  ) : correctAnswer[answerIndex].url ? (
                    <img
                      src={correctAnswer[answerIndex].url}
                      alt="Match The Following"
                    />
                  ) : (
                    'Drop Here...'
                  ))}
              </div>
            </div>
          </div>
        );
      }
    }

    return questionData;
  };

  return (
    <div style={styles} className="mtf-container">
      <div className="content-scroller">
        {props.activeQuestion?.question?.length !== 0 && (
          <div className="mtf-container-content">
            <QuestionHeader question={props.activeQuestion.question} />

            <div className="mtf-container-item">{getRenderingQuestion()}</div>

            {props.activeQuestion?.answer?.ans?.length !== 0 && (
              <QuestionSuggestions
                onDragOver={onDragOver}
                activeQuestion={props.activeQuestion}
              />
            )}
          </div>
        )}
      </div>

      <div className="mtf-container-footer">
        {answerResponse && !answerResponse.correct && <WrongAnswerToast />}

        <PrimaryButton
          name="Submit"
          showIcon={true}
          onClick={handleAnswerSubmit}
          disabled={checkSubmitEnabled()}
        />
      </div>
    </div>
  );
};

export default MTFQuestion;
