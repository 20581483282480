import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import { showToast } from '../../../common/helpers';
import { setLoaderAction } from '../../../actions/siteActions';
import { setActiveQuestionAction } from '../../../actions/resourceActions';

import uuid from 'uuid/dist/v4';
import Lightbox from '../../../components/Lightbox';
import HintIcon from '../../../assets/images/hint-icon.svg';
import resourceService from '../../../services/resourceService';
import NavigationStatic from '../../../components/NavigationStatic';
import FreebieActiveQuestion from '../../../components/Questions/FreebieActiveQuestion';

import './styles.scss';

const FreebieQuestionnaire = (props) => {
  const [redirectUrl, setRedirectUrl] = useState(window.location.origin);
  const [deviceIdentifier, setDeviceIdentifier] = useState(`${Date.now()}`);
  const [showHint, setShowHint] = useState(false);
  const [showLightbox, setShowLightbox] = useState(null);

  useEffect(() => {
    getFreebieWorksheet();
    setDeviceIdentifier(uuid());
  }, []); // eslint-disable-line

  const getFreebieWorksheet = () => {
    props.setLoader(true);
    resourceService
      .startFreebieWorksheet({
        worksheetID: props.match.params.worksheetID,
      })
      .then((responseData) => {
        if (responseData) {
          props.setLoader(false);
          if (responseData.code === 200 && responseData.data) {
            props.setActiveQuestion(responseData.data);
            if (responseData.data.subject && responseData.data.class) {
              setRedirectUrl(
                `${
                  process.env.REACT_APP_STATIC_APP_URL
                }/${responseData.data.subject?.toLowerCase()}-worksheets-for-class-${
                  responseData.data.class
                }`
              );
            }
          }
        }
      })
      .catch((errorData) => {
        props.setLoader(false);
        showToast(errorData?.message);
      });
  };

  const resourceCompleted = () => {
    window.location.href = redirectUrl;
  };

  return (
    <div className="global-container content-container questions-container">
      <NavigationStatic hidePrivateData={true} />

      {props.activeQuestion && (
        <section className="content-container-dynamic">
          <FreebieActiveQuestion
            setLoader={props.setLoader}
            sessionID={deviceIdentifier}
            activeQuestion={props.activeQuestion}
            resourceCompleted={resourceCompleted}
            setActiveQuestion={props.setActiveQuestion}
          />

          <div className="content-options">
            <div className="hint d-flex align-items-center justify-content-center flex-column relative">
              <span className="hint-title d-block">Hint</span>

              <img
                src={HintIcon}
                className="questionhint-icon"
                alt="Hint"
                onClick={() => setShowHint(!showHint)}
              />

              {showHint && (
                <div className="questionhint-overlay">
                  <h5 className="questionhint-title">Hint</h5>
                  <p className="questionhint-content">
                    {props.activeQuestion?.questionHint &&
                    props.activeQuestion?.questionHint?.length !== 0 ? (
                      props.activeQuestion?.questionHint.map((hint, index) => {
                        return typeof hint === 'string' ? (
                          <p key={index} className="questionhint-content-item">
                            {hint}
                          </p>
                        ) : hint.type === 'text' ? (
                          <p key={index} className="questionhint-content-item">
                            {hint.value}
                          </p>
                        ) : (
                          <img
                            alt="Hint"
                            key={index}
                            src={hint.value}
                            className="questionhint-content-image"
                            onClick={() => setShowLightbox(hint.value)}
                          />
                        );
                      })
                    ) : (
                      <p className="questionhint-content-item">
                        Sorry! No hint available for this question.
                      </p>
                    )}
                  </p>
                  <p className="questionhint-info">
                    Q. Code: {props.activeQuestion?.code}
                  </p>
                  <p className="questionhint-info">
                    R. Code: {props.activeQuestion?.ownerID}
                  </p>
                </div>
              )}
            </div>

            {props.activeQuestion && (
              <div className="progress-track">
                <span className="progress-track-title d-block">Attempted</span>

                <div className="progress-track-bar">
                  <div
                    className="progress-track-filler"
                    style={{
                      height: `${
                        (1 -
                          props.activeQuestion.currentQuestion /
                            props.activeQuestion.totalQuestions) *
                        100
                      }%`,
                    }}
                  />
                </div>

                <span className="progress-track-count d-block">
                  <span className="progress-track-completed">{`${props.activeQuestion.currentQuestion}`}</span>
                  /
                  <span className="progress-track-remaining">{`${props.activeQuestion.totalQuestions}`}</span>
                </span>
              </div>
            )}
          </div>
        </section>
      )}

      {showLightbox && (
        <Lightbox image={showLightbox} setShowLightbox={setShowLightbox} />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  activeQuestion: state.resource.activeQuestion,
  questionnaireBreadcrumbs: state.resource.questionnaireBreadcrumbs,
});

const mapDispatchToProps = (dispatch) => ({
  setActiveQuestion: (dataPayload) =>
    setActiveQuestionAction(dispatch, dataPayload),
  setLoader: (dataPayload) => setLoaderAction(dispatch, dataPayload),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FreebieQuestionnaire);
