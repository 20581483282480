import uuid from 'uuid/dist/v4';
import { toast } from 'react-toastify';

export const getDeviceIdentifier = () => {
  let deviceIdentifier = document.cookie.replace(
    /(?:(?:^|.*;\s*)deviceIdentifier\s*=\s*([^;]*).*$)|^.*$/,
    '$1'
  );

  if (!deviceIdentifier) {
    document.cookie = `deviceIdentifier=${uuid()}`;
    return getDeviceIdentifier();
  }

  return deviceIdentifier;
};

export const showToast = (content) => {
  if (content) {
    toast(content);
  }
};

export const shortenText = (text = '', charactersCount = 0) => {
  if (text && typeof text === 'string' && text.length > charactersCount) {
    return `${text.substr(0, charactersCount)}...`;
  } else {
    return text;
  }
};
