import { connect } from 'react-redux';
import { Fragment, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { logoutAction } from '../../actions/authActions';
import { routeConstants } from '../../common/routeConstants';

import CartIcon from '../../assets/images/cart-icon.svg';

import './styles.scss';

const BreadcrumbStatic = (props) => {
  const history = useHistory();
  const [showMenu, setShowMenu] = useState(false);

  const logoutHandler = () => {
    props.logout();
  };

  return (
    <section className="global-breadcrumb">
      <div className="global-breadcrumb-left">
        {props.crumbs &&
          props.crumbs.length !== 0 &&
          props.crumbs.map((crumb, index) => {
            return (
              <Fragment key={index}>
                {index !== 0 && ' > '}
                {index === props.crumbs.length - 1 ? (
                  <p className="global-breadcrumb-text">{crumb.name}</p>
                ) : (
                  <Link to={crumb.route} className="global-breadcrumb-link">
                    {crumb.name}
                  </Link>
                )}
              </Fragment>
            );
          })}
      </div>

      <div className="global-breadcrumb-right">
        <button
          type="button"
          className="global-breadcrumb-option"
          onClick={() =>
            // (props?.productsIds || []).length
              // ? 
              history.push(routeConstants.CART)
              // : {}
          }
        >
          <img src={CartIcon} className="cart-icon" alt="Cart" />

          <span className="cart-count d-flex align-items-center justify-content-center">
            {(props?.productsIds || []).length}
          </span>
        </button>

        <div className="hamburger-menu">
          <button
            type="button"
            className="global-breadcrumb-option"
            onClick={() => setShowMenu(!showMenu)}
          >
            <div className="hamburger-icon">
              <span className="hamburger-icon-line d-block"></span>
              <span className="hamburger-icon-line d-block"></span>
              <span className="hamburger-icon-line d-block"></span>
            </div>
          </button>
          {showMenu && (
            <div className="hamburger-menu-items">
              <Link to={routeConstants.SUPPORT}>Support</Link>
              <Link to={'#'} onClick={() => logoutHandler()}>
                Logout
              </Link>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => ({
  productsIds: state.order.productsIds,
});

const mapDispatchToProps = (dispatch) => ({
  logout: (dataPayload) => logoutAction(dispatch, dataPayload),
});

export default connect(mapStateToProps, mapDispatchToProps)(BreadcrumbStatic);
