import React from 'react';
import { Link } from 'react-router-dom';
import './styles.scss';

const SubjectIcon = (props) => {
	return (
		<Link to="#" onClick={props.onClick} className={props.className ? `subject-icon ${props.className}` : 'subject-icon'}>
			<div className='wrapper'>
				<div className='icon-wrap'>
					<img src={props.icon} alt='label' className='icon' />
				</div>
				<p className='label'>{props.label}</p>
			</div>
		</Link>
	);
};

export default SubjectIcon;
