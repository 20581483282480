import { useState, useEffect } from 'react';
import { QuestionHeaderTypes } from '../../../common/constants';

import get from 'lodash/get';
import set from 'lodash/set';
import isEqual from 'lodash/isEqual';
import Lightbox from '../../Lightbox';
import PrimaryButton from '../../PrimaryButton';
import WrongAnswerToast from '../../WrongAnswerToast';
import QuestionSuggestions from '../QuestionSuggestions';

import styles from './styles.scss';

const FITBTQuestion = (props) => {
  const [correctAnswer, setCorrectAnswer] = useState([]);
  const [showLightbox, setShowLightbox] = useState(null);
  const [questionDataArr, setQuestionDataArr] = useState([]);
  const [answerResponse, setAnswerResponse] = useState(null);

  const getAnswerData = () => {
    const answer = get(props.activeQuestion, 'answer');
    setCorrectAnswer(answer);
  };

  const handleAnswerUpdate = (fieldName, fieldValue) => {
    let correctAnswerCopy = Object.assign([], correctAnswer);
    set(correctAnswerCopy, fieldName, fieldValue);
    setCorrectAnswer(correctAnswerCopy);
  };

  const getRenderingQuestion = () => {
    let questionDataArrCopy = [];
    const question = get(props, 'activeQuestion.question', []);

    if (question && question.length) {
      for (let ques of question) {
        if (ques.type === QuestionHeaderTypes.IMAGE) {
          questionDataArrCopy.push(
            <img
              alt="Question"
              src={ques.value}
              className="fitbt-image"
              onClick={() => setShowLightbox(ques.value)}
            />
          );
        }

        if (ques.type === QuestionHeaderTypes.TEXT) {
          let mainArr = [];
          let textArr = ques.value.match(/(##FIELD)([0-9]+)(##)/g);

          if (textArr && textArr.length) {
            mainArr = ques.value.split(/##/g);
          } else {
            questionDataArrCopy.push(
              <p className="fitbt-text fib-heading">{ques.value}</p>
            );
            continue;
          }

          if (mainArr.length) {
            let textData = [];
            for (let textIndex = 0; textIndex < mainArr.length; textIndex++) {
              if (mainArr[textIndex]) {
                if (/(FIELD)([0-9]+)/g.test(mainArr[textIndex])) {
                  let fieldIndex = 0;
                  if (correctAnswer.length) {
                    for (let i = 0; i < correctAnswer.length; i++) {
                      if (correctAnswer[i].field === mainArr[textIndex]) {
                        fieldIndex = i;
                        break;
                      }
                    }
                  }

                  textData.push(
                    <input
                      type="text"
                      key={textIndex}
                      className={`fitbt-item`}
                      placeholder="Type answer"
                      onChange={(event) =>
                        handleAnswerUpdate(
                          `[${fieldIndex}].ans`,
                          event.currentTarget.value
                        )
                      }
                      value={correctAnswer[fieldIndex]?.ans || ''}
                    />
                  );
                } else {
                  textData.push(
                    <p key={textIndex} className="fitbt-text">
                      {mainArr[textIndex]}
                    </p>
                  );
                }
              }
            }

            questionDataArrCopy.push(
              <div className="fitbt-field">{textData}</div>
            );
          }
        }
      }
    }

    setQuestionDataArr(questionDataArrCopy);
  };

  useEffect(() => {
    if (!isEqual(answerResponse, props.answerResponse)) {
      setAnswerResponse(props.answerResponse);
    }
  }, [props.answerResponse]); // eslint-disable-line

  useEffect(() => {
    getAnswerData();
  }, [props.activeQuestion]); // eslint-disable-line

  useEffect(() => {
    getRenderingQuestion();
  }, [correctAnswer]); // eslint-disable-line

  const handleAnswerSubmit = () => {
    props.answerSubmitHandler(correctAnswer);
  };

  const checkSubmitEnabled = () => {
    let isSubmitDisabled = true;
    let isValid = true;
    if (correctAnswer && correctAnswer.length) {
      for (let ans of correctAnswer) {
        if (!ans.ans || ans.ans === '') {
          isValid = false;
          isSubmitDisabled = true;
          break;
        }

        if (isValid) {
          isSubmitDisabled = false;
        }
      }
    }

    return isSubmitDisabled;
  };

  return (
    <div style={styles} className="fitbt-container">
      <div className="content-scroller">
        {questionDataArr.length !== 0 && (
          <div className="fitbt-container-content">
            {questionDataArr}

            {props.activeQuestion?.questionSuggestions?.length !== 0 && (
              <QuestionSuggestions activeQuestion={props.activeQuestion} />
            )}
          </div>
        )}
      </div>

      <div className="fitbt-container-footer">
        {answerResponse && !answerResponse.correct && <WrongAnswerToast />}

        <PrimaryButton
          name="Submit"
          showIcon={true}
          onClick={handleAnswerSubmit}
          disabled={checkSubmitEnabled()}
        />
      </div>

      {showLightbox && (
        <Lightbox image={showLightbox} setShowLightbox={setShowLightbox} />
      )}
    </div>
  );
};

export default FITBTQuestion;
