import { useState, useEffect } from 'react';

import isEqual from 'lodash/isEqual';
import QuestionHeader from '../QuestionHeader';
import InputTextArea from '../../InputTextArea';
import PrimaryButton from '../../PrimaryButton';
import WrongAnswerToast from '../../WrongAnswerToast';
import QuestionSuggestions from '../QuestionSuggestions';

import styles from './styles.scss';

const LAQQuestion = (props) => {
  const [correctAnswer, setCorrectAnswer] = useState('');
  const [answerResponse, setAnswerResponse] = useState(null);

  useEffect(() => {
    if (!isEqual(answerResponse, props.answerResponse)) {
      setAnswerResponse(props.answerResponse);
    }
  }, [props.answerResponse]);

  useEffect(() => {
    setCorrectAnswer('');
  }, [props.activeQuestion]);

  const handleAnswerSubmit = () => {
    props.answerSubmitHandler(correctAnswer);
  };

  return (
    <div style={styles} className="laq-container">
      <div className="content-scroller">
        {props.activeQuestion?.question?.length !== 0 && (
          <div className="laq-container-content">
            <QuestionHeader question={props.activeQuestion.question} />

            <div className="laq-container-item">
              <InputTextArea
                rows={4}
                value={correctAnswer}
                placeholder="Type answer"
                onChange={(event) =>
                  setCorrectAnswer(event.currentTarget.value)
                }
              />
            </div>
            {props.activeQuestion?.questionSuggestions?.length !== 0 && (
              <QuestionSuggestions activeQuestion={props.activeQuestion} />
            )}
          </div>
        )}
      </div>

      <div className="laq-container-footer">
        {answerResponse && !answerResponse.correct && <WrongAnswerToast />}

        <PrimaryButton
          name="Submit"
          showIcon={true}
          disabled={!correctAnswer}
          onClick={handleAnswerSubmit}
        />
      </div>
    </div>
  );
};

export default LAQQuestion;
