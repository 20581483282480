import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { showToast } from '../../../common/helpers';
import { PaymentGateways } from '../../../common/constants';
import { setLoaderAction } from '../../../actions/siteActions';
import { routeConstants } from '../../../common/routeConstants';
import {
  removeProductsAction,
  setCurrentOrderAction,
} from '../../../actions/orderActions';

import get from 'lodash/get';
import Private from '../../../hocs/Private';
import SectionTitle from '../../../components/SectionTitle';
import PrimaryButton from '../../../components/PrimaryButton';
import resourceService from '../../../services/resourceService';
import BreadcrumbStatic from '../../../components/BreadcrumbStatic';
import NavigationStatic from '../../../components/NavigationStatic';
import PaymentGatewayModal from '../../../components/PaymentGatewayModal';

import './styles.scss';

const CartCheckout = (props) => {
  const history = useHistory();
  const [showPaymentGateways, setShowPaymentGateways] = useState(false);
  const [breakdown, setBreakdown] = useState({
    gstAmount: 0,
    totalAmount: 0,
    initialAmount: 0,
  });

  useEffect(() => {
    if (!props.products || !props.products.length) {
      return history.push(routeConstants.DASHBOARD);
    } else {
      let initialAmount = 0,
        totalAmount = 0,
        gstAmount = 0;

      for (let product of props.products) {
        initialAmount += parseInt(product.prices.INR);
      }

      totalAmount = initialAmount;
      gstAmount = 0.18 * totalAmount;
      initialAmount -= gstAmount;

      setBreakdown({
        gstAmount: getValidMinimum(gstAmount),
        totalAmount: getValidMinimum(totalAmount),
        initialAmount,
      });
    }
  }, [props.products]); // eslint-disable-line

  const getValidMinimum = (number) => {
    return number < 0 ? 0 : number;
  };

  const addUserProduct = (gateway) => {
    props.setLoader(true);
    let dataPayload = {
      gateway,
      currency: 'INR',
      productID: props.productsIds,
    };

    resourceService
      .addUserProduct(dataPayload)
      .then((responseData) => {
        props.setLoader(false);

        if (responseData) {
          if (responseData.code === 200) {
            props.setCurrentOrder({
              currentOrderType: 'PRODUCT',
              currentOrder: responseData.data,
            });

            const orderAmount = get(responseData.data, 'order.orderAmount');
            if (orderAmount !== 0) {
              if (gateway === PaymentGateways.RAZORPAY) {
                triggerRazorpayCheckout(responseData.data);
              } else {
                window.location.href = routeConstants.PRODUCTPAYMENT;
              }
            } else {
              verifyOrder(responseData.data);
            }
          }
        }
      })
      .catch((errorData) => {
        props.setLoader(false);
        showToast(errorData?.message);
      });
  };

  const triggerRazorpayCheckout = (order) => {
    const orderDetails = order?.order;
    const razorpayInstance = new window.Razorpay({
      theme: {
        color: '#BBDEF5',
      },
      name: 'Wise Owl Learning',
      handler: (response) => {
        if (
          response &&
          response.razorpay_payment_id &&
          response.razorpay_signature
        ) {
          verifyOrder(order, response.razorpay_payment_id);
        }
      },
      key: get(orderDetails, 'appId'),
      prefill: {
        name: get(orderDetails, 'userName'),
        email: get(orderDetails, 'userEmail'),
        contact: get(orderDetails, 'userNumber'),
      },
      order_id: get(orderDetails, 'createdOrder.id'),
      amount: get(orderDetails, 'createdOrder.amount'),
      currency: get(orderDetails, 'createdOrder.currency'),
      description: get(orderDetails, 'createdOrder.receipt'),
      modal: {
        ondismiss: () => failOrder(order),
      },
    });

    razorpayInstance.open();
    setShowPaymentGateways(false);
  };

  const verifyOrder = (orderDetails, paymentGatewayID) => {
    props.setLoader(true);
    resourceService
      .verifyProductPayment({
        userProductID: orderDetails._id,
        paymentGatewayID,
      })
      .then((responseData) => {
        props.setLoader(false);
        if (responseData) {
          if (responseData.code === 200) {
            history.push(routeConstants.PRODUCTCHECKOUTSUCCESS);
          }
        }
      })
      .catch((errorData) => {
        props.setLoader(false);
        showToast(errorData?.message);
      });
  };

  const failOrder = (orderDetails) => {
    props.setLoader(true);
    resourceService
      .failProductPayment({ userProductID: orderDetails._id })
      .then((responseData) => {
        props.setLoader(false);
        if (responseData) {
          if (responseData.code === 200) {
            history.push(routeConstants.PRODUCTCHECKOUTFAILURE);
          }
        }
      })
      .catch((errorData) => {
        props.setLoader(false);
        showToast(errorData?.message);
      });
  };

  return (
    <div className="global-container content-container">
      <NavigationStatic />
      <BreadcrumbStatic
        crumbs={[
          {
            name: 'My Cart',
            route: routeConstants.CART,
          },
          {
            name: 'Order Summary',
            route: routeConstants.CARTCHECKOUT,
          },
        ]}
      />

      <div className="cartcheckout">
        <SectionTitle title="Order Summary" />

        <div className="cartcheckout-content">
          <div className="cartcheckout-content-header">
            <h5 className="col-10">Item</h5>
            <h5 className="col-2">Price</h5>
          </div>

          {props.products &&
            props.products.length !== 0 &&
            props.products.map((product, index) => {
              return (
                <div key={index} className="cartcheckout-content-item">
                  <div className="productitem col-10">
                    <div className="productitem-body">
                      <h5 className="productitem-body-title">
                        {product.title}
                      </h5>
                      <h5 className="productitem-body-subtitle">
                        {`${product.chapterID?.chapter} / ${product.chapterID?.subjectID?.subject}`}
                      </h5>
                    </div>
                  </div>
                  <div className="productprice col-2">
                    ₹ {product.prices?.INR}
                  </div>
                </div>
              );
            })}

          <div className="cartcheckout-content-separator"></div>
          <div className="cartcheckout-content-breakdown">
            <div className="col-9 breakdown-fields">
              <h5>Amount:</h5>
              <h5>GST (18%):</h5>
            </div>
            <div className="col-1"></div>
            <div className="col-2 breakdown-values">
              <h5>₹ {breakdown.initialAmount.toFixed(2)}</h5>
              <h5>₹ {breakdown.gstAmount.toFixed(2)}</h5>
            </div>
          </div>

          <div className="cartcheckout-content-separator separator-two"></div>

          <div className="cartcheckout-content-total">
            <h5 className="col-9">Total:</h5>
            <div className="col-1"></div>
            <h5 className="col-2">₹ {breakdown.totalAmount}</h5>
          </div>

          <div className="cartcheckout-content-cta">
            <PrimaryButton
              name="Proceed & Pay"
              onClick={() => setShowPaymentGateways(true)}
            />
          </div>
        </div>
      </div>

      {showPaymentGateways && (
        <PaymentGatewayModal
          onClick={addUserProduct}
          setLoader={props.setLoader}
          onHide={() => setShowPaymentGateways(false)}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  products: state.order.products,
  productsIds: state.order.productsIds,
});

const mapDispatchToProps = (dispatch) => ({
  setLoader: (dataPayload) => setLoaderAction(dispatch, dataPayload),
  removeProducts: (dataPayload) => removeProductsAction(dispatch, dataPayload),
  setCurrentOrder: (dataPayload) =>
    setCurrentOrderAction(dispatch, dataPayload),
});

export default Private(
  connect(mapStateToProps, mapDispatchToProps)(CartCheckout)
);
