import React, { useState } from 'react';
import { QuestionHeaderTypes } from '../../../common/constants';

import Lightbox from '../../Lightbox';
import styles from './styles.scss';

const QuestionHeader = (props) => {
  const [showLightbox, setShowLightbox] = useState(null);

  return (
    <React.Fragment>
      <div className="question-header" style={styles}>
        {props.question.map((questionItem, index) => {
          if (questionItem.type === QuestionHeaderTypes.IMAGE) {
            return (
              <img
                key={index}
                alt={`Question ${index + 1}`}
                src={questionItem.value}
                className="question-header-image"
                onClick={() => setShowLightbox(questionItem.value)}
              />
            );
          } else if (questionItem.type === QuestionHeaderTypes.TEXT) {
            return (
              <p className="question-header-text" key={index}>
                {questionItem.value}
              </p>
            );
          } else if (questionItem.type === QuestionHeaderTypes.URL) {
            return (
              <a
                key={index}
                target="_blank"
                rel="noreferrer"
                href={questionItem.value}
              >
                <p className="question-header-link">Link</p>
              </a>
            );
          } else if (questionItem.type === QuestionHeaderTypes.VIDEO) {
            return questionItem.vendor === "Direct" ? (
              <div key={index} className="question-header-video">
                <video className="video-playback" controls>
                  <source src={questionItem.value} />
                </video>
              </div>
            ) : (
              <div
                className="question-header-video"
                dangerouslySetInnerHTML={{ __html: questionItem.value }}
              ></div>
            );
          } else if (questionItem.type === QuestionHeaderTypes.AUDIO) {
            return (
              <div key={index} className="question-header-audio">
                <audio controls>
                  <source src={questionItem.value} />
                </audio>
              </div>
            );
          } else return <div key={index}></div>;
        })}
      </div>

      {showLightbox && (
        <Lightbox image={showLightbox} setShowLightbox={setShowLightbox} />
      )}
    </React.Fragment>
  );
};

export default QuestionHeader;
