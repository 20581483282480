import React from 'react';
import BlueCheckIcon from '../../assets/images/blue-check-icon.svg';

import './styles.scss';

const LessonProgressCard = (props) => {
  const getProgressPercent = (completed, total) => {
    let progress = (completed / total) * 100;
    if (isNaN(progress)) {
      return 0;
    } else {
      return progress;
    }
  };

  return (
    <div className="lesson-progress">
      <div className="lesson-num-container">
        <h3 className="lesson-num">{props.number}</h3>
        {props.lesson?.allDone && (
          <img src={BlueCheckIcon} alt="Check" className="check-icon" />
        )}
      </div>
      <div className="lesson-info">
        <h3 className="lesson-name">{props.lesson?.chapter}</h3>

        <div className="progress-row">
          <div className="lesson-type-container">
            <div className="lesson-progress-bar">
              <div
                className="progress-value"
                style={{
                  width: `${getProgressPercent(
                    props.lesson?.completed?.Worksheet,
                    props.lesson?.total?.Worksheet
                  )}%`,
                }}
              />
            </div>
            <div className="lesson-progress-count">
              <div className="count">
                <h3 className="numerator">
                  {props.lesson?.completed?.Worksheet}
                </h3>
                <h3 className="denominator">
                  {props.lesson?.total?.Worksheet}
                </h3>
              </div>
              <p className="lesson-type">Worksheets</p>
            </div>
          </div>
          <div className="lesson-type-container">
            <div className="lesson-progress-bar">
              <div
                className="progress-value"
                style={{
                  width: `${getProgressPercent(
                    props.lesson?.completed?.Project,
                    props.lesson?.total?.Project
                  )}%`,
                }}
              />
            </div>
            <div className="lesson-progress-count">
              <div className="count">
                <h3 className="numerator">
                  {props.lesson?.completed?.Project}
                </h3>
                <h3 className="denominator">{props.lesson?.total?.Project}</h3>
              </div>
              <p className="lesson-type">Projects</p>
            </div>
          </div>
          <div className="lesson-type-container">
            <div className="lesson-progress-bar">
              <div
                className="progress-value"
                style={{
                  width: `${getProgressPercent(
                    props.lesson?.completed?.Activity,
                    props.lesson?.total?.Activity
                  )}%`,
                }}
              />
            </div>
            <div className="lesson-progress-count">
              <div className="count">
                <h3 className="numerator">
                  {props.lesson?.completed?.Activity}
                </h3>
                <h3 className="denominator">{props.lesson?.total?.Activity}</h3>
              </div>
              <p className="lesson-type">Activities</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LessonProgressCard;
