import { apiConstants } from '../common/apiConstants';
import { baseAxiosInstance, parentAxiosInstance } from './';

const classService = {
  getLessonDetails: (dataPayload) =>
    baseAxiosInstance.get(
      apiConstants.GET_LESSON_DETAILS(dataPayload.lessonID)
    ),

  getClasses: () => baseAxiosInstance.get(apiConstants.GET_CLASSES),
  getSubjects: () => baseAxiosInstance.get(apiConstants.GET_SUBJECTS),
  getSubjectDetails: (dataPayload) =>
    baseAxiosInstance.post(apiConstants.GET_SUBJECT_DETAILS, dataPayload),
  getParentSubjectDetails: (dataPayload) =>
    parentAxiosInstance.post(apiConstants.PARENT_SUBJECT_DETAILS, dataPayload),
};

export default classService;
