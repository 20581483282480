import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { showToast } from '../../../common/helpers';
import { setLoaderAction } from '../../../actions/siteActions';
import { routeConstants } from '../../../common/routeConstants';

import userService from '../../../services/userService';
import ParentPrivate from '../../../hocs/ParentPrivate';
import ProductCard from '../../../components/ProductCard';
import SectionTitle from '../../../components/SectionTitle';
import BreadcrumbStatic from '../../../components/BreadcrumbStatic';
import NavigationStatic from '../../../components/NavigationStatic';

import './styles.scss';

const Newsletters = (props) => {
  const [newsletters, setNewsletters] = useState([]);

  useEffect(() => {
    props.setLoader(true);
    userService
      .getNewsletters()
      .then((responseData) => {
        if (responseData) {
          props.setLoader(false);
          if (responseData.code === 200 && responseData.data) {
            setNewsletters(responseData.data);
          }
        }
      })
      .catch((errorData) => {
        props.setLoader(false);
        showToast(errorData?.message);
      });
  }, []); // eslint-disable-line

  return (
    <div className="global-container content-container">
      <NavigationStatic />
      <BreadcrumbStatic
        crumbs={[
          {
            name: 'Dashboard',
            route: routeConstants.PARENT_DASHBOARD,
          },
          {
            name: 'Newsletters',
            route: routeConstants.PARENT_NEWSLETTERS,
          },
        ]}
      />

      <div className="newsletters">
        <SectionTitle title="Newsletters" />

        <div className="newsletters-grid">
          {newsletters &&
            newsletters.length !== 0 &&
            newsletters.map((newsletter, index) => {
              return (
                <ProductCard
                  key={index}
                  type="mySessions"
                  title={newsletter.title}
                  thumbnail={newsletter.thumbnail}
                  onClick={() => window.open(newsletter.url, '_blank')}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  setLoader: (dataPayload) => setLoaderAction(dispatch, dataPayload),
});

export default ParentPrivate(
  connect(mapStateToProps, mapDispatchToProps)(Newsletters)
);
