import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { routeConstants } from '../../common/routeConstants';
import { AvailableEnglishTags } from '../../common/constants';

import PrimaryButton from '../PrimaryButton';
import HintIcon from '../../assets/images/hint-icon.svg';
import GrammarIcon from '../../assets/images/grammar.svg';
import ReadingIcon from '../../assets/images/reading.svg';
import WritingIcon from '../../assets/images/writing.svg';
import './styles.scss';

const EnglishTagSelection = (props) => {
  const history = useHistory();
  const [selectedTag, setSelectedTag] = useState(null);

  return (
    <div>
      <Modal
        centered
        size="md"
        show={true}
        onHide={props.hideModal}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Body>
          <h4>Select what you want to study</h4>

          <div
            onClick={() => setSelectedTag(AvailableEnglishTags.GRAMMAR)}
            className={`english-tag ${
              selectedTag === AvailableEnglishTags.GRAMMAR &&
              'english-tag-selected'
            }`}
          >
            <img src={GrammarIcon} alt="Grammar" />
            <h5>Grammar</h5>
          </div>
          <div
            onClick={() => setSelectedTag(AvailableEnglishTags.READING)}
            className={`english-tag ${
              selectedTag === AvailableEnglishTags.READING &&
              'english-tag-selected'
            }`}
          >
            <img src={ReadingIcon} alt="Reading" />
            <h5>Reading</h5>
          </div>
          <div
            onClick={() => setSelectedTag(AvailableEnglishTags.WRITING)}
            className={`english-tag ${
              selectedTag === AvailableEnglishTags.WRITING &&
              'english-tag-selected'
            }`}
          >
            <img src={WritingIcon} alt="Writing" />
            <h5>Writing</h5>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <PrimaryButton
            disabled={!selectedTag}
            onClick={() =>
              history.push(
                `${props.route.replace(
                  ':subjectID',
                  props.subjectID
                )}?et=${selectedTag}`
              )
            }
            name="Continue"
          />
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default EnglishTagSelection;
