import { reduxConstants } from '../common/reduxConstants';

const resourceReducer = (
  state = {
    activeQuestion: null,
    questionnaireBreadcrumbs: [],
  },
  action
) => {
  switch (action.type) {
    case reduxConstants.SETACTIVEQUESTION:
      return {
        ...state,
        activeQuestion: action.payload,
      };

    case reduxConstants.SETQUESTIONNAIREBREADCRUMB:
      return {
        ...state,
        questionnaireBreadcrumbs: action.payload,
      };

    case reduxConstants.LOGOUT:
      return {
        activeQuestion: null,
        questionnaireBreadcrumbs: [],
      };

    default: {
      return state;
    }
  }
};

export default resourceReducer;
