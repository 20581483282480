import OnboardingIllustration from '../../assets/images/onboarding-illustration-2x.png';

import './styles.scss';

const OnboardingStatic = (props) => {
  return (
    <aside className='onboarding-container-static'>
      <img
        src={OnboardingIllustration}
        className='img-fluid'
        alt='Change the way you look at education'
      />

      <p className='onboarding-container-definer'>
        Adaptive Self-learning platform with 1000+ resources for English,
        Science and Maths. Also available on synchronised mobile apps. Log in to
        explore ‘Freedom of Learning’
      </p>
    </aside>
  );
};

export default OnboardingStatic;
