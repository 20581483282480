import React from 'react';
import './styles.scss';

const EmptyState = (props) => {
  return (
    <div className='empty-state'>
      <img src={props.img} alt='Empty' className='empty-state-img' />
      <p className='empty-state-label'>{props.label}</p>
    </div>
  );
};

export default EmptyState;
