import './styles.scss';

const InputTextArea = (props) => {
  return (
    <div className="textarea-container">
      <textarea
        type={props.type}
        onChange={props.onChange}
        className={
          props.className
            ? `input-textarea ${props.className}`
            : 'input-textarea'
        }
        {...props}
      />
      {props.minWords && <span>{props.wordCount} / {props.minWords} words</span>}
    </div>
  );
};

export default InputTextArea;
