import React from 'react';

import './styles.scss';

const SubjectProgress = (props) => {
	const getProgressPercent = () => {
		let progress = props.done / props.total * 100;
		if (isNaN(progress)) {
			return 0;
		} else {
			return progress;
		}
	};

	return (
		<div onClick={props.onClick} className='subject-progress'>
			<div className='subject-img'>
				<img src={props.icon} alt='Maths' />
			</div>
			<div className='subject-info'>
				<h3 className='subject-name'>{props.name}</h3>
				<div className='subject-progress-bar'>
					<div className='progress-value' style={{ width: `${getProgressPercent()}%` }} />
				</div>
				<div className='subject-progress-count'>
					<h3 className='numerator'>{props.done}</h3>
					<h3 className='denominator'>{props.total}</h3>
					<p>Lessons</p>
				</div>
			</div>
		</div>
	);
};

export default SubjectProgress;
