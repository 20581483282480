import { reduxConstants } from '../common/reduxConstants';

export const loginAction = (dispatch, dataPayload) => {
  dispatch({
    payload: dataPayload,
    type: reduxConstants.LOGIN,
  });
};

export const logoutAction = (dispatch) => {
  dispatch({
    payload: {},
    type: reduxConstants.LOGOUT,
  });
};

export const parentLoginAction = (dispatch, dataPayload) => {
  dispatch({
    payload: dataPayload,
    type: reduxConstants.PARENTLOGIN,
  });
};

export const setParentOtpAction = (dispatch) => {
  dispatch({
    payload: {},
    type: reduxConstants.PARENTOTP,
  });
};