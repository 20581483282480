import styles from './styles.scss';
import {
  QuestionSubTypes,
  QuestionHeaderTypes,
} from '../../../common/constants';

const QuestionSuggestions = (props) => {
  const onDragStart = (event, suggestion) => {
    event.dataTransfer.dropEffect = 'copy';
    event.dataTransfer.setData('text/plain', suggestion);
  };

  return (
    <div style={styles} className="suggestions-container">
      <div className="suggestions-container-header">
        <strong>Suggestions: </strong>
      </div>
      <div className="suggestions-container-content row">
        {[
          QuestionSubTypes.LQ,
          QuestionSubTypes.TBLS,
          QuestionSubTypes.FITBS,
        ].includes(props.activeQuestion.questionSubType)
          ? props.activeQuestion.questionSuggestions.map(
              (suggestion, index) => {
                return (
                  <div
                    key={index}
                    draggable={true}
                    onDragOver={props.onDragOver}
                    className="suggestion-item-col"
                    onDragStart={(event) => onDragStart(event, suggestion)}
                  >
                    <p className='suggestion-label'>
                      {suggestion}
                    </p>
                    <div className='suggestion-bars'>
                      <div className='bar'></div>
                      <div className='bar'></div>
                    </div>
                  </div>
                );
              }
            )
          : props.activeQuestion.questionSubType === QuestionSubTypes.MTF
          ? props.activeQuestion?.answer?.ans?.map((suggestion, index) => {
              return (
                <div
                  key={index}
                  draggable={true}
                  onDragOver={props.onDragOver}
                  className="suggestion-item-col"
                  onDragStart={(event) => onDragStart(event, JSON.stringify(suggestion))}
                >
                  {suggestion.type === QuestionHeaderTypes.TEXT ? (
                    <p className='suggestion-label'>
                      {suggestion.text}
                    </p>
                  ) : (
                    <img src={suggestion.url} alt="Suggestion" />
                  )}
                  <div className='suggestion-bars'>
                      <div className='bar'></div>
                      <div className='bar'></div> 
                    </div>
                </div>
              );
            })
          : props.activeQuestion.questionSuggestions.map(
              (suggestion, index) => {
                return (
                  <div
                    key={index}
                    style={{ cursor: 'default' }}
                    className="suggestion-item-col relative"
                  >
                    {suggestion}
                  </div>
                );
              }
            )}
      </div>
    </div>
  );
};

export default QuestionSuggestions;
