import './styles.scss';

const InputText = (props) => {
	return (
		<div className='relative'>
			<input
				type={props.type}
				onChange={props.onChange}
				className={props.className ? `input-text ${props.className}` : 'input-text'}
				{...props}
			/>
			{props.icon && <img src={props.icon} alt='' className='input-icon' />}
		</div>
	);
};

export default InputText;
