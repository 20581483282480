import styles from './styles.scss';

const WrongAnswerToast = (props) => {
  return (
    <h5 style={styles}>
      OOPS, that was a miss!{' '}
      {props.attempts
        ? `But you still have ${props.attempts} left.`
        : 'You may like to try again...'}
    </h5>
  );
};

export default WrongAnswerToast;
