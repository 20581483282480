import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { routeConstants } from '../common/routeConstants';

import PageLoader from '../components/PageLoader';

const ParentPrivate = (ChildComponent) => {
  const PrivateHoc = (props) => {
    const history = useHistory();
    const [shouldRender, setShouldRender] = useState(false);

    useEffect(() => {
      if (!props?.auth?.accessToken || !props?.profile?.mobileNumber) {
        return history.replace(routeConstants.DASHBOARD);
      } else if (
        !props?.profile?.parentMobileNumber ||
        !props?.parentAuth?.accessToken
      ) {
        return history.replace(routeConstants.PARENT_ONBOARDING_MOBILE);
      } else {
        setShouldRender(true);
      }
    }, [props.parentAuth?.accessToken]); // eslint-disable-line

    return (
      <>
        {props.site.loader && <PageLoader />}
        {shouldRender && <ChildComponent {...props} />}
      </>
    );
  };

  const mapStateToProps = (state) => ({
    site: state.site,
    profile: state.user.profile,
    auth: state.auth.userDetails,
    parentAuth: state.auth.parentDetails,
  });

  const mapDispatchToProps = (dispatch) => ({});

  return connect(mapStateToProps, mapDispatchToProps)(PrivateHoc);
};

export default ParentPrivate;
