import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { showToast } from '../../common/helpers';
import { useEffect, useState, useCallback } from 'react';
import { AvailableSubjects } from '../../common/constants';
import { setLoaderAction } from '../../actions/siteActions';
import { routeConstants } from '../../common/routeConstants';

import debounce from 'lodash/debounce';
import Private from '../../hocs/Private';
import InputText from '../../components/InputText';
import userService from '../../services/userService';
import EmptyState from '../../components/EmptyState';
import SectionTitle from '../../components/SectionTitle';
import SearchIcon from '../../assets/images/search-icon.svg';
import BreadcrumbStatic from '../../components/BreadcrumbStatic';
import NavigationStatic from '../../components/NavigationStatic';
import EmptyLessonIcon from '../../assets/images/empty-lesson.svg';

import './styles.scss';

const Search = (props) => {
  const history = useHistory();
  const [search, setSearch] = useState('');
  const [lessons, setLessons] = useState([]);

  useEffect(() => {
    if (props.match.params.search) {
      userService
        .search({ query: props.match.params.search, type: 'Global' })
        .then((responseData) => {
          if (responseData) {
            props.setLoader(false);
            if (responseData.code === 200 && responseData.data) {
              setLessons(responseData.data.lessons);
            }
          }
        })
        .catch((errorData) => {
          props.setLoader(false);
          showToast(errorData?.message);
        });
    } else {
      setLessons([]);
    }
  }, [props.match.params.search]); // eslint-disable-line

  const searchHandler = (value) => {
    setSearch(value);
    debouncedSearch(value);
  };

  const debouncedSearch = useCallback(
    debounce(
      (nextValue) =>
        history.push(routeConstants.SEARCHPARAMS.replace(':search', nextValue)),
      300
    ),
    []
  ); // will be created only once initially

  const getLinearBgColor = (subject) => {
    switch (subject.toLowerCase()) {
      case AvailableSubjects.Maths.toLowerCase():
        return 'linear-gradient(90deg, #F47F7F, #FDDFDF)';

      case AvailableSubjects.Science.toLowerCase():
        return 'linear-gradient(90deg, #fac055, #feeac3)';

      case AvailableSubjects.English.toLowerCase():
        return 'linear-gradient(90deg, #42E476, #D0F9DD)';

      default:
        return 'grey';
    }
  };

  const lessonSelectionHandler = (lesson) => {
    if (!lesson.disabled) {
      history.push(
        routeConstants.LESSONDETAILS.replace(':lessonID', lesson._id)
      );
    }
  };

  return (
    <div className="global-container content-container">
      <NavigationStatic />
      <BreadcrumbStatic
        crumbs={[
          {
            name: 'Dashboard',
            route: routeConstants.DASHBOARD,
          },
          {
            name: 'Search',
            route: routeConstants.SEARCH,
          },
        ]}
      />

      <div className="search-page">
        <SectionTitle title="Search" />

        <div className="search-page-search">
          <InputText
            type="text"
            value={search}
            icon={SearchIcon}
            className="search-input"
            placeholder="Search here..."
            onChange={(event) => searchHandler(event.target.value)}
          />
        </div>

        <div className="search-page-lessons">
          {lessons.length !== 0 && <div className="dividing-line"></div>}
          {lessons && lessons.length !== 0 ? (
            lessons.map((lesson, index) => {
              return (
                <div
                  key={index}
                  // className={`col-5 lesson-item ${
                  //   lesson.disabled ? 'lesson-item-disabled' : ''
                  // }`}
                  className={lesson.disabled ? 'col-5 lesson-item lesson-item-disabled' : 'col-5 lesson-item'}
                  onClick={() => lessonSelectionHandler(lesson)}
                >
                  <h5
                    className="lesson-item-number"
                    style={{
                      background: getLinearBgColor(lesson.subjectID?.subject),
                    }}
                  >
                    {index + 1}
                  </h5>
                  <p className="lesson-item-name">{lesson.chapter}</p>
                </div>
              );
            })
          ) : (
            <EmptyState
              img={EmptyLessonIcon}
              label={
                props.match.params.search
                  ? 'OOPS! No lessons found'
                  : 'Please search for a lesson...'
              }
            />
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  setLoader: (dataPayload) => setLoaderAction(dispatch, dataPayload),
});

export default Private(connect(mapStateToProps, mapDispatchToProps)(Search));
